import Banner from "../banner";

import chImg1 from "../../img/career-img1.png";
import chImg2 from "../../img/career-img2.png";
import chImg3 from "../../img/career-img3.png";
import chImg4 from "../../img/career-img4.png";

import { useNavigate } from "react-router-dom";

const CareersHeroFr = () => {
  const navigate = useNavigate();

  const handleOurCutltureSection = () => {
    navigate("/careers?section=our-culture");
    const query = new URLSearchParams(window.location.search);
    if (query.get("section") === "our-culture") {
      const element = document.getElementById("our-culture");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleOpenRoles = () => {
    navigate("/careers?section=open-roles");
    const query = new URLSearchParams(window.location.search);
    if (query.get("section") === "open-roles") {
      const element = document.getElementById("open-roles");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center gap-20 h-screen p-10 py-[400px] pt-[500px] lg:p-20">
      <div className="flex flex-col justify-start items-start w-full lg:w-1/2 mt-0">
        <Banner title="laisse ta trace" />
        <div className="text-left mt-4">
          <h1 className="text-2xl lg:text-4xl font-medium text-berkeleyBlue">
            Rejoignez-nous et façonnez l’avenir du financement des soins de
            santé
          </h1>
          <p className="text-xl font-ibmPlexSans text-[#4D4D4D] mt-5">
            Des médicaments abordables aux plans de financement adaptés au
            public vulnérable ; nos objectifs sont ambitieux à mesure qu'ils se
            présentent.
          </p>
          <div className="font-ibmPlexSans mt-10 flex justify-start items-center gap-5">
            <button
              onClick={handleOurCutltureSection}
              className="bg-[#1A70B8] px-5 p-3 rounded-md text-white"
            >
              notre culture
            </button>
            <button
              onClick={handleOpenRoles}
              className="bg-[#D2E7F9] px-5 p-3 rounded-md text-[#1A70B8]"
            >
              afficher les postes ouverts
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-center items-center gap-3">
          <img src={chImg1} alt="" className="w-[100px] rounded-lg" />
          <img src={chImg2} alt="" className="w-[260px] rounded-lg" />
        </div>
        <div className="flex justify-center items-center gap-3 mt-3">
          <img src={chImg3} alt="" className="w-[270px] rounded-lg" />
          <img src={chImg4} alt="" className="w-[100px] rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default CareersHeroFr;
