import React, { useState } from "react";
import Modal from "./modal";
import ReactPlayer from "react-player";

const HomeVidModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        className="text-sm text-white bg-[#1a70b8] p-3 rounded-md w-full"
        onClick={openModal}
      >
        Watch the video
      </button>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <div className="rounded-lg overflow-hidden">
          <ReactPlayer
            url="/videos/homepage_vid.mp4"
            width="100%"
            height="100%"
            controls={true}
            playing={isModalOpen}
            style={{
              borderRadius: "20px",
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default HomeVidModal;
