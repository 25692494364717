import s1 from "../../img/tb-1.png";
import s2 from "../../img/tb-2.png";
import s3 from "../../img/tb-3.png";

const TrustBuildingSectionFr = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-start gap-10 mt-28 w-full p-5 py-0 lg:p-20">
      <div className="w-full lg:w-1/2">
        <h3 className="text-2xl text-berkeleyBlue">
          Initiatives marketing de renforcement de la confiance
        </h3>
        <p className="text-[#4D4D4D] font-ibmPlexSans text-sm leading-[30px] mt-3">
          Bénéficiez de notre engagement à renforcer la confiance dans les soins
          de santé. Nous collaborerons avec votre pharmacie sur des initiatives
          marketing qui mettent en valeur l’impact positif de vos services dans
          les communautés mal desservies. Cela inclut des campagnes co-marquées,
          des événements d'engagement communautaire et des stratégies de
          visibilité en ligne. En s'associant à 10mg Pharma, votre pharmacie
          devient un partenaire de soins de santé de confiance qui se consacre à
          faire une différence dans la vie des gens.
        </p>
      </div>
      <div className="flex justify-center items-center gap-3 w-full lg:w-1/2 h-[300px]">
        <div className="flex">
          <img className="w-[327px] rounded-lg" src={s1} alt="s1" />
        </div>
        <div className="flex flex-col gap-3">
          <img className="w-[150px] rounded-lg" src={s2} alt="s2" />
          <img className="w-[150px] rounded-lg" src={s3} alt="s3" />
        </div>
      </div>
    </div>
  );
};

export default TrustBuildingSectionFr;
