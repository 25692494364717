import Banner from "../banner";

import { GoArrowUpRight } from "react-icons/go";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Autoplay, Navigation, EffectFade, Pagination } from "swiper/modules";

const Achievements = () => {
  const achievenents = [
    {
      achievenent: "Disrupt Africa",
      description: `How Nigeria’s 10mg Pharma is simplifying access to medication using AI`,
    },
    {
      achievenent: "BitcoinKe",
      description: `Ten African startups, including Nigeria's 10mg Pharma (healthtech), 
      funding and mentorship through Startupbootcamp AfriTech’s ASIP Accelerator Programme`,
    },
    {
      achievenent: "Yahoo Finance",
      description: `Microsoft-backed FAST Accelerator Announces 12 African Startups Selected 
      for Artificial Intelligence (AI) Program`,
    },
    {
      achievenent: "Empower Africa",
      description: `10mg Pharma employs AI to connect African healthcare providers with 
      financing partners, bridging traditional practices with cutting-edge technology. `,
    },
  ];

  return (
    <div className="p-5 py-20 lg:p-20 text-center w-full">
      <div className="flex flex-col justify-center items-center">
        <Banner title="our achievements" />
        <h1 className="text-left md:text-center text-2xl lg:text-3xl mt-7 w-full lg:w-1/2 text-berkeleyBlue">
          With pride in our impactful solutions, we have been recognized for our
          significant contributions to the healthcare field.
        </h1>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        navigation={true}
        effect={"fade"}
        pagination={{ clickable: true }}
        autoplay={{ delay: 1000 }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
      >
        {achievenents.map((achievement, index) => (
          <SwiperSlide key={index}>
            <div
              className="mt-20 relative border lg:border-0 border-gray-200 rounded-lg p-5 
              bg-white h-[300px]"
            >
              <h1 className="text-3xl text-[#B3001B]">
                {achievement.achievenent}
              </h1>
              <p className="text-xl font-ibmPlexSans text-berkeleyBlue mt-5 w-full lg:w-1/2 mx-auto">
                {achievement.description}
              </p>
              <div
                className="absolute top-7 right-[2rem] sm:right-[5rem] lg:right-[20rem] bg-[#FBDAC6] flex justify-center items-center 
            w-6 h-6 rounded-full"
              >
                <GoArrowUpRight className="text-[#B3001B]" />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Achievements;
