import { useState } from "react";
import plusIcon from "../../images/plusIcon.png";
import { FaTimes } from "react-icons/fa";

import Banner from "../banner";

const HealthCard = ({
  title,
  subTitle,
  description,
  cardOneTitle,
  cardOneImage,
  cardTwoImage,
  cardTwoTitle,
  cardOneBack,
  cardTwoBack,
}) => {
  const [cardOneFlip, setCardOneFlip] = useState(false);
  const [cardTwoFlip, setCardTwoFlip] = useState(false);

  const flipCardOne = () => {
    setCardOneFlip(!cardOneFlip);
  };
  const flipCardTwo = () => {
    setCardTwoFlip(!cardTwoFlip);
  };
  return (
    <div className="flex flex-col lg:flex-row justify-between items-center gap-8">
      <div className="w-full lg:w-1/2">
        <div>
          <Banner title={title} />
          <h2 className="font-medium text-[20px] text-berkeleyBlue leading-6 tracking-wide sm:text-[30px] sm:leading-10 mt-6">
            {subTitle}
          </h2>
        </div>
        <div>
          <p className="text-[#4D4D4D] font-ibmPlexSans leading-7 tracking-wide mt-5 sm:text-[16px]">
            {description}
          </p>
        </div>
      </div>

      <div
        className={`w-full flex flex-col lg:flex-row justify-between items-start gap-4`}
      >
        <div
          className={`p-3 border border-[#E6E6E6] rounded-2xl flex flex-shrink-0 flex-col flex-grow-0 w-full lg:w-1/2 ${
            cardOneFlip ? "bg-[#F7F7F8]" : ""
          } `}
        >
          <h2 className="leading-7 text-lg font-ibmPlexSans tracking-wide font-normal text-berkeleyBlue mt-2 mb-10">
            {cardOneTitle}
          </h2>
          <div className="h-[300px] mb-10 sm:mt-auto">
            {!cardOneFlip ? (
              <img
                src={cardOneImage}
                alt="health care service page"
                className="h-full w-full object-cover rounded-2xl "
              />
            ) : (
              <p className="font-ibmPlexSans text-sm font-normal leading-[1.75] px-3 ">
                {cardOneBack}
              </p>
            )}
          </div>

          <button
            className={`${
              !cardOneFlip ? "bg-[#F7F7F9]" : "bg-[#E6E6E6]"
            } text-[12px] text-berkeleyBlue py-2 px-5 flex text-center items-center gap-3 rounded-full leading-[15px] w-max mt-auto mb-2`}
            onClick={flipCardOne}
          >
            LEARN MORE{" "}
            {!cardOneFlip ? (
              <img src={plusIcon} alt="plus icon" className="h-2 w-2" />
            ) : (
              <FaTimes className="h-2 w-2" />
            )}
          </button>
        </div>
        <div
          className={`p-3 border border-[#E6E6E6] rounded-2xl flex flex-shrink-0 flex-col flex-grow-0 w-full lg:w-1/2 ${
            cardTwoFlip ? "bg-[#F7F7F8]" : ""
          }`}
        >
          <h2 className="leading-7 text-lg tracking-wide font-normal font-ibmPlexSans text-berkeleyBlue mt-2 mb-10">
            {cardTwoTitle}
          </h2>
          <div className="h-[300px] mb-10 sm:mt-auto ">
            {!cardTwoFlip ? (
              <img
                src={cardTwoImage}
                alt="health care service page"
                className="h-full w-full object-cover rounded-2xl"
              />
            ) : (
              <p className="font-ibmPlexSans text-sm font-normal leading-[1.75] px-3">
                {cardTwoBack}
              </p>
            )}
          </div>
          <button
            className={`${
              !cardTwoFlip ? "bg-[#F7F7F9]" : "bg-[#E6E6E6]"
            } text-[12px] text-berkeleyBlue py-2 px-5 flex text-center items-center gap-3 rounded-full leading-[15px] w-max sm:mt-auto mb-2`}
            onClick={flipCardTwo}
          >
            LEARN MORE{" "}
            {!cardTwoFlip ? (
              <img src={plusIcon} alt="plus icon" className="h-2 w-2" />
            ) : (
              <FaTimes className="h-2 w-2" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HealthCard;
