import ServiceHeroFr from "../components/sections/service-hero-fr";
import HoverExpandComponentFr from "../components/sections/service_hover_fr";
import HealthcareProvidersFr from "../components/sections/healthcare-providers-fr";
import AccessSectionFr from "../components/sections/access-section-fr";
import TrustBuildingSectionFr from "../components/sections/trust-building-section-fr";
import ServiceSuppliersFr from "../components/sections/services-suppliers-fr";
import HealthCareFr from "../components/sections/service_healthCare-fr";

const ServicesFr = () => {
  return (
    <div>
      <ServiceHeroFr />
      <section className="sm:p-10">
        <HoverExpandComponentFr />
      </section>
      <HealthcareProvidersFr />
      <AccessSectionFr />
      <TrustBuildingSectionFr />
      <ServiceSuppliersFr />
      <HealthCareFr />
    </div>
  );
};

export default ServicesFr;
