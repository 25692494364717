import Banner from "../banner";

import { GoArrowUpRight } from "react-icons/go";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Autoplay, Navigation, EffectFade, Pagination } from "swiper/modules";

const AchievementsFr = () => {
  const achievenents = [
    {
      achievenent: "Disrupt Africa",
      description: `Comment l’entreprise nigériane 10mg Pharma simplifie l’accès aux médicaments grâce à l’IA`,
    },
    {
      achievenent: "BitcoinKe",
      description: `Dix startups africaines, dont la société nigériane 10mg Pharma (technologies de la santé), 
      bénéficient d'un financement et d'un mentorat via le programme d'accélération ASIP de Startupbootcamp 
      AfriTech.`,
    },
    {
      achievenent: "Yahoo Finance",
      description: `FAST Accelerator, soutenu par Microsoft, annonce la sélection de 12 startups africaines pour 
      le programme d'intelligence artificielle (IA)`,
    },
    {
      achievenent: "Empower Africa",
      description: `10mg Pharma utilise l’IA pour connecter les prestataires de soins de santé africains avec 
      des partenaires financiers, reliant les pratiques traditionnelles à la technologie de pointe.. `,
    },
  ];

  return (
    <div className="p-5 py-20 lg:p-20 text-center w-full">
      <div className="flex flex-col justify-center items-center">
        <Banner title="nos réalisations" />
        <h1 className="text-left md:text-center text-2xl lg:text-3xl mt-7 w-full lg:w-1/2 text-berkeleyBlue">
          Fiers de nos solutions percutantes, nous avons été reconnus pour nos
          contributions significatives au domaine de la santé.
        </h1>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        navigation={true}
        effect={"fade"}
        pagination={{ clickable: true }}
        autoplay={{ delay: 1000 }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
      >
        {achievenents.map((achievement, index) => (
          <SwiperSlide key={index}>
            <div
              className="mt-20 relative border lg:border-0 border-gray-200 rounded-lg p-5 
              bg-white h-[300px]"
            >
              <h1 className="text-3xl text-[#B3001B]">
                {achievement.achievenent}
              </h1>
              <p className="text-xl font-ibmPlexSans text-berkeleyBlue mt-5 w-full lg:w-1/2 mx-auto">
                {achievement.description}
              </p>
              <div
                className="absolute top-7 right-[2rem] sm:right-[5rem] lg:right-[20rem] bg-[#FBDAC6] flex justify-center items-center 
            w-6 h-6 rounded-full"
              >
                <GoArrowUpRight className="text-[#B3001B]" />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AchievementsFr;
