import AboutHero from "../components/sections/ab-hero";
import AboutImpact from "../components/sections/ab-impact";
import AboutIntro from "../components/sections/ab-intro";
import Contact from "../components/sections/contact";
import PainProblem from "../components/sections/pain-problem";
import Partners from "../components/sections/partners";
import Spotlight from "../components/sections/spotlight";
// import Team from "../components/sections/team";
// import Values from '../components/sections/values'
import Why10mg from "../components/sections/why10mg";

const About = () => {
  return (
    <>
      <AboutHero />
      <Partners />
      <AboutIntro />
      <Why10mg />
      <PainProblem />
      <AboutImpact />
      <Spotlight />
      <Contact />
      {/* <Values /> */}
      {/* <Team /> */}
      {/* <HealthRefined /> */}
    </>
  );
};

export default About;
