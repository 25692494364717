import React, { useState } from "react";
import Map from "../map";
import MapForm from "../sections/map-form";
import StateSelection from "../state-selection";

const Contact = () => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const handleStateChange = (address) => {
    console.log("Received address in App:", address);
    setSelectedAddress(address);
  };

  return (
    <div className="p-5 py-20 lg:p-20 flex flex-col lg:flex-row justify-between items-center gap-7">
      <MapForm />
      <div className="w-full lg:w-1/2 mx-auto">
        <Map selectedAddress={selectedAddress} />
        <StateSelection onStateChange={handleStateChange} />
      </div>
    </div>
  );
};

export default Contact;
