// import AccordionValues from '../components/sections/accordion_values'
import Benefits from "../components/sections/benefits";
import CValues from "../components/sections/c-values";
import CareersHero from "../components/sections/careers-hero";
import Opening from "../components/sections/openings";
import StaffSpotlight from "../components/sections/staff-spotlight";

const Careers = () => {
  return (
    <>
      <CareersHero />
      <CValues />
      {/* <AccordionValues /> */}
      <StaffSpotlight />
      <Benefits />
      <Opening />
    </>
  );
};

export default Careers;
