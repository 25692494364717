import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Routes, Route } from "react-router-dom";
import NotFound from "./routes/404";
import About from "./routes/about";
import Home from "./routes/home";
import Blog from "./routes/blog";
import Careers from "./routes/careers";
import Faqs from "./routes/faqs";
import Privacy from "./routes/privacy";
import Terms from "./routes/terms";
import Services from "./routes/services";
import FaqCategory from "./routes/faqsCategories";
import OpeningDetails from "./components/opening-details";
import SignUpPharmacy from "./components/signup/signup";
import ConfirmEmail from "./components/confirm";
import ConfirmPage from "./components/signup/confirm-page";
import LoginForm from "./components/login";
import ForgotPassword from "./components/forgot-password";
import { useState } from "react";
import Navbar from "./components/nav";
import HomeFr from "./routes/home-fr";
import ServicesFr from "./routes/services-fr";
import AboutFr from "./routes/about-fr";
import CareersFr from "./routes/careers-fr";
import FaqsFr from "./routes/faqs-fr";
import FaqCategoryFr from "./routes/faqsCategoriesFr";
import TermsFr from "./routes/terms-fr";
import PrivacyFr from "./routes/privacy-fr";

function App() {
  const [language, setLanguage] = useState("English");

  const handleLanguageChange = (language) => {
    console.log("just language:", language);
    const lang = setLanguage(language);
    console.log("Lang:", lang);
  };

  return (
    <>
      <Navbar onLanguageChange={handleLanguageChange} />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path="/"
          element={language === "English" ? <Home /> : <HomeFr />}
        />
        <Route
          path="/about"
          element={language === "English" ? <About /> : <AboutFr />}
        />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/careers"
          element={language === "English" ? <Careers /> : <CareersFr />}
        />
        <Route
          path="/faqs"
          element={language === "English" ? <Faqs /> : <FaqsFr />}
        />
        <Route
          path="/privacy"
          element={language === "English" ? <Privacy /> : <PrivacyFr />}
        />
        <Route
          path="/terms"
          element={language === "English" ? <Terms /> : <TermsFr />}
        />
        <Route
          path="/services"
          element={language === "English" ? <Services /> : <ServicesFr />}
        />
        <Route
          path="/faqCategory/:id"
          element={language === "English" ? <FaqCategory /> : <FaqCategoryFr />}
        />
        <Route path="/opening/:title" element={<OpeningDetails />} />
        <Route path="/signup-pharmacy" element={<SignUpPharmacy />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/confirm-pharmacy" element={<ConfirmPage />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Routes>
    </>
  );
}

export default App;
