import vector from "../images/Vector.png";
import deliveryTruck from "../images/delivery-truck.png";
import accountIcon from "../images/manage-accounts.png";
import billingIcon from "../images/credit-card.png";
import receiptIcon from "../images/receipt.png";
import supportIcon from "../images/support.png";

export const commonTopicsFr = [
  {
    id: 1,
    title: "Commandes et livraison",
    icon: deliveryTruck,
    description: "suivi des commandes, estimations de livraison et plus encore",
    color: "#FBFBF8",
  },
  {
    id: 2,
    title: "Information produit",
    icon: vector,
    description: "Types de médicaments, authenticité, etc.",
    color: "#F8FBFA",
  },
  {
    id: 3,
    title: "Compte et sécurité",
    icon: accountIcon,
    description: "Réinitialiser le mot de passe, vos informations",
    color: "#F8F9FB",
  },
  {
    id: 4,
    title: "Paiement et facturation",
    icon: billingIcon,
    description: "Modes de paiement, factures, etc.",
    color: "#F8FAFB",
  },

  {
    id: 5,
    title: "Retours et remboursements",
    icon: receiptIcon,
    description: "Politique de retour",
    color: "#FAF8FB",
  },

  {
    id: 6,
    title: "Service client",
    icon: supportIcon,
    description: "Ligne d'assistance, coordonnées",
    color: "#FBFBF8",
  },
];

export const faqCategoriesDataFr = [
  {
    id: 1,
    title: "Commandes et livraison",
    desc: "Suivi des commandes, estimations de livraison et plus encore",
    questions: [
      {
        question: "Pourquoi avons-nous une livraison accélérée ?",
        answer: `Nous comprenons que certains problèmes de santé peuvent nécessiter une livraison plus urgente, 
        et notre service accéléré est conçu pour répondre à ces besoins spécifiques. Qu'il s'agisse d'une 
        ordonnance critique ou d'un besoin de soins de santé urgent, la livraison accélérée offre une option 
        d'expédition plus rapide, garantissant que nos clients reçoivent rapidement les médicaments nécessaires, 
        favorisant à la fois la commodité et la tranquillité d'esprit.`,
      },
      {
        question: "Quels types de livraison proposez-vous ?",
        answer:
          "Notre mission première est la transformation du système de santé, avec une attention sans faille. Chaque décision que nous prenons s'aligne sur la réalisation de cette mission, en donnant la priorité à nos objectifs plutôt qu'à la politique ou à la politesse. Nous opérons avec un sentiment d’appartenance, identifiant constamment les opportunités d’amélioration au-delà de nos responsabilités actuelles.",
      },
      {
        question: "Comment puis-je suivre ma commande ?",
        answer:
          "Notre mission première est la transformation du système de santé, avec une attention sans faille. Chaque décision que nous prenons s'aligne sur la réalisation de cette mission, en donnant la priorité à nos objectifs plutôt qu'à la politique ou à la politesse. Nous opérons avec un sentiment d’appartenance, identifiant constamment les opportunités d’amélioration au-delà de nos responsabilités actuelles.",
      },
      {
        question: "Que faire si ma commande est erronée ou endommagée ?",
        answer:
          "Notre mission première est la transformation du système de santé, avec une attention sans faille. Chaque décision que nous prenons s'aligne sur la réalisation de cette mission, en donnant la priorité à nos objectifs plutôt qu'à la politique ou à la politesse. Nous opérons avec un sentiment d’appartenance, identifiant constamment les opportunités d’amélioration au-delà de nos responsabilités actuelles.",
      },
    ],
  },
  {
    id: 2,
    title: "Information produit",
    desc: "Suivi des commandes, estimations de livraison et plus encore",
    questions: [
      {
        question: "Que propose votre produit/service ?",
        answer:
          "Chez 10mg Pharma, nous proposons les services suivants pour répondre à vos besoins spécifiques : Nous donnons accès à des médicaments abordables contre la douleur chronique grâce à un achat direct auprès des fabricants. Fini les goulots d'étranglement liés aux intermédiaires. Nous facilitons également le financement des pharmacies/hôpitaux.",
      },
      {
        question:
          "Quelles sont les principales caractéristiques de votre produit/service ?",
        answer:
          "Des médicaments contre la douleur chronique à faible coût, un soutien financier sous forme de prêts sans garantie et un système de gestion des stocks de bout en bout pour optimiser votre stock de médicaments.",
      },
      {
        question: "Comment puis-je bénéficier de vos produits/services ?",
        answer:
          "Pour bénéficier de l’un de nos services, inscrivez-vous en tant que fournisseur ou prestataire de soins de santé pour bénéficier d’avantages illimités. En savoir plus (lien vers WhatsApp)",
      },
    ],
  },
  {
    id: 3,
    title: "Compte et sécurité",
    desc: "Suivi des commandes, estimations de livraison et plus encore",
    questions: [
      {
        question: "Comment créer un compte sur votre plateforme ?",
        answer:
          "Suivez ces 2 étapes simples pour : 1. Vous inscrire en tant que fournisseur ou prestataire de soins de santé 2. Fournir les documents requis",
      },
      {
        question:
          "Puis-je mettre à jour les informations de mon compte, telles que mon adresse e-mail ou mon mot de passe ?",
        answer: "Oui. Cela peut être fait à partir des paramètres (Lien)",
      },
      {
        question:
          "Que dois-je faire si j'oublie le mot de passe de mon compte ?",
        answer:
          "Sur la page de connexion, cliquez sur réinitialiser le mot de passe et suivez l'invite",
      },
      {
        question:
          "Existe-t-il un moyen de désactiver ou de fermer mon compte ?",
        answer:
          "Oui. Depuis les paramètres (lien vers) ou l'assistance par e-mail (lien vers info@10mg.co.uk)",
      },
      {
        question:
          "Y a-t-il des avantages à créer un compte, comme des récompenses de fidélité ou des réductions ?",
        answer:
          "Oui, nous valorisons nos clients et concevons actuellement un programme de récompense",
      },
    ],
  },
  {
    id: 4,
    title: "Paiement et facturation",
    desc: "Suivi des commandes, estimations de livraison et plus encore",
    questions: [
      {
        question: "Quels modes de paiement acceptez-vous?",
        answer:
          "Nous acceptons les espèces au point de livraison, les virements bancaires et les paiements à la caisse. USSD à venir",
      },
      {
        question: "Mes informations de paiement sont-elles sécurisées ?",
        answer:
          "Rassurez-vous, nous utilisons la dernière technologie de cryptage et adhérons à des protocoles de sécurité stricts pour protéger vos données. Vos informations de paiement sont cryptées lors de la transmission et stockées en toute sécurité conformément aux normes du secteur.",
      },
      {
        question: "Quand ma carte de crédit/débit sera-t-elle débitée ?",
        answer:
          "Votre carte de crédit/débit sera débitée à la fin de votre commande. Vous recevrez un e-mail de confirmation une fois le paiement traité avec succès.",
      },
      {
        question: "Puis-je recevoir une copie de ma facture ou de mon reçu ?",
        answer:
          "Nous envoyons rapidement une copie de votre facture ou de votre reçu par e-mail. Veuillez nous fournir un e-mail actif pour recevoir une notification de facture et de reçus après le paiement.",
      },
      {
        question: "Y a-t-il des frais ou des charges supplémentaires ?",
        answer:
          "Nous ne facturons pas de frais supplémentaires. Avant le paiement, nous vous enverrons un devis pour confirmer votre paiement.",
      },
    ],
  },
  {
    id: 5,
    title: "Retours et remboursements",
    desc: "Suivi des commandes, estimations de livraison et plus encore",
    questions: [
      {
        question: "Résumé de la politique de retour et de remboursement :",
        answer:
          "Contactez le service client pour lancer le processus de retour. Les remboursements seront effectués moins les frais de réapprovisionnement et les frais d'expédition de retour. Les articles endommagés ou défectueux seront remplacés ou remboursés rapidement. Pour plus d'informations, contactez-nous à info@10mg.co.uk",
      },
    ],
  },
  {
    id: 6,
    title: "Service client",
    desc: "Suivi des commandes, estimations de livraison et plus encore",
    questions: [
      {
        question: "Vous souhaitez nous contacter ?",
        answer: "Contactez-nous via info@10mg.co.uk",
      },
    ],
  },
];
