import HoverExpandComponent from "../components/sections/service_hover";
import HealthCare from "../components/sections/service_healthCare";

import ServiceHero from "../components/sections/service-hero";
import HealthcareProviders from "../components/sections/healthcare-providers";
import AccessSection from "../components/sections/access-section";
import TrustBuildingSection from "../components/sections/trust-building-section";
import ServiceSuppliers from "../components/sections/services-suppliers";

const Services = () => {
  return (
    <div>
      <ServiceHero />
      <section className="sm:p-10">
        <HoverExpandComponent />
      </section>
      <HealthcareProviders />
      <AccessSection />
      <TrustBuildingSection />
      <ServiceSuppliers />
      <HealthCare />
    </div>
  );
};

export default Services;
