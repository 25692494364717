import { useState } from "react";

const CareerForm = () => {
  const [resumeFile, setResumeFile] = useState(null);
  const [resumeFilename, setResumeFilename] = useState("Upload Resume");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setResumeFile(file);
    setResumeFilename(file.name, "uploaded successfully");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // ... your form submission logic, including resumeFile
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="border border-red-50 p-5 rounded-lg mt-20 font-ibmPlexSans 
        flex flex-col gap-6 w-full lg:w-[60%]"
      >
        <div className="flex flex-col">
          <label htmlFor="fullName" className="text-sm  text-berkeleyBlue">
            Full Name
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            placeholder="Your name"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="fullName"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Email
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            placeholder="you@company.com"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="phone"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Phone
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="+123456789"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="linkedIn"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            LinkedIn
          </label>
          <input
            type="text"
            name="linkedIn"
            id="phone"
            placeholder="linkedin.com/in/you"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div
          className={`flex flex-col custom-file-upload ${
            resumeFile ? "uploaded" : ""
          }`}
        >
          <label
            htmlFor="Resume/CV "
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Resume/CV ( File types: pdf, doc, docx, txt, rtf)
          </label>
          <input
            type="file"
            name="resume"
            id="resume"
            accept=".pdf, .doc, .docx, .txt, .rtf"
            onChange={handleFileChange}
            className="hidden"
          />
          <label htmlFor="resume" className="cursor-pointer label-new text-sm">
            {resumeFilename}
          </label>
        </div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-full border border-berkeleyBlue 
                        bg-transparent px-4 py-2 text-sm font-medium text-berkeleyBlue  hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          Submit Application
        </button>
      </form>
    </div>
  );
};

export default CareerForm;
