import Banner from "../../components/banner";

import pain from "../../img/pain.png";

const PainProblem = () => {
  return (
    <div
      className="bg-[#F7F7F9] p-5 py-20 lg:p-20 text-center"
      id="the-problem"
    >
      <div className="flex flex-col justify-start lg:justify-center items-start lg:items-center gap-4 w-full lg:w-1/2 mx-auto">
        <Banner title="the problem" />
        <h1 className="text-left lg:text-center text-3xl font-medium text-berkeleyBlue">
          Chronic Pain Epidemic is Here
        </h1>
        <p className="text-left lg:text-center text-lg text-[#011627] font-ibmPlexSans">
          Chronic pain can have a devastating impact anywhere it is experienced,
          but in Africa, it is particularly problematic due to a combination of
          a lack of awareness, resources, and medical infrastructure.{" "}
        </p>
      </div>
      <div className="font-ibmPlexSans flex flex-col lg:flex-row justify-between items-center gap-5 mt-10 lg:mt-20">
        <div className="flex flex-col justify-start items-start gap-8 text-left text-[#011627]">
          <img
            src={pain}
            alt="the problem"
            className="lg:hidden rounded-lg w-full"
          />
          <h1 className="text-3xl font-pangaia text-berkeleyBlue w-[70%]">
            Combating pain through Community
          </h1>
          <p>
            The World Health Organization estimates that over 70% of the world's
            chronic pain sufferers live in low and middle-income countries, many
            of which are in Africa. The burden of chronic pain in Africa affects
            both individuals and societies.
          </p>

          <p>
            To mitigate the mortality rate of patients who die of cancer, sickle
            cell diseases, and other chronic pain illnesses, we are setting up
            programs and initiatives through our social impact and
            community-based programs.
          </p>
        </div>
        <img
          src={pain}
          alt="the problem"
          className="hidden lg:block rounded-lg w-1/2"
        />
      </div>
    </div>
  );
};

export default PainProblem;
