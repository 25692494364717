import AchievementsFr from "../components/sections/achievements-fr";
import ContactFr from "../components/sections/contact-fr";
import HomeHeroFr from "../components/sections/home_hero_fr";
import ImpactFr from "../components/sections/impact-fr";
import PartnersFr from "../components/sections/partners-fr";
import ProblemFr from "../components/sections/problems-fr";
import ProcessFr from "../components/sections/process-fr";
import QuoteFr from "../components/sections/quote-fr";
import SolutionFr from "../components/sections/solution-fr";
import SupportersFr from "../components/sections/supporters-fr";
import TestimonialsFr from "../components/sections/testimonials-fr";

const HomeFr = () => {
  return (
    <>
      <HomeHeroFr />
      <PartnersFr />
      <ProblemFr />
      <SolutionFr />
      <QuoteFr />
      <ProcessFr />
      <ImpactFr />
      <AchievementsFr />
      <TestimonialsFr />
      <SupportersFr />
      <ContactFr />
    </>
  );
};

export default HomeFr;
