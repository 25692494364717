import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Banner from "../banner";
import impactImg1 from "../../img/free-m.png";
import impactImg2 from "../../img/free-m1.png";
import impactImg3 from "../../img/free-m2.png";

const AboutImpactFr = () => {
  const contents = [
    {
      postion: "1",
      text: "Éducation et service gratuits sur la gestion de la douleur chronique.",
      img: impactImg1,
    },
    {
      postion: "2",
      text: "Camp médical et sensibilisation gratuits pour aider les prestataires de soins de santé dans les communautés mal desservies.",
      img: impactImg2,
    },
    {
      postion: "3",
      text: "Médicaments gratuits ou à prix réduit pour l'assistance aux patients afin d'aider les patients vulnérables confrontés à des obstacles financiers pour accéder au traitement de la douleur chronique.",
      img: impactImg3,
    },
  ];

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const targetSection = urlParams.get("section");

    if (targetSection) {
      const element = document.getElementById(targetSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="p-5 py-20 lg:p-20 text-center" id="our-journey">
      <div>
        <div className="">
          <Banner title="notre parcours d'impact" />
        </div>
        <div className="w-full flex justify-center items-start gap-4 mt-8">
          <h1 className="text-left text-3xl text-berkeleyBlue w-[70%]">
            Nous soutenons les communautés mal desservies
          </h1>
          <p className="text-sm font-ibmPlexSans text-[#4D4D4D] w-full lg:w-1/2 text-left">
            Qu’il s’agisse d’initiatives de soins de santé qui garantissent
            l’accès à des services médicaux vitaux ou de programmes éducatifs
            qui confèrent aux individus des connaissances et des compétences,
            nous nous efforçons d’avoir un impact positif. Notre engagement va
            au-delà de l’assistance immédiate et se concentre sur la création de
            changements durables et significatifs qui renforcent le tissu
            socio-économique des communautés mal desservies.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 lg:mt-20">
        {contents.map((content) => (
          <div
            className=" h-[500px] flex flex-col justify-between items-start p-5 border border-[#E6E6E6] 
            rounded-lg gap-4"
          >
            <div>
              <Banner
                title={content.postion}
                className="rounded-full w-7 h-7 p-0 px-0 py-0"
              />
              <p className="text-left font-ibmPlexSans text-berkeleyBlue mt-5">
                {content.text}
              </p>
            </div>
            <img src={content.img} alt="Impact" className="rounded-lg" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutImpactFr;
