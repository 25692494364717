// import AccordionValues from '../components/sections/accordion_values'
import BenefitsFr from "../components/sections/benefits-fr";
import CValuesFr from "../components/sections/c-values-fr";
import CareersHeroFr from "../components/sections/careers-hero-fr";
import OpeningFr from "../components/sections/openings-fr";
import StaffSpotlightFr from "../components/sections/staff-spotlight-fr";

const CareersFr = () => {
  return (
    <>
      <CareersHeroFr />
      <CValuesFr />
      {/* <AccordionValues /> */}
      <StaffSpotlightFr />
      <BenefitsFr />
      <OpeningFr />
    </>
  );
};

export default CareersFr;
