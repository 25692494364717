import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const NumberCounter = ({ img, num, title }) => {
  const [counter, setCounter] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounter(true)}
      onExit={() => setCounter(false)}
    >
      <div className="flex flex-col items-start gap-8">
        <div className="flex flex-col items-start gap-8">
          <div
            className="flex justify-center items-center bg-[#E6F3FE]
            w-[40px] h-[40px] rounded-full"
          >
            <img src={img} alt="Bottle Pills" className="w-[20px]" />
          </div>
          <div>
            <h1 className="text-4xl text-berkeleyBlue">
              {counter && <CountUp start={0} end={num} duration={5} />}+
            </h1>
            <p className="text-[14px] font-ibmPlexSans">{title}</p>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default NumberCounter;
