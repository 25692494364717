import s1 from '../../img/tb-1.png'
import s2 from '../../img/tb-2.png'
import s3 from '../../img/tb-3.png'

const TrustBuildingSection = () => {
  return (
    <div className='flex flex-col md:flex-row justify-center items-start gap-10 mt-28 w-full p-5 py-0 lg:p-20'>
      <div className='w-full lg:w-1/2'>
        <h3 className='text-2xl text-berkeleyBlue'>
          Trust-Building Marketing Initiatives
        </h3>
        <p className='text-[#4D4D4D] font-ibmPlexSans text-sm leading-[30px] mt-3'>
          Benefit from our commitment to building trust in healthcare. We will
          collaborate with your pharmacy on marketing initiatives that highlight
          the positive impact of your services in underserved communities. This
          includes co-branded campaigns, community engagement events, and online
          visibility strategies. By associating with 10mg Pharma, your pharmacy
          becomes a trusted healthcare partner dedicated to making a difference
          in people's lives.
        </p>
      </div>
      <div className='flex justify-center items-center gap-3 w-full lg:w-1/2 h-[300px]'>
        <div className='flex'>
          <img className='w-[327px] rounded-lg' src={s1} alt='s1' />
        </div>
        <div className='flex flex-col gap-3'>
          <img className='w-[150px] rounded-lg' src={s2} alt='s2' />
          <img className='w-[150px] rounded-lg' src={s3} alt='s3' />
        </div>
      </div>
    </div>
  )
}

export default TrustBuildingSection
