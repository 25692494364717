import sol1 from "../../img/collateral-img.png";
import sol2 from "../../images/sol2.png";
import Banner from "../banner";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const SolutionFr = () => {
  const [animationStage, setAnimationStage] = useState(0);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setAnimationStage(1);
    }, 2000);

    const timer2 = setTimeout(() => {
      setAnimationStage(2);
    }, 5000);

    const intervalId = setInterval(() => {
      setAnimationStage(2);
    }, 3000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearInterval(intervalId);
    };
  }, []);

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center gap-8 p-5 lg:p-10 py-20">
      <div className="w-full relative">
        {/* Image Top */}
        <motion.img
          src={sol1}
          alt="Solutions descriptions"
          className="w-[100%] lg:w-[80%] z-10"
          initial={animationStage >= 2 ? "hidden" : "visible"}
          animate={animationStage >= 2 ? "visible" : "hidden"}
          variants={imageVariants}
        />

        {/* Image Below */}
        <motion.img
          src={sol2}
          alt="Solutions descriptions"
          className="w-[100%] lg:w-[80%] rotate-6 absolute top-0 left-0 -z-10 rounded-xl"
          initial="visible"
          animate={animationStage >= 1 ? "hidden" : "visible"}
          variants={imageVariants}
        />
      </div>
      <div className="w-full">
        <Banner title="notre solution" />
        <div className="space-y-4 mt-9">
          <motion.h1
            className="text-[30px] text-berkeleyBlue leading-[38.4px]"
            initial={animationStage >= 1 ? "hidden" : "visible"}
            animate={animationStage >= 1 ? "visible" : "hidden"}
            variants={textVariants}
          >
            Un accès transparent et sans garantie aux prêts à un chiffre
          </motion.h1>
          <motion.p
            className="text-[16px] text-[#3E5B34] font-ibmPlexSans"
            initial={animationStage >= 1 ? "hidden" : "visible"}
            animate={animationStage >= 1 ? "visible" : "hidden"}
            variants={textVariants}
          >
            En tirant parti de nos arbres de codes d'IA, les prestataires de
            soins de santé obtiennent des prêts à un chiffre pour servir leurs
            communautés sans se soucier de fournir des garanties et des taux
            d'intérêt élevés de la part des prêteurs traditionnels.
          </motion.p>
        </div>
        <div className="space-y-4 mt-9">
          <motion.h1
            className="text-[30px] text-berkeleyBlue leading-[38.4px]"
            initial={animationStage >= 2 ? "hidden" : "visible"}
            animate={animationStage >= 2 ? "visible" : "hidden"}
            variants={textVariants}
          >
            {" "}
            Marge bénéficiaire accrue pour les prestataires de soins de santé,
            économies de coûts pour les patients.
          </motion.h1>
          <motion.p
            className="text-[16px] text-[#3E5B34] font-ibmPlexSans"
            initial={animationStage >= 2 ? "hidden" : "visible"}
            animate={animationStage >= 2 ? "visible" : "hidden"}
            variants={textVariants}
          >
            Nos partenariats stratégiques et nos financements avancés avec les
            fabricants de médicaments chroniques comblent le déficit de la
            chaîne d'approvisionnement, permettant aux prestataires de soins de
            santé d'accéder aux médicaments avec une réduction significative de
            23%, tandis que les patients bénéficient d'une réduction de 15 % du
            coût de leurs médicaments.
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default SolutionFr;
