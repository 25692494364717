import s1 from "../../img/im-1.png";
import s2 from "../../img/im-2.png";
import s3 from "../../img/im-3.png";

import Banner from "../../components/banner";
import { FaChevronRight } from "react-icons/fa6";

const HealthcareProviders = () => {
  return (
    <div className="p-5 py-20 lg:p-20">
      <div className="flex justify-center items-center">
        <Banner title="for health care providers" />
      </div>

      <div className="flex flex-col md:flex-row justify-center items-start gap-10 mt-28 w-full">
        <article className="mt-8 text-left w-full lg:w-1/2">
          <h3 className="text-2xl text-berkeleyBlue">
            AI-Enhanced Inventory Management
          </h3>
          <div className="flex items-center gap-2 text-md text-[#3E5B34] mt-1">
            <FaChevronRight className="h-3 w-2" />
            <p>Automate your procurement process for the year</p>
          </div>
          <p className="text-[#4D4D4D] font-ibmPlexSans text-sm leading-[30px] mt-3">
            Simplify your monthly medication procurement by embracing an
            automated system that takes the stress out of the process. With our
            automated medication purchase, you can set up a seamless and
            stress-free routine for obtaining your required medications each
            year. This entails a hassle-free ordering and delivery process,
            ensuring that you consistently have the necessary medications
            without the need for constant manual intervention.
          </p>
        </article>

        <div className="flex justify-center items-center gap-3 w-full lg:w-1/2 h-[300px]">
          <div className="flex">
            <img className="w-[327px] rounded-lg" src={s1} alt="s1" />
          </div>
          <div className="flex flex-col gap-3">
            <img className="w-[150px] rounded-lg" src={s2} alt="s2" />
            <img className="w-[150px] rounded-lg" src={s3} alt="s3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthcareProviders;
