import partner1 from "../../images/1.png";
import partner2 from "../../images/2.png";
import partner3 from "../../images/3.png";
import partner4 from "../../images/4.svg";
import partner5 from "../../images/5.svg";
import partner6 from "../../images/6.svg";
import partner7 from "../../images/7.svg";
import partner8 from "../../images/8.svg";
import partner9 from "../../images/9.svg";
import { motion, useReducedMotion } from "framer-motion";

const PartnersFr = () => {
  const prefersReducedMotion = useReducedMotion();
  const imageVariants = {
    initial: { x: 0 }, // Start at the right side
    animate: {
      x: "-100%", // Move offscreen to the left
      transition: {
        duration: prefersReducedMotion ? 8 : 5, // 10 second animation
        repeat: Infinity,
        repeatType: "loop",
        ease: "linear", // Constant movement
      },
    },
  };
  return (
    <div className="partners p-10 overflow-hidden">
      <h1 className="text-left md:text-center text-[14px] font-ibmPlexSans text-berkeleyBlue">
        FAIT CONFIANCE AUX MEILLEURS PARTENAIRES
      </h1>
      <div className="flex justify-between items-center gap-16 overflow-hidden w-[550%] lg:w-[150%] mt-10">
        <motion.div
          className="flex justify-between items-center gap-16"
          variants={imageVariants}
          initial="initial"
          animate="animate"
        >
          <img src={partner1} alt="Partners" />
          <img src={partner2} alt="Partners" />
          <img src={partner3} alt="Partners" />
          <img src={partner4} alt="Partners" />
          <img src={partner5} alt="Partners" />
          <img src={partner6} alt="Partners" />
          <img src={partner7} alt="Partners" />
          <img src={partner8} alt="Partners" />
          <img src={partner9} alt="Partners" />
          <img src={partner1} alt="Partners" />
          <img src={partner2} alt="Partners" />
          <img src={partner3} alt="Partners" />
        </motion.div>
      </div>
    </div>
  );
};

export default PartnersFr;
