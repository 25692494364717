import Banner from "../banner";
import NumberCounter from "../number-counter";

import bottlePills from "../../images/bottle-pills.svg";
import hospital from "../../images/hospital.svg";
import time from "../../images/time.svg";
import massage from "../../images/massage.svg";

const Impact = () => {
  return (
    <div className="p-5 py-10 lg:p-20">
      <Banner title="our impact" />
      <h1
        className="text-md md:text-2xl font-ibmPlexSans lg:font-pangaia 
        text-berkeleyBlue w-full lg:w-[40%] font-medium mt-5"
      >
        Leveraging our AI code trees, healthcare providers get single-digit
        loans to serve their communities with less worry about providing
        collateral.
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 mt-20">
        <NumberCounter img={bottlePills} num="1550" title="Pharmacies" />
        <NumberCounter img={hospital} num="2200" title="Hospitals" />
        <NumberCounter img={time} num="20" title="Years of Experience" />
        <NumberCounter
          img={massage}
          num="169000"
          title="Patients covered/month"
        />
      </div>
    </div>
  );
};

export default Impact;
