const Banner = ({ title, className }) => {
  return (
    <div
      className={` bg-[#DCE9D8] w-max rounded-full font-ibmPlexSans px-5 py-[5px] 
      flex justify-center items-center ${className}`}
    >
      <p className='text=[#11190F] text-[12px]'>{title.toUpperCase()}</p>
    </div>
  )
}

export default Banner
