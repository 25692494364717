import React, { useState } from "react";
import ReactPlayer from "react-player";

import imageVid from "../images/vid-cover.png";
import Modal from "./modal";

function VideoModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img
        onClick={openModal}
        src={imageVid}
        alt="Vids"
        className="w-full lg:w-[500px] cursor-pointer"
      />
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <div className="rounded-lg overflow-hidden w-full h-full">
          <ReactPlayer
            url="https://youtu.be/2KNq0F0tt10"
            width="100%"
            height="100%"
            controls={true}
            playing={isModalOpen}
            style={{
              borderRadius: "20px",
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default VideoModal;
