import illustration from "../images/Illustration.svg";
import { FaSearch } from "react-icons/fa";
import { commonTopicsFr } from "../data/data-fr";
import Card from "../components/sections/faq_card";
import { useState } from "react";
import rightArrow from "../images/rightArrow.svg";
import leftArrow from "../images/leftArrow.svg";
import whatsApp from "../images/WhatsApp.png";
import email_icon from "../images/Email.png";
import agent_image from "../images/agent.png";
import Banner from "../components/banner";

const FaqsFr = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => Math.max(0, prevSlide - 1));
  };
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => Math.min(2, prevSlide + 1));
  };
  return (
    <div>
      <div className="w-[100%]">
        <section className="relative h-[450px] bg-[#F7F7F9] sm:bg-gradient-to-br from-[#cbcbf1] to-[#eaf5f5] w-full pt-20">
          <div className="hidden sm:block absolute bottom-0 right-0 ">
            <img src={agent_image} alt="hero" className="h-[300px] w-[300px]" />
          </div>
          <div className="absolute top-20 right-5 sm:hidden ">
            <img src={illustration} alt="hero" className="h-[125x] w-[132px]" />
          </div>
          <div className="px-5 mt-[120px] sm:w-[500px] sm:px-8 space-y-8 sm:mt-[80px]">
            <Banner title="faqs" />
            <p className="text-[28px] text-berkeleyBlue sm:text-3xl">
              Allons vous régler
            </p>
            <form className="flex w-[100%] border border-[#808080] items-center py-1 px-4 rounded-lg bg-[#DEDEED] sm:border sm:border-[#8484C2]">
              <FaSearch />
              <input
                placeholder="rechercher un sujet, une question, n'importe quoi"
                className="p-1 w-full text-base font-ibmPlexSans text-[#4D4D4D] bg focus:outline-none pl-1 bg-transparent sm:text-lg sm:p-1"
              />
            </form>
          </div>
        </section>
        <section className="w-full p-5 sm:p-10">
          <h2 className="text-[28px] my-5 sm:text-[40px] text-berkeleyBlue font-medium">
            Parcourir les sujets courants
          </h2>
          <div className="mb-4 sm:grid grid-cols-3 gap-8">
            <div
              className={`flex flex-col gap-8 ${
                currentSlide !== 0 && "hidden"
              } sm:flex`}
            >
              <Card
                title={commonTopicsFr[0].title}
                color={commonTopicsFr[0].color}
                description={commonTopicsFr[0].description}
                icon={commonTopicsFr[0].icon}
                id={commonTopicsFr[0].id}
              />
              <Card
                title={commonTopicsFr[3].title}
                color={commonTopicsFr[3].color}
                description={commonTopicsFr[3].description}
                icon={commonTopicsFr[3].icon}
                id={commonTopicsFr[3].id}
              />
            </div>
            <div
              className={`flex flex-col gap-8 ${
                currentSlide !== 1 && "hidden"
              } sm:flex`}
            >
              <Card
                title={commonTopicsFr[1].title}
                color={commonTopicsFr[1].color}
                description={commonTopicsFr[1].description}
                icon={commonTopicsFr[1].icon}
                id={commonTopicsFr[1].id}
              />
              <Card
                title={commonTopicsFr[4].title}
                color={commonTopicsFr[4].color}
                description={commonTopicsFr[4].description}
                icon={commonTopicsFr[4].icon}
                id={commonTopicsFr[4].id}
              />
            </div>
            <div
              className={`flex flex-col gap-8 ${
                currentSlide !== 2 && "hidden"
              } sm:flex`}
            >
              <Card
                title={commonTopicsFr[2].title}
                color={commonTopicsFr[2].color}
                description={commonTopicsFr[2].description}
                icon={commonTopicsFr[2].icon}
                id={commonTopicsFr[2].id}
              />
              <Card
                title={commonTopicsFr[5].title}
                color={commonTopicsFr[5].color}
                description={commonTopicsFr[5].description}
                icon={commonTopicsFr[5].icon}
                id={commonTopicsFr[5].id}
              />
            </div>
          </div>
          <div className="flex justify-between items-center gap-2 mt-5">
            <div className="flex gap-2">
              <button
                className={`h-8 w-8 bg-[#E6E6E6] rounded-full flex justify-center items-center ${
                  currentSlide === 0 ? "opacity-20" : ""
                } sm:hidden`}
                onClick={handlePrevSlide}
                disabled={currentSlide === 0}
              >
                <img
                  src={leftArrow}
                  alt={leftArrow}
                  className="text-[#02325A] h-2 w-2"
                />
              </button>
              <button
                className={`h-8 w-8 bg-[#E6E6E6] rounded-full flex justify-center items-center ${
                  currentSlide === 2 ? "opacity-20" : ""
                } sm:hidden`}
                onClick={handleNextSlide}
                disabled={currentSlide === 2}
              >
                <img
                  src={rightArrow}
                  alt={rightArrow}
                  className="text-[#02325A] h-[10px] w-[10px]"
                />
              </button>
            </div>
            <div className="sm: hidden">
              {currentSlide + 1} of {3}
            </div>
          </div>
        </section>
        <section className="w-full sm:p-10">
          <div className="p-5 sm:w-[60%]">
            <h2 className="text-3xl text-[#02325A] font-medium leading-10">
              Vous ne trouvez pas ce que vous cherchez ?
            </h2>
            <p className="font-ibmPlexSans font-normal text-base mt-4 leading-8">
              Si vous avez d'autres questions ou avez besoin d'aide
              supplémentaire, contactez-nous appelez, envoyez un e-mail ou
              remplissez simplement le formulaire ci-dessous, et notre une
              équipe dédiée vous répondra dans les plus brefs délais. Vos
              demandes important, et nous sommes impatients de vous fournir les
              informations dont vous avez besoin.
            </p>
          </div>
          <div className=" sm:flex mb-20">
            <div className="flex gap-4 p-5 overflow-x-auto w-full sm:gap-8">
              <article className="w-[100%] bg-[#FBFBF8] py-2 px-[50px] flex flex-col justify-center items-center rounded-lg">
                <div className="w-10 h-10 rounded-full mb-8 bg-[#F2F5F8] flex justify-center items-center sm:w-[112px] sm:h-[112px]">
                  <img
                    src={email_icon}
                    alt={email_icon}
                    className="w-4 h-4 sm:h-12 sm:w-12 "
                  />
                </div>
                <p className="text-lg font-ibmPlexSans font-normal mb-2">
                  Envoyer un e-mail
                </p>
                <p className="text-base font-ibmPlexSans text-[#02325A]">
                  info@10mg.co.uk
                </p>
              </article>
              <article className="w-[100%] bg-[#FBFBF8] py-5 px-[50px] flex flex-col justify-center items-center rounded-lg">
                <div className="w-10 h-10 rounded-full mb-8 bg-[#F5F8F2] flex justify-center items-center sm:h-[112px] sm:w-[112px]">
                  <img
                    src={whatsApp}
                    alt={whatsApp}
                    className="w-4 h-4 sm:h-10 sm:w-10"
                  />
                </div>
                <p className="text-lg font-ibmPlexSans font-normal mb-2 text-[#B3001B]">
                  WhatsApp
                </p>
                <p className="text-base font-ibmPlexSans text-[#02325A]">
                  wa.me/10mg
                </p>
              </article>
            </div>
            <div className="p-5 sm:pl-2">
              <form className=" p-5 bg-[#EEF5FC] rounded-2xl border-b-4 border-[#E6E6E6] space-y-5 sm:space-y-2">
                <div className="flex flex-col">
                  <label className="mb-2 font-ibmPlexSans text-berkeleyBlue text-base">
                    Nom et prénom
                  </label>
                  <input
                    type="text"
                    placeholder="Votre nom"
                    className="p-4 rounded font-ibmPlexSans border-1 border-[#E6E6E6] drop-shadow sm:p-2"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-ibmPlexSans text-berkeleyBlue text-base">
                    E-mail
                  </label>
                  <input
                    type="email"
                    placeholder="You@company.com"
                    className="p-4 rounded font-ibmPlexSans border-1 border-[#E6E6E6] drop-shadow sm:p-2"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-ibmPlexSans text-berkeleyBlue text-base">
                    Comment pouvons nous aider?
                  </label>
                  <textarea
                    placeholder="Your message"
                    rows={3}
                    cols={40}
                    className="p-4 rounded font-ibmPlexSans border-1 border-[#E6E6E6] drop-shadow"
                  />
                </div>
                <div className="pt-4 sm:pt-2">
                  <button className="border border-berkeleyBlue w-full py-2 rounded-full drop-shadow text-base font-ibmPlexSans">
                    Envoyer le message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FaqsFr;
