import Banner from "../banner";
import spotlight1 from "../../img/mission.png";
import spotlight2 from "../../img/vision-img.png";

const SpotlightFr = () => {
  return (
    <>
      <div className="p-5 py-20 lg:p-20 text-center">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-10">
          <div className="w-full lg:w-1/2">
            <Banner title="notre mission" />
            <h1 className="text-left mt-5 text-2xl lg:text-3xl text-berkeleyBlue">
              10mg Pharma se consacre à étendre notre influence et notre impact
              dans le domaine de la santé en Afrique. A commencer par le Nigeria
              et le Sénégal.
            </h1>
            <p className="text-left mt-10 text-sm text-[#4D4D4D] font-ibmPlexSans">
              Nous allons intensifier nos efforts pour que 10 mg Pharma soit
              profondément enraciné à travers l’Afrique. Ghana et Kenya. Notre
              objectif sera d’atteindre un minimum de 2 millions de patients par
              mois au cours des cinq prochaines années.
            </p>
          </div>
          <img
            src={spotlight1}
            alt="Spotlight"
            className="w-full lg:w-[400px] rounded-lg"
          />
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center gap-10 mt-20 lg:mt-[250px]">
          <img
            src={spotlight2}
            alt="Spotlight"
            className="w-[400px] hidden lg:block rounded-lg"
          />
          <div className="w-full lg:w-1/2">
            <Banner title="NOTRE VISION" />
            <h1 className="text-left mt-5 text-2xl lg:text-3xl text-berkeleyBlue">
              Notre engagement à construire un avenir durable pour les soins de
              santé en L’Afrique est attachée à la défense d’une approche
              rentable et cohérente utilisation de médicaments contre la douleur
              chronique.
            </h1>
            <p className="text-left mt-10 text-sm text-[#4D4D4D] font-ibmPlexSans">
              Nous veillons à ce que les patients puissent accéder à ces
              médicaments via notre un soutien financier innovant aux
              prestataires de soins de santé.
            </p>
          </div>
          <img
            src={spotlight2}
            alt="Spotlight"
            className="w-full lg:hidden rounded-lg"
          />
        </div>
      </div>
    </>
  );
};

export default SpotlightFr;
