import React, { useRef, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCreative, Pagination, Autoplay } from "swiper/modules";

import Banner from "../banner";

const TestimonialsFr = () => {
  const testimonialsData = [
    {
      pharmacy: "Femih Pharmacy Ltd.",
      testimonial: `"Livraison très rapide, les produits sont très efficaces d'après les retours des patients. Bravo"`,
      occupation: "PHARMACIST",
      location: "OWO",
    },
    {
      pharmacy: "Aniwura Tijani.",
      testimonial: `"J'aime vraiment le 10 mg, car il m'a permis d'acheter des médicaments en toute transparence."`,
      occupation: "Chef de la technologie",
      location: "",
    },
    {
      pharmacy: "M4 Pharmacy.",
      testimonial: `"Le prix de 10mg Pharma est attractif, la livraison a été rapide, nous sommes ravis de faire affaire avec eux"`,
      occupation: "manager",
      location: "kaduna",
    },
    {
      pharmacy: "Tonia Pharmaceuticals Ltd.",
      testimonial: `"Satisfied with the quick response and delivery"`,
      occupation: "manager",
      location: "abuja, fct",
    },
    {
      pharmacy: "St Francis Health Consult.",
      testimonial: "Excellent",
      occupation: "md/ceo",
      location: "lokoja",
    },
    {
      pharmacy: "Ichegbo Jonadab Hospital",
      testimonial: `"10mg Medical Services are fast, good, affordable and Professional. 
        There is a good relationship between the company and their clients. 
        The medications are original and packaging is also impressive. Thank you."`,
      occupation: "Medical director",
      location: "lekki, lagos",
    },
    {
      pharmacy: "Mascunik Pharmacy Limited",
      testimonial: `"10mg is reliable in all ramifications. Just first transaction, delivered to my 
        office in my absence. Keep it up"`,
      occupation: "Director/Superintendent Pharmacist",
      location: "EKPOMA, BENIN",
    },
    {
      pharmacy: "Tijani Hospital",
      testimonial: `"This platform has allowed us to seemlessly manage the purchase of 
        our pain medication with excellence customer service"`,
      occupation: "CHIEF MEDICAL OFFICER",
      location: "JOS, NIGERIA",
    },
  ];

  const swiperRef = useRef(null);

  useEffect(() => {
    // Access Swiper instance on component mount
    if (swiperRef.current) {
      // Find all the grey containers
      const slideContainers = document.querySelectorAll(".select-container");

      slideContainers.forEach((container) => {
        container.addEventListener("click", () => {
          if (swiperRef.current.swiper.isEnd) {
            swiperRef.current.swiper.slideTo(0);
          } else {
            swiperRef.current.swiper.slideNext();
          }
        });
      });
    }
  }, []);

  return (
    <div className="p-5 py-20 lg:p-20 text-center">
      <div className="flex flex-col justify-center items-center">
        <Banner title="témoignages" />
        <h1
          className="text-left md:text-center text-2xl lg:text-3xl mt-7 w-full lg:w-1/2 
        text-berkeleyBlue"
        >
          Fiers de nos solutions percutantes, nous avons été reconnus pour nos
          contributions significatives au domaine de la santé.
        </h1>
      </div>
      <div className="relative">
        <div
          className="bg-[#E0EEDD] w-[90%] lg:w-[400px] mx-auto h-[300px] absolute top-[200px] left-1/2 
            -translate-x-1/2 -translate-y-1/2 -z-10 rounded-lg select-container"
        ></div>
        <div className="relative">
          <Swiper
            ref={swiperRef}
            effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            slidesPerView={1}
            spaceBetween={30}
            autoplay
            pagination={{ clickable: true }}
            modules={[EffectCreative, Pagination, Autoplay]}
          >
            {testimonialsData.map((testimonial, index) => (
              <SwiperSlide>
                {/* Grey Container */}
                <div
                  className={`font-ibmPlexSans bg-[#F2EAE6] p-10 mt-20 rounded-lg
              text-left text-berkeleyBlue w-full lg:w-[500px] h-[300px] mx-auto z-10 `}
                  key={index}
                >
                  <p className="text-xl font-bold">{testimonial.pharmacy}</p>
                  <div>
                    <p className="mt-5 text-md">{testimonial.testimonial}</p>
                    <div className="flex justify-start items-start gap-2 mt-10 text-xs font-medium">
                      <p>{testimonial.occupation.toUpperCase()}</p>
                      <p>{testimonial.location.toUpperCase()}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsFr;
