import React, { useState } from "react";
import data from "../map-data/data";

const StateSelection = ({ onStateChange }) => {
  const [selectedState, setSelectedState] = useState(null);
  const handleStateChange = (name) => {
    const foundState = data.find((state) => state.name === name);
    setSelectedState(foundState);
    onStateChange(foundState.address);
  };
  return (
    <div className="font-ibmPlexSans mt-8">
      {/* <h1 className="text-sm font-bold text-[#B3001B] mb-2">OUR LOCATIONS</h1> */}
      <div className="">
        <ul>
          {/* <div className='bg-[#F6F1EE] p-5 px-8 rounded-lg'>
            <p className='text-xl text-[#02325A] font-medium'>
              Regional Office
            </p>
            <h1 className='py-2 text-[#B3001B] text-2xl'>
              {selectedState ? selectedState.name : 'No state selected'}
            </h1>
          </div> */}
          <div className="flex flex-col lg:flex-row justify-center items-center gap-2 mt-5 ">
            {data.map((state) => (
              <li
                key={state.name}
                className={`bg-[#FAFAFC] text-black text-center rounded-full px-4 py-1 w-1/2 lg:w-max border border-[#EEEEF0], 
                ${
                  selectedState && selectedState.name === state.name
                    ? "bg-black text-white"
                    : ""
                }`}
              >
                <button onClick={() => handleStateChange(state.name)}>
                  {state.name}
                </button>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default StateSelection;
