import quote from "../../images/quote.png";
import logo from "../../images/logo.png";

import Banner from "../banner";

const Quote = () => {
  return (
    <div
      className="flex justify-start lg:justify-between 
        items-start gap-10 p-5 lg:p-10 
        py-20 bg-[#F7F7F9] relative"
      id="founder-desk"
    >
      {/* <div className="my-10 block lg:hidden">
        <Banner title="founder's desk" />
      </div> */}
      <div
        className="flex justify-center items-center border border-berkeleyBlue 
        border-opacity-25 w-[150px] h-[150px] rounded-full absolute left-[100px] top-[100px] p-10"
      >
        <img src={quote} alt="Quote" />
      </div>
      <div className="w-full lg:pl-[300px]">
        <div className="">
          <Banner title="founder's desk" />
        </div>
        <p className="mt-8 text-[25px] text-[#011627] w-full lg:w-[80%]">
          At 10mg Pharma, we are dedicated to ensuring universal access to
          exceptional medical care despite financial constraints. In the face of
          escalating inflation, our mission is to provide comprehensive
          healthcare solutions, offering hope to individuals across diverse
          financial circumstances.
        </p>
        <div className="flex flex-col justify-end items-end gap-3 mt-16 font-ibmPlexSans">
          <p className="text-[20px] text-berkeleyBlue">Christian Nwachukwu</p>
          <p className="text-[12px] text-[#4D4D4D]">CHIEF EXECUTIVE OFFICER</p>
          <img src={logo} alt="10mg Logo" />
        </div>
      </div>

      {/* Next and Previous buttons */}
      {/* <div></div> */}
    </div>
  );
};

export default Quote;
