import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/nav";
import Footer from "./components/footer";
import ScrollToTop from "./routes/helpers/ScrollToTop";
import { Toaster } from "react-hot-toast";
import { ChakraProvider } from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider>
        <ScrollToTop />
        {/* <Navbar /> */}
        <App />
        <Toaster />
        <Footer />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);
