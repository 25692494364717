// import { useState } from "react";
import { MdClose } from "react-icons/md";

const Modal = ({ isOpen, closeModal, children }) => {
  return (
    <div
      className={` ${
        !isOpen
          ? "hidden"
          : "text-center bg-black bg-opacity-90 w-full h-screen fixed top-0 left-0 z-[100]"
      }`}
    >
      <div className="bg-transparent flex flex-col justify-center items-center text-white h-screen p-10 py-28 lg:p-20 rounded-lg w-full lg:w-[90%] m-auto">
        <MdClose
          className="text-white w-6 h-6 absolute top-10 right-10 cursor-pointer"
          onClick={closeModal}
        />
        {children}
      </div>
    </div>
  );
};

export default Modal;
