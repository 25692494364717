import Banner from "../banner";

const AboutIntro = () => {
  return (
    <div className="p-5 lg:p-10 py-20 lg:py-[200px] flex flex-col justify-center items-start lg:items-center w-full lg:w-[48%] mx-auto">
      <Banner title="introduction" />
      <h2
        className="text-[16px] md:text-[25px] mt-4 text-berkeleyBlue font-ibmPlexSans 
             md:font-pangaia md:font-medium text-left lg:text-center"
      >
        We integrate technology and innovation into practical solutions as part
        of our strategy, aiming for a sustained impact in addressing healthcare
        challenges.
      </h2>
    </div>
  );
};

export default AboutIntro;
