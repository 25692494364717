import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ConfirmEmail from "../confirm";
import SupplierSignUpForm from "../supplier-signup-form";

const ConfirmPage = () => {
  return (
    <div className="flex justify-center items-center h-screen p-5 py-20 lg:p-20 w-[70%] mx-auto">
      <Tabs isFitted variant="red">
        <TabList mb="1em" className="flex justify-center gap-10">
          <Tab
            _selected={{
              color: "#11190F",
              bg: "#DCE9D8",
              borderRadius: "40px",
              fontWeight: "medium",
              padding: "5px 10px",
              width: "200px",
            }}
          >
            <p className="text-xs font-ibmPlexSans w-full">
              SIGNUP AS A PHARMACY
            </p>
          </Tab>
          <Tab
            _selected={{
              color: "#11190F",
              bg: "#DCE9D8",
              borderRadius: "40px",
              fontWeight: "medium",
              padding: "0px 30px",
              width: "200px",
            }}
          >
            <p className="text-xs font-ibmPlexSans">SIGNUP AS A SUPPLIER</p>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="text-center flex flex-col gap-3 justify-center">
              <h1 className="text-4xl text-berkeleyBlue text-center">
                Register your Pharmacy
              </h1>
              <p className="text-lg text-[#4D4D4D] font-ibmPlexSans">
                Let's get you on board
              </p>
            </div>
            <ConfirmEmail />
          </TabPanel>
          <TabPanel>
            <div className="text-center flex flex-col gap-3 justify-center">
              <h1 className="text-4xl text-berkeleyBlue text-center">
                Register your Business
              </h1>
              <p className="text-lg text-[#4D4D4D] font-ibmPlexSans">
                Let's get you on board
              </p>
            </div>
            <SupplierSignUpForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default ConfirmPage;
