const stateData = [
  {
    name: "Lagos",
    address: "Block B, Suite 23, Obi Village Ikeja",
    country: "Nigeria",
  },
  {
    name: "Kaduna",
    address: "NO 17, KACHA ROAD, SABO KADUNA",
    country: "Nigeria",
  },
  {
    name: "Birmingham",
    address: "B2 Tomlinson House, 325 Tyburn Road B24 8HJ, Birmingham",
    country: "UK",
  },
  {
    name: "Delaware",
    address:
      "1007 N, Orange ST. 4TH Floor, STE 1382 Wilmington, Delaware 19801",
    country: "USA",
  },
  {
    name: "Dakar",
    address: "Dhub de la DER/FJ, Point E - rue Saint Louis - immeuble Jaraaf.",
    country: "Senegal",
  },
];

export default stateData;
