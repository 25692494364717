import Banner from "../banner";
import NumberCounter from "../number-counter";

import bottlePills from "../../images/bottle-pills.svg";
import hospital from "../../images/hospital.svg";
import time from "../../images/time.svg";
import massage from "../../images/massage.svg";

const ImpactFr = () => {
  return (
    <div className="p-5 py-10 lg:p-20">
      <Banner title="notre IMPACT" />
      <h1
        className="text-md md:text-2xl font-ibmPlexSans lg:font-pangaia 
        text-berkeleyBlue w-full lg:w-[40%] font-medium mt-5"
      >
        En tirant parti de nos arbres de codes d’IA, les prestataires de soins
        de santé obtiennent des prêts à un chiffre pour servir leurs communautés
        sans se soucier de fournir des garanties.
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 mt-20">
        <NumberCounter img={bottlePills} num="760" title="Pharmacies" />
        <NumberCounter img={hospital} num="1550" title="Hôpitaux" />
        <NumberCounter img={time} num="20" title="Des années d'expérience" />
        <NumberCounter img={massage} num="160" title="Patients couverts/mois" />
      </div>
    </div>
  );
};

export default ImpactFr;
