import Banner from "../banner";

const AboutIntroFr = () => {
  return (
    <div className="p-5 lg:p-10 py-20 lg:py-[200px] flex flex-col justify-center items-start lg:items-center w-full lg:w-[48%] mx-auto">
      <Banner title="introduction" />
      <h2
        className="text-[16px] md:text-[25px] mt-4 text-berkeleyBlue font-ibmPlexSans 
             md:font-pangaia md:font-medium text-left lg:text-center"
      >
        Nous intégrons la technologie et l’innovation dans des solutions
        pratiques dans le cadre de notre stratégie, visant un impact durable
        pour relever les défis des soins de santé.
      </h2>
    </div>
  );
};

export default AboutIntroFr;
