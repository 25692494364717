import { Link } from "react-router-dom";

const ConfirmEmail = () => {
  return (
    <div>
      <form
        className="flex flex-col gap-6 mt-10 font-ibmPlexSans 
            border border-[#EEEEF0] p-5 rounded-lg text-center"
      >
        <div className="flex flex-col gap-2">
          <input
            type="number"
            name="code"
            id="code"
            placeholder="Enter verification code"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-full border border-berkeleyBlue 
                        bg-transparent px-4 py-2 text-sm font-medium text-berkeleyBlue  
                        hover:bg-blue-200 focus:outline-none focus-visible:ring-2 
                        focus-visible:ring-blue-500 focus-visible:ring-offset-2 mt-3"
        >
          Verify email
        </button>
      </form>
      <p className="text-[#808080] font-ibmPlexSans text-center mt-7">
        Didn't get code?
        <Link to="/login" className="text-berkeleyBlue pl-1">
          Resend
        </Link>
      </p>
    </div>
  );
};

export default ConfirmEmail;
