import { Link } from "react-router-dom";

const Card = ({ title, icon, color, id, description }) => {
    console.log(id)
  return (
    <article
      className="flex gap-5 py-4 px-5 h-[112px] rounded-lg  w-full"
      style={{ background: color }}
    >
      <div className="w-[32px] h-[32px] bg-[#EEF4F6] rounded-full flex justify-center items-center">
        <img src={icon} className="w-4 h-4" alt={icon} />
      </div>
      <Link to={`/faqCategory/${id}`}>
        <div className="flex flex-col gap-4 font-ibmPlexSans">
          <h5 className="text-lg text-[#02325A] font-normal">{title}</h5>
          <p>{description}</p>
        </div>
      </Link>
    </article>
  );
};

export default Card;
