import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import Banner from "./banner";

const LoginForm = () => {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [pharmName, setPharmName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [message, setMessage] = useState("");

  //Error validation states
  // eslint-disable-next-line
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    setEmailError("");
    setPasswordError("");

    let hasErrors = false;

    if (email.length < 1 && !email) {
      setEmailError("Please enter your email address");
      hasErrors = true;
    }

    if (!email.includes("@") && !email.includes(".")) {
      setEmailError("Please enter a valid email address");
      hasErrors = true;
    }

    if (password.length < 1 && !password) {
      setPasswordError("Please enter your password");
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const response = await fetch("https://api.10mg.co.uk/10mg/register", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          pharmName: pharmName,
          phone: phone,
          email: email,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // eslint-disable-next-line
      const responseData = await response.json();

      if (response.status === 200) {
        setName("");
        setPharmName("");
        setPhone("");
        setEmail("");
        setPassword("");
        setMessage("Registration successful");
        navigate("/confirm-pharmacy");
      } else {
        setMessage("Some error occured");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="flex justify-center items-center p-5 py-20 h-screen">
      <div className="w-full lg:w-1/3">
        <div className="text-center flex flex-col gap-3 justify-center items-center">
          <Banner title="login" />
          <h1 className="text-4xl text-berkeleyBlue text-center">Welcome</h1>
          <p className="text-lg text-[#4D4D4D] font-ibmPlexSans">
            Glad to have you back
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          method="post"
          target="_top"
          className="flex flex-col gap-6 mt-4 font-ibmPlexSans 
            border border-[#EEEEF0] p-5 rounded-lg"
        >
          <div className="flex flex-col gap-2">
            <label
              htmlFor="fullName"
              className="text-sm font-ibmPlexSans text-berkeleyBlue"
            >
              Email Address
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              placeholder="you@company.com"
              className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="password"
              className="text-sm font-ibmPlexSans text-berkeleyBlue"
            >
              Password
            </label>
            <div
              className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0] 
              flex justify-between items-center"
            >
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="bg-transparent w-full outline-none border-0"
              />
              {isPasswordVisible ? (
                <MdOutlineVisibilityOff
                  onClick={togglePasswordVisibility}
                  className="cursor-pointer text-[#999]"
                />
              ) : (
                <MdOutlineVisibility
                  onClick={togglePasswordVisibility}
                  className="cursor-pointer text-[#999]"
                />
              )}
            </div>
            <span className="text-red-500">{passwordError}</span>
          </div>

          <button
            type="submit"
            className="inline-flex justify-center rounded-full border border-berkeleyBlue 
                        bg-transparent px-4 py-2 text-sm font-medium text-berkeleyBlue  hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          >
            Login
          </button>
        </form>
        <p className="text-[#808080] font-ibmPlexSans text-center mt-4">
          Forgot your password?{" "}
          <Link to="/forgot-password" className="text-berkeleyBlue">
            reset it here
          </Link>
        </p>
        <p className="text-[#808080] font-ibmPlexSans text-center mt-4">
          Don't have an account?{" "}
          <Link to="/signup-pharmacy" className="text-berkeleyBlue">
            Register here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
