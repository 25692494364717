import Banner from "../banner";

import remote from "../../images/remote.svg";
import development from "../../images/development.svg";
import insurance from "../../images/insurance.svg";
import leave from "../../images/leave.svg";
import employee from "../../images/employee.svg";

const Benefits = () => {
  const benefitsImages = [remote, development, insurance, leave, employee];

  const benefits = [
    {
      icon: benefitsImages[0],
      title: "Remote First",
      description: `10mg Pharma is dedicated to fostering efficiency and flexibility through 
        remote work. We advocate for digital collaboration across our diverse employee 
        base, and we offer the flexibility of a hybrid work experience, especially for 
        those in proximity to our offices.`,
    },
    {
      icon: benefitsImages[1],
      title: "Professional Development",
      description: `We prioritize the development of our employees, supporting them in 
      reaching their short-term, medium-term, and long-term professional objectives.`,
    },
    {
      icon: benefitsImages[2],
      title: "Health Insurance",
      description: `We provide a comprehensive health insurance to cover the medical needs of 
      you and your dependents.`,
    },
    {
      icon: benefitsImages[3],
      title: "Paid annual leave",
      description: `At 10mg Pharma, we value work-life balance and to that effect all team 
      members enjoy 20 days of paid annual leave, allowing for essential breaks and ensuring 
      a refreshed and motivated workforce.`,
    },
    {
      icon: benefitsImages[4],
      title: "Employee Pension",
      description: `At 10mg Pharma, we prioritize the financial well-being of our team members. 
      Through our comprehensive pension plan, we empower our employees to plan for a secure and 
      fulfilling future.`,
    },
  ];

  return (
    <div className="p-5 py-20 lg:p-20">
      <Banner title="perks & benefits" />
      <h1 className="text-berkeleyBlue text-3xl mt-5">
        You’ll love working here
      </h1>
      <div
        className="mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 
        "
      >
        {benefits.map((benefit, index) => (
          <div
            className="flex flex-col justify-center items-start font-ibmPlexSans 
            bg-[#FBFBF8] p-5 rounded-lg"
          >
            <div
              className="bg-[#DFF0F6] w-10 h-10 rounded-full flex 
            justify-center items-center mb-5"
            >
              <img src={benefit.icon} alt="Remote First" className="w-4" />
            </div>
            <p className="text-[#B3001B] text-lg">{benefit.title}</p>
            <p className="text-[#011627] text-[14px] mt-2">
              {benefit.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
