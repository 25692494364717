import HealthCard from "./healthCareCard";
import healthCare from "../../img/automate-img.png";
import healthCare1 from "../../img/automate-img.png";
import healthCare2 from "../../img/grow-img.png";
import healthCare3 from "../../images/afsp13.png";
import healthCare4 from "../../images/afsp15.png";
import healthCare5 from "../../images/afsp16.png";
import rightArrow from "../../images/rightArrow.svg";
import leftArrow from "../../images/leftArrow.svg";
import { useState } from "react";
import { motion } from "framer-motion";

const HealthCare = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => Math.max(0, prevSlide - 1));
  };
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => Math.min(2, prevSlide + 1));
  };
  const titles = [
    "FOR HEALTH CARE PROVIDERS",
    "FOR SUPPLIERS",
    "FOR FINANCIAL INSTITUTIONS",
  ];
  console.log(titles[currentSlide - 1]);
  return (
    <section className="p-5 w-full sm:px-10 sm:py-20">
      <motion.div
        key={currentSlide}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.2 }}
        transition={{ duration: 1 }}
        className="flex"
      >
        <div className={`${currentSlide !== 0 && "hidden"} `}>
          {currentSlide === 0 && (
            <HealthCard
              title="FOR HEALTH CARE PROVIDERS"
              subTitle="Unlock growth for your business and prioritize patient well-being"
              description="Elevate the trajectory of your business by capitalizing on our
        exclusive discount offers, designed to unlock substantial growth
        opportunities. Simultaneously, prioritize the well-being of your
        patients by leveraging these cost-saving advantages. Our tailored
        discount programs not only fuel the expansion of your business but
        also contribute to enhancing patient care by making chronic pain
        medications more accessible and affordable for your patients."
              cardOneTitle="Automate your procurement process for the year"
              cardOneImage={healthCare}
              cardTwoImage={healthCare2}
              cardTwoTitle="Grow at 10x, say goodbye to collaterals and high interest rates"
              cardOneBack="Simplify your monthly medication procurement by embracing an automated system that takes the stress out of the process. With our automated medication purchase, you can set up a seamless and stress-free routine for obtaining your required medications each year. This entails a hassle-free ordering and delivery process, ensuring that you consistently have the necessary medications without the need for constant manual intervention."
              cardTwoBack="Bid farewell to the burdens of collaterals and exorbitant interest rates as you chart a path to business growth and expansion into underserved communities. Our innovative financial solutions offer a liberating alternative, allowing your business to thrive without the traditional constraints."
            />
          )}
        </div>
        <div className={`${currentSlide !== 1 && "hidden"} `}>
          {currentSlide === 1 && (
            <HealthCard
              title="FOR SUPPLIERS"
              subTitle="Grow your brand visibility, use our space"
              description="Boost the visibility of your brand by taking advantage of our prime space dedicated to showcasing medications. Elevate your brand presence and reach a wider audience as we provide a platform specifically designed for promoting your pharmaceutical products. Our strategic placement ensures maximum exposure, making your medications stand out in the market."
              cardOneTitle="Increase your ROI by 5x, Lower your marketing cost"
              cardOneImage={healthCare1}
              cardTwoImage={healthCare3}
              cardTwoTitle="You are social, your brand is helping underserved communities"
              cardOneBack="Reduce your marketing expenditures significantly, while enjoying a fivefold increase in return on investment, enabling you to extend your reach to a broader community. Our cost-effective strategies are designed to optimize your marketing budget, ensuring that every investment yields substantial returns."
              cardTwoBack="Demonstrate the unwavering commitment of your brand to serving rural communities by actively engaging in initiatives that address their unique needs and challenges. Our brand is dedicated to making a meaningful impact in these often-overlooked areas, where access to essential services may be limited.."
            />
          )}
        </div>
        <div className={`${currentSlide !== 2 && "hidden"} `}>
          {currentSlide === 2 && (
            <HealthCard
              title="FOR FINANCIAL INSTITUTIONS"
              subTitle="Take a deep into the $4.3 Billion chronic pain market in Africa"
              description="Position yourself at the forefront of a transformative journey. This isn't merely an exploration of financial opportunities; it's an opportunity to contribute meaningfully to the well-being of communities, addressing a pressing health concern and making a lasting impact on lives across the continent."
              cardOneTitle="Seamless and hurdle-free investing"
              cardOneImage={healthCare4}
              cardTwoImage={healthCare5}
              cardTwoTitle="Embrace social innovative financing and in-depth healthcare data for future transformation"
              cardOneBack="Investing in a new market can be tricky. At 10mg, we simplify your entry mode to the African healthcare market through generative AI data keyed to long-term KYC analytics. Our system verifies each healthcare provider, ensuring that they meet your funding criteria."
              cardTwoBack="Social innovative financing introduces a holistic perspective, emphasizing community impact and sustainable outcomes. By exploring novel funding models that prioritize social responsibility, you not only drive positive change within healthcare systems but also contribute to broader societal well-being. This approach ensures that financial strategies align with the social mission of improving healthcare accessibility, equity, and outcomes."
            />
          )}
        </div>
      </motion.div>
      <div className="flex justify-between items-center gap-2 mt-5 sm:px-5">
        <div className="flex sm:justify-between gap-4 w-full sm:mt-20">
          <button
            className={`w-max  flex justify-center items-center py-2 sm:px-4 gap-4 ${
              currentSlide === 0 ? "opacity-20 sm:opacity-0" : ""
            }`}
            onClick={handlePrevSlide}
            disabled={currentSlide === 0}
          >
            <div className="h-8 w-8 bg-[#E6E6E6] rounded-full flex justify-center items-center ">
              <img
                src={leftArrow}
                className="text-[#02325A] h-3 w-3"
                alt={leftArrow}
              />
            </div>
            <p className="hidden sm:block text-berkeleyBlue font-ibmPlexSans font-medium tracking-tight text-[20px]">
              {titles[currentSlide - 1]}
            </p>
          </button>
          <button
            className={`w-max flex justify-center items-center py-2 sm:px-4 gap-4 ${
              currentSlide === 2 ? "opacity-20 sm:opacity-0" : ""
            }`}
            onClick={handleNextSlide}
            disabled={currentSlide === 2}
          >
            <p className="hidden sm:block text-berkeleyBlue font-ibmPlexSans font-medium tracking-tight text-[20px]">
              {titles[currentSlide + 1]}
            </p>
            <div className="h-8 w-8 bg-[#E6E6E6] rounded-full flex justify-center items-center ">
              <img
                src={rightArrow}
                alt={rightArrow}
                className="text-[#02325A] h-3 w-3"
              />
            </div>
          </button>
        </div>
        <div className="sm:hidden w-full text-end">
          {currentSlide + 1} of {3}
        </div>
      </div>
    </section>
  );
};

export default HealthCare;
