import sol1 from "../../img/collateral-img.png";
import sol2 from "../../images/sol2.png";
import Banner from "../banner";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const Solution = () => {
  const [animationStage, setAnimationStage] = useState(0);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setAnimationStage(1);
    }, 2000);

    const timer2 = setTimeout(() => {
      setAnimationStage(2);
    }, 5000);

    const intervalId = setInterval(() => {
      setAnimationStage(2);
    }, 3000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearInterval(intervalId);
    };
  }, []);

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.8 } },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center gap-8 p-5 lg:p-10 py-20">
      <div className="w-full relative">
        {/* Image Top */}
        <motion.img
          src={sol1}
          alt="Solutions descriptions"
          className="w-[100%] lg:w-[80%] z-10"
          initial={animationStage >= 2 ? "hidden" : "visible"}
          animate={animationStage >= 2 ? "visible" : "hidden"}
          variants={imageVariants}
        />

        {/* Image Below */}
        <motion.img
          src={sol2}
          alt="Solutions descriptions"
          className="w-[100%] lg:w-[80%] rotate-6 absolute top-0 left-0 -z-10 rounded-xl"
          initial="visible"
          animate={animationStage >= 1 ? "hidden" : "visible"}
          variants={imageVariants}
        />
      </div>
      <div className="w-full">
        <Banner title="our solution" />
        <div className="space-y-4 mt-9">
          <motion.h1
            className="text-[30px] text-berkeleyBlue leading-[38.4px]"
            initial={animationStage >= 1 ? "hidden" : "visible"}
            animate={animationStage >= 1 ? "visible" : "hidden"}
            variants={textVariants}
          >
            A collateral free, seamless access to single-digit loans
          </motion.h1>
          <motion.p
            className="text-[16px] text-[#3E5B34] font-ibmPlexSans"
            initial={animationStage >= 1 ? "hidden" : "visible"}
            animate={animationStage >= 1 ? "visible" : "hidden"}
            variants={textVariants}
          >
            Leveraging our AI code trees, healthcare providers get single-digit
            loans to serve their communities with less worry about providing
            collateral and high-interest rates from traditional lenders.
          </motion.p>
        </div>
        <div className="space-y-4 mt-9">
          <motion.h1
            className="text-[30px] text-berkeleyBlue leading-[38.4px]"
            initial={animationStage >= 2 ? "hidden" : "visible"}
            animate={animationStage >= 2 ? "visible" : "hidden"}
            variants={textVariants}
          >
            {" "}
            Increased profit margin for healthcare providers, cost saving for
            patients.
          </motion.h1>
          <motion.p
            className="text-[16px] text-[#3E5B34] font-ibmPlexSans"
            initial={animationStage >= 2 ? "hidden" : "visible"}
            animate={animationStage >= 2 ? "visible" : "hidden"}
            variants={textVariants}
          >
            Our strategic partnerships and advanced financing with chronic
            medication manufacturers are closing the supply chain gap, enabling
            healthcare providers to access medication at a significant 23%
            discount, while patients benefit from a 15% reduction in their
            medication costs
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default Solution;
