import { useRef, useState } from "react";

const Privacy = () => {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);
  const [selectedItem, setSelectedItem] = useState("section1");

  const scrollToSection = (elementRef, data) => {
    console.log(data);
    setSelectedItem(data);
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="w-[100%] p-3 mx-auto sm:p-2">
      <div className="my-20 sm:flex w-[95%] m-auto">
        <div className="hidden sm:block w-[560px] mt-[132px]">
          <ul className="text-berkeleyBlue grid gap-2 text-[14px] font-ibmPlexSans ">
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section1"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section1, "section1")}
            >
              overview
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section2"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section2, "section2")}
            >
              Personal identification information
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section3"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section3, "section3")}
            >
              non-Personal identification information
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section4"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section4, "section4")}
            >
              Web browser cookies
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section5"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section5, "section5")}
            >
              How we protect your information
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section6"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section6, "section6")}
            >
              Sharing your personal information
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section7"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section7, "section7")}
            >
              Third party websites
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section8"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section8, "section8")}
            >
              Changes to this privacy policy
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section9"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section9, "section9")}
            >
              Your acceptance of these terms
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section10"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section10, "section10")}
            >
              contacting us
            </li>
          </ul>
        </div>
        <div className="sm:w-[60%]">
          <div class=" flex flex-col gap-8">
            <div class="w-[342px] flex flex-col gap-4 sm:flex-row justify-between">
              <span class="w-max bg-[#DCE9D8] text-xs rounded-full text-center py-1 px-4 text-darkGreen">
                Privacy
              </span>
              <p class="text-sm font-medium text-darkGreen">
                LAST UPDATED - October 16, 22
              </p>
            </div>
            <h1 class="text-xl font-medium font-pp-pangaia text-berkeleyBlue mb-10">
              Privacy Policy
            </h1>
          </div>

          <section ref={section1}>
            <h2 className="text-lg mb-6 font-ibmPlexSans text-berkeleyBlue">
              Overview
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              This Privacy Policy governs the manner in which 10mg Pharma
              collects, uses, maintains and discloses information collected from
              users (each, a “User”) of the 10mg.co.uk website (“Site”). This
              privacy policy applies to the Site and all products and services
              offered by 10MILESGREEN PHARMA LTD.
            </p>
          </section>
          <section ref={section2}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Personal identification information
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, subscribe to the newsletter, fill out a form, and in
              connection with other activities, services, features or resources
              we make available on our Site. Users may be asked for, as
              appropriate, name, email address, mailing address, phone number.
              Users may, however, visit our Site anonymously. We will collect
              personal identification information from Users only if they
              voluntarily submit such information to us. Users can always refuse
              to supply personally identification information, except that it
              may prevent them from engaging in certain Site related activities.
            </p>
          </section>
          <section ref={section3}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              non-Personal identification information
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.
            </p>
          </section>
          <section ref={section4}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Web browser cookies
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Our Site may use “cookies” to enhance User experience. User s web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly. How we use collected
              information, 10mg Pharma may collect and use Users personal
              information for the following purposes:
              <ul className=" list-disc ml-6 mt-6">
                <li>To improve customer service.</li>
                <li>
                  Information you provide helps us respond to your customer
                  service requests and support needs more efficiently.
                </li>
                <li>To improve our Site.</li>
                <li>
                  We may use feedback you provide to improve our products and
                  services.
                </li>
                <li>To send periodic emails.</li>
                <li>
                  We may use the email address to send User information and
                  updates pertaining to their order. It may also be used to
                  respond to their inquiries, questions, and/or other requests.
                  If User decides to opt-in to our mailing list, they will
                  receive emails that may include company news, updates, related
                  product or service information, etc. If at any time the User
                  would like to unsubscribe from receiving future emails, we
                  include detailed unsubscribe instructions at the bottom of
                  each email.
                </li>
              </ul>
            </p>
          </section>
          <section ref={section5}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              How we protect your information
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
          </section>
          <section ref={section6}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Sharing your personal information
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              We do not sell, trade, or rent Users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above.We may
              use third party service providers to help us operate our business
              and the Site or administer activities on our behalf, such as
              sending out newsletters or surveys. We may share your information
              with these third parties for those limited purposes provided that
              you have given us your permission.
            </p>
          </section>
          <section ref={section7}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Third party websites
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, suppliers, advertisers,
              sponsors, licensors and other third parties. We do not control the
              content or links that appear on these sites and are not
              responsible for the practices employed by websites linked to or
              from our Site. In addition, these sites or services, including
              their content and links, may be constantly changing. These sites
              and services may have their own privacy policies and customer
              service policies. Browsing and interaction on any other website,
              including websites which have a link to our Site, is subject to
              that website s own terms and policies.
            </p>
          </section>
          <section ref={section8}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Changes to this privacy policy
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              10mg Pharma has the discretion to update this privacy policy at
              any time. When we do, we will revise the updated date at the
              bottom of this page. We encourage Users to frequently check this
              page for any changes to stay informed about how we are helping to
              protect the personal information we collect. You acknowledge and
              agree that it is your responsibility to review this privacy policy
              periodically and become aware of modifications.
            </p>
          </section>
          <section ref={section9}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              Your acceptance of these terms
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
          </section>
          <section ref={section10}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              contacting us
            </h2>
            <p className="text-base font-ibmPlexSans text-naturalGrey my-6 leading-7">
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              using any of our provided channels below
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
