import HealthCard from "./healthCareCard";
import healthCare from "../../img/automate-img.png";
import healthCare1 from "../../img/automate-img.png";
import healthCare2 from "../../img/grow-img.png";
import healthCare3 from "../../images/afsp13.png";
import healthCare4 from "../../images/afsp15.png";
import healthCare5 from "../../images/afsp16.png";
import rightArrow from "../../images/rightArrow.svg";
import leftArrow from "../../images/leftArrow.svg";
import { useState } from "react";
import { motion } from "framer-motion";

const HealthCareFr = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => Math.max(0, prevSlide - 1));
  };
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => Math.min(2, prevSlide + 1));
  };
  const titles = [
    "POUR LES PRESTATAIRES DE SOINS DE SANTÉ",
    "POUR LES FOURNISSEURS",
    "POUR LES INSTITUTIONS FINANCIÈRES",
  ];
  console.log(titles[currentSlide - 1]);
  return (
    <section className="p-5 w-full sm:px-10 sm:py-20">
      <motion.div
        key={currentSlide}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.2 }}
        transition={{ duration: 1 }}
        className="flex"
      >
        <div className={`${currentSlide !== 0 && "hidden"} `}>
          {currentSlide === 0 && (
            <HealthCard
              title="POUR LES PRESTATAIRES DE SOINS DE SANTÉ"
              subTitle="Libérez la croissance de votre entreprise et donnez la priorité au bien-être des patients"
              description="Élevez la trajectoire de votre entreprise en capitalisant sur nos offres de réduction exclusives, conçues pour débloquer des opportunités de croissance substantielles. Simultanément, donnez la priorité au bien-être de vos patients en tirant parti de ces avantages économiques. Nos programmes de réduction sur mesure alimentent non seulement l’expansion de votre entreprise, mais contribuent également à améliorer les soins aux patients en rendant les médicaments contre la douleur chronique plus accessibles et plus abordables pour vos patients."
              cardOneTitle="Automatisez votre processus d’approvisionnement pour l’année"
              cardOneImage={healthCare}
              cardTwoImage={healthCare2}
              cardTwoTitle="Croissez à 10x, dites adieu aux garanties et aux taux d’intérêt élevés"
              cardOneBack="Simplifiez votre achat mensuel de médicaments en adoptant un système automatisé qui élimine le stress du processus. Grâce à notre achat automatisé de médicaments, vous pouvez mettre en place une routine transparente et sans stress pour obtenir les médicaments dont vous avez besoin chaque année. Cela implique un processus de commande et de livraison sans tracas, garantissant que vous disposez toujours des médicaments nécessaires sans avoir besoin d’une intervention manuelle constante."
              cardTwoBack="Dites adieu au fardeau des garanties et aux taux d’intérêt exorbitants alors que vous tracez la voie vers la croissance de votre entreprise et son expansion dans des communautés mal desservies. Nos solutions financières innovantes offrent une alternative libératrice, permettant à votre entreprise de prospérer sans les contraintes traditionnelles."
            />
          )}
        </div>
        <div className={`${currentSlide !== 1 && "hidden"} `}>
          {currentSlide === 1 && (
            <HealthCard
              title="POUR LES FOURNISSEURS"
              subTitle="Développez la visibilité de votre marque, utilisez notre espace"
              description="Boostez la visibilité de votre marque en profitant de notre espace privilégié dédié à la vitrine des médicaments. Renforcez la présence de votre marque et atteignez un public plus large grâce à une plateforme spécialement conçue pour promouvoir vos produits pharmaceutiques. Notre placement stratégique garantit une exposition maximale, permettant à vos médicaments de se démarquer sur le marché."
              cardOneTitle="Augmentez votre retour sur investissement par 5, réduisez vos coûts marketing"
              cardOneImage={healthCare1}
              cardTwoImage={healthCare3}
              cardTwoTitle="Vous êtes social, votre marque aide les communautés mal desservies"
              cardOneBack="Réduisez considérablement vos dépenses marketing, tout en bénéficiant d’un retour sur investissement multiplié par cinq, vous permettant ainsi d’étendre votre portée à une communauté plus large. Nos stratégies rentables sont conçues pour optimiser votre budget marketing, garantissant que chaque investissement génère des retours substantiels."
              cardTwoBack="Démontrez l’engagement inébranlable de votre marque au service des communautés rurales en vous engageant activement dans des initiatives qui répondent à leurs besoins et défis uniques. Notre marque s'engage à avoir un impact significatif dans ces domaines souvent négligés, où l'accès aux services essentiels peut être limité."
            />
          )}
        </div>
        <div className={`${currentSlide !== 2 && "hidden"} `}>
          {currentSlide === 2 && (
            <HealthCard
              title="POUR LES INSTITUTIONS FINANCIÈRES"
              subTitle="Plongez dans le marché de la douleur chronique de 4,3 milliards de dollars en Afrique"
              description="Placez-vous à l’avant-garde d’un voyage transformateur. Il ne s'agit pas simplement d'une exploration d'opportunités financières ; c'est l'occasion de contribuer de manière significative au bien-être des communautés, en répondant à un problème de santé urgent et en ayant un impact durable sur la vie à travers le continent."
              cardOneTitle="Un investissement fluide et sans obstacles"
              cardOneImage={healthCare4}
              cardTwoImage={healthCare5}
              cardTwoTitle="Adoptez un financement social innovant et des données approfondies sur les soins de santé pour une transformation future"
              cardOneBack="Investir dans un nouveau marché peut être délicat. À 10 mg, nous simplifions votre mode d'entrée sur le marché africain de la santé grâce à des données d'IA génératives liées à des analyses KYC à long terme. Notre système vérifie chaque prestataire de soins de santé, garantissant qu'il répond à vos critères de financement."
              cardTwoBack="Le financement social innovant introduit une perspective holistique, mettant l’accent sur l’impact communautaire et les résultats durables. En explorant de nouveaux modèles de financement qui donnent la priorité à la responsabilité sociale, vous suscitez non seulement des changements positifs au sein des systèmes de santé, mais vous contribuez également au bien-être sociétal plus large. Cette approche garantit que les stratégies financières s'alignent sur la mission sociale consistant à améliorer l'accessibilité, l'équité et les résultats des soins de santé."
            />
          )}
        </div>
      </motion.div>
      <div className="flex justify-between items-center gap-2 mt-5 sm:px-5">
        <div className="flex sm:justify-between gap-4 w-full sm:mt-20">
          <button
            className={`w-max  flex justify-center items-center py-2 sm:px-4 gap-4 ${
              currentSlide === 0 ? "opacity-20 sm:opacity-0" : ""
            }`}
            onClick={handlePrevSlide}
            disabled={currentSlide === 0}
          >
            <div className="h-8 w-8 bg-[#E6E6E6] rounded-full flex justify-center items-center ">
              <img
                src={leftArrow}
                className="text-[#02325A] h-3 w-3"
                alt={leftArrow}
              />
            </div>
            <p className="hidden sm:block text-berkeleyBlue font-ibmPlexSans font-medium tracking-tight text-[20px]">
              {titles[currentSlide - 1]}
            </p>
          </button>
          <button
            className={`w-max flex justify-center items-center py-2 sm:px-4 gap-4 ${
              currentSlide === 2 ? "opacity-20 sm:opacity-0" : ""
            }`}
            onClick={handleNextSlide}
            disabled={currentSlide === 2}
          >
            <p className="hidden sm:block text-berkeleyBlue font-ibmPlexSans font-medium tracking-tight text-[20px]">
              {titles[currentSlide + 1]}
            </p>
            <div className="h-8 w-8 bg-[#E6E6E6] rounded-full flex justify-center items-center ">
              <img
                src={rightArrow}
                alt={rightArrow}
                className="text-[#02325A] h-3 w-3"
              />
            </div>
          </button>
        </div>
        <div className="sm:hidden w-full text-end">
          {currentSlide + 1} of {3}
        </div>
      </div>
    </section>
  );
};

export default HealthCareFr;
