import React, { useState } from "react";
import Banner from "../banner";

import shipping from "../../img/shipping-img.png";
import support from "../../img/support.png";
import guarantee from "../../img/guarantee.png";
import checkout from "../../img/checkout.png";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Autoplay, Scrollbar } from "swiper/modules";

const ProcessFr = () => {
  const images = [shipping, support, guarantee, checkout];
  const textContent = [
    {
      title: "Expédition",
      description:
        "Délai de traitement : 3 jours ouvrables. Livraison estimée : généralement 5 à 7 jours ouvrables pour la plupart des pays.",
    },
    {
      title: "Assistance 24h/24 et 7j/7",
      description:
        "Si vous avez une question ou un problème. Nous avons un taux de réponse de 100% le jour même, week-end et jours fériés compris, toute l'année.",
    },
    {
      title: "Garantie",
      description:
        "Nous vous promettons une expérience sans tracas. Nous garantissons la qualité des médicaments tout en veillant à ce que votre expérience avec nous soit mémorable.",
    },
    {
      title: "Paiement sécurisé",
      description:
        "Payez en toute transparence et sans tracas en utilisant votre carte de débit, PayPal, virement bancaire, code USSD (à venir) et faites livrer vos produits à votre adresse professionnelle enregistrée.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndicator, setActiveIndicator] = useState(0);

  return (
    <div className="p-2 md:p-5 lg:p-10 py-10 text-center ">
      <div
        className="flex flex-col justify-center items-center w-full lg:w-1/2 
        mx-auto space-y-3 text-berkeleyBlue py-10"
      >
        <Banner title="notre processus" />
        <h1 className="text-[28px]">
          Nous sommes fiers de fournir constamment un service exceptionnel grâce
          à notre processus rationalisé en quatre étapes.
        </h1>
      </div>

      {/* IMAGES */}

      <div className="w-full relative p-5 lg:p-10">
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          grabCursor={true}
          centeredSlides={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          pagination={false}
          modules={[Autoplay, Scrollbar]}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
            setActiveIndicator(swiper.activeIndex);
          }}
        >
          <div className="flex items-center justify-center gap-1">
            {images.map((image, index) => (
              <SwiperSlide>
                <img
                  key={index}
                  src={image}
                  alt={textContent[index].title}
                  className="rounded-lg w-[600px]"
                  style={{
                    opacity: activeIndex === index ? 1 : 0.1,
                  }}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>

        {/* TEXTS CONTAINER THAT CHANGE */}
        <div
          className="bg-[#F6F4EF] p-5 py-4 lg:py-8 rounded-lg mt-10 w-[50%] 
        sm:w-1/2 lg:w-1/4 text-left space-y-4 absolute left-0 lg:left-[100px] bottom-8 lg:bottom-20 z-10"
        >
          <h1 className="text-sm lg:text-3xl text-[#B3001B]">
            {textContent[activeIndex].title}
          </h1>
          <p className="text-[10px] lg:text-[13px] font-ibmPlexSans text-berkeleyBlue">
            {textContent[activeIndex].description}
          </p>
        </div>

        {/* Indicators */}
        <div className="flex justify-center items-center gap-3 mt-10">
          <div
            className={`w-[100px] h-[2px] transition-colors ${
              activeIndicator === 0 ? "bg-[#3E5B34]" : "bg-[#E6E6E6]"
            }`}
          ></div>
          <div
            className={`w-[100px] h-[2px] transition-colors ${
              activeIndicator === 1 ? "bg-[#3E5B34]" : "bg-[#E6E6E6]"
            }`}
          ></div>
          <div
            className={`w-[100px] h-[2px] transition-colors ${
              activeIndicator === 2 ? "bg-[#3E5B34]" : "bg-[#E6E6E6]"
            }`}
          ></div>
          <div
            className={`w-[100px] h-[2px] transition-colors ${
              activeIndicator === 3 ? "bg-[#3E5B34]" : "bg-[#E6E6E6]"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProcessFr;
