import prob1 from "../../img/fb-img.png";
import prob2 from "../../img/navigate-img.png";
import prob3 from "../../img/interest-img.png";

import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import Banner from "../banner";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const ProblemFr = () => {
  const [activeScreen, setActiveScreen] = useState(2);

  const screenVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
    exit: { opacity: 0, x: 100, transition: { duration: 0.7 } },
  };

  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
    exit: { opacity: 0, x: 50, transition: { duration: 0.7 } },
  };

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveScreen((prev) => (prev + 1) % 3);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleNextScreen = () => {
    setActiveScreen((prevIndex) => (prevIndex + 1) % 3);
  };

  const handlePreviousScreen = () => {
    setActiveScreen((prevIndex) => (prevIndex - 1 + 3) % 3);
  };

  return (
    <div className="p-5 lg:p-10 py-20 bg-[#F7F7F9]">
      <div className="flex flex-col justify-center items-start w-full md:w-[28%]">
        <Banner title="LE PROBLÈME" />
        <h2
          className="text-[16px] md:text-[25px] mt-4 text-berkeleyBlue font-ibmPlexSans 
            md:h2 md:font-pangaia md:font-medium"
        >
          En 2022, l’Afrique a été témoin d’un taux stupéfiant de 70 % des décès
          mondiaux liés à la douleur chronique...
        </h2>
      </div>

      <AnimatePresence mode="wait">
        {/* Screen 1 */}
        {activeScreen === 0 && (
          <motion.div
            key="screen1"
            className="mt-10 flex justify-center items-center gap-[100px] w-full"
            variants={screenVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="h-[300px]">
              <motion.div variant={textVariants} key="text1">
                <h3 className="text-[#B3001B] text-[23px] my-5 font-pangaia-medium">
                  Un fardeau financier croissant
                </h3>
              </motion.div>
              <motion.div variant={imageVariants} key="img1">
                <img
                  src={prob1}
                  alt="Problem description illustration"
                  className="md:hidden w-full rounded-md"
                />
              </motion.div>
              <p
                className="text-[#011627] text-[16px] mt-10 md:mt-0 
                font-ibmPlexSans w-full lg:w-[70%]"
              >
                Le coût élevé des médicaments contre la douleur chronique,
                gonflé de manière irrégulière, est six fois plus élevé que celui
                des autres médicaments. La plupart des patients sont incapables
                de maintenir leur régime, ce qui constitue une grave
                préoccupation.
              </p>
            </div>

            <motion.div variant={imageVariants} key="img1">
              <img
                src={prob1}
                alt=""
                className="hidden md:block w-[80%] rounded-md"
              />
            </motion.div>
          </motion.div>
        )}

        {/* Screen 2 */}
        {activeScreen === 1 && (
          <motion.div
            key="screen2"
            className="mt-10 flex  justify-center items-center gap-[100px] w-full"
            variants={screenVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="h-[300px]">
              <motion.div variant={textVariants} key="text2">
                <h3 className="text-[#B3001B] text-[23px] my-5 font-pangaia-medium">
                  Faire face au fardeau d’une faible couverture d’assurance
                  maladie
                </h3>
              </motion.div>
              <motion.div variant={imageVariants} key="img2">
                <img
                  src={prob2}
                  alt="Problem description illustration"
                  className="md:hidden w-full lg:w-[100px] rounded-md"
                />
              </motion.div>
              <p className="text-[#011627] text-[16px] mt-10 md:mt-0 font-ibmPlexSans w-full lg:w-[70%]">
                Avec seulement 2,19 % de couverture par l’assurance maladie, la
                plupart des patients sont responsables de la hausse des coûts
                des médicaments.
              </p>
            </div>
            <motion.div variant={imageVariants} key="img2">
              <img
                src={prob2}
                alt=""
                className="hidden md:block w-[80%] rounded-md"
              />
            </motion.div>
          </motion.div>
        )}

        {/* Screen 3 */}
        {activeScreen === 2 && (
          <motion.div
            key="screen3"
            className="mt-10 flex justify-center items-center gap-[100px] w-full"
            variants={screenVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="h-[300px]">
              <motion.div variant={textVariants} key="text3">
                <h3 className="text-[#B3001B] text-[23px] my-5 font-pangaia-medium">
                  Taux d’intérêt élevés sur les prêts et les garanties
                </h3>
              </motion.div>
              <motion.div variant={imageVariants} key="img3">
                <img
                  src={prob3}
                  alt="Problem description illustration"
                  className="md:hidden w-full md:w-[100%] rounded-md"
                />
              </motion.div>
              <p className="text-[#011627] text-[16px] mt-10 md:mt-0 font-ibmPlexSans w-full lg:w-[70%]">
                En 2021, 83 % des prestataires de soins de santé des communautés
                mal desservies ont été confrontés à des refus de prêt en raison
                de l'incapacité d'offrir des garanties, associé aux taux
                d'intérêt élevés des prêteurs traditionnels.
              </p>
            </div>

            <motion.div variant={imageVariants} key="img3">
              <img
                src={prob3}
                alt=""
                className="hidden md:block w-[80%] rounded-md"
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Pagination - Desktop only */}
      <div className="hidden md:flex justify-start items-center gap-2">
        {Array(3)
          .fill()
          .map((_, index) => (
            <div
              className={`h-[2px] w-[100px] rounded-full cursor-pointer ${
                index === activeScreen ? "bg-[#B3001B]" : "bg-[#E6E6E6]"
              } `}
            ></div>
          ))}
      </div>

      {/* Navigation Buttons - Mobile only */}
      <div className="flex justify-start items-center md:hidden gap-2 mt-10 ">
        <div
          className="w-8 h-8 rounded-full bg-[#E6E6E6] opacity-5 flex justify-center 
            items-center text-berkeleyBlue cursor-pointer"
          onClick={handlePreviousScreen}
        >
          <IoChevronBack />
        </div>
        <div
          className="w-8 h-8 rounded-full bg-[#E6E6E6] flex justify-center 
            items-center text-berkeleyBlue cursor-pointer"
          onClick={handleNextScreen}
        >
          <IoChevronForward />
        </div>
      </div>
    </div>
  );
};

export default ProblemFr;
