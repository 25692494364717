import healthPic from "../../img/me.png";
import healthPic2 from "../../img/me.png";
import problemPic3 from "../../img/me.png";

import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const SuppliersFr = () => {
  const [activeScreen, setActiveScreen] = useState(2);

  const screenVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.2 } },
    exit: { opacity: 0, transition: { duration: 0.2 } },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
    exit: { opacity: 0, y: 100, transition: { duration: 0.7 } },
  };

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveScreen((prev) => (prev + 1) % 3);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleNextScreen = () => {
    setActiveScreen((prevIndex) => (prevIndex + 1) % 3);
  };

  const handlePreviousScreen = () => {
    setActiveScreen((prevIndex) => (prevIndex - 1 + 3) % 3);
  };

  return (
    <div className="p-5 lg:p-10 py-20">
      <AnimatePresence mode="wait">
        {/* Screen 1 */}
        {activeScreen === 0 && (
          <motion.div
            key="screen1"
            className="mt-10 flex flex-col-reverse md:flex-row justify-between items-center md:gap-10 w-full"
            variants={screenVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="md:w-1/2">
              <motion.div variant={textVariants} key="text1">
                <h3 className="text-berkeleyBlue text-[23px] mt-4 md:my-5 font-pangaia-medium">
                  Exposition accrue au marché
                </h3>
              </motion.div>

              <p className="w-full text-[#011627] text-[16px] mt-4 md:mt-0 font-ibmPlexSans md:w-[85%]">
                Le partenariat avec 10mg Pharma ouvre les portes à une
                exposition plus large au marché. Présentez vos produits à un
                public diversifié et large, en tirant parti de notre réseau pour
                atteindre de nouveaux clients et étendre votre présence sur le
                marché.
              </p>
            </div>

            <div className="md:w-1/2">
              <motion.div variant={imageVariants} key="img1">
                <img
                  src={healthPic}
                  alt="Problem description illustration"
                  className="md:hidden w-full md:w-[40%] rounded-md"
                />
              </motion.div>
              <motion.div variant={imageVariants} key="img1">
                <img
                  src={healthPic}
                  alt=""
                  className="hidden md:block w-full rounded-md"
                />
              </motion.div>
            </div>
          </motion.div>
        )}

        {/* Screen 2 */}
        {activeScreen === 1 && (
          <motion.div
            key="screen2"
            className="mt-10 flex flex-col-reverse md:flex-row justify-between items-center md:gap-10 w-full"
            variants={screenVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="md:w-1/2">
              <motion.div variant={textVariants} key="text2">
                <h3 className="text-berkeleyBlue text-[21px] md:text-[23px] mt-4 md:my-5 font-pangaia-medium">
                  Support et ressources dédiés
                </h3>
              </motion.div>
              <p className="text-[#011627] text-[16px] mt-4 md:mt-0 font-ibmPlexSans md:w-[85%]">
                Bénéficiez d’un accompagnement dédié et de ressources adaptées à
                votre succès. Notre engagement s'étend au-delà des
                transactions ; nous fournissons des ressources, des conseils et
                un environnement collaboratif pour aider optimiser vos
                opérations, en garantissant un résultat mutuellement bénéfique
                et partenariat prospère.
              </p>
            </div>
            <div className="md:w-1/2">
              <motion.div variant={imageVariants} key="img2">
                <img
                  src={healthPic2}
                  alt="Problem description illustration"
                  className="md:hidden w-full md:w-[100%] rounded-md"
                />
              </motion.div>
              <motion.div variant={imageVariants} key="img2">
                <img
                  src={healthPic2}
                  alt=""
                  className="hidden md:block w-[100%] rounded-md md:h-full"
                />
              </motion.div>
            </div>
          </motion.div>
        )}

        {/* Screen 3 */}
        {activeScreen === 2 && (
          <motion.div
            key="screen3"
            className="mt-10 flex flex-col-reverse md:flex-row justify-between items-center md:gap-10 w-full"
            variants={screenVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="md:w-1/2">
              <motion.div variant={textVariants} key="text3">
                <h3 className="text-berkeleyBlue text-[21px] md:text-[23px] mt-4 md:my-5 font-pangaia-medium">
                  Collaborations stratégiques et initiatives d'innovation
                </h3>
              </motion.div>

              <p className="text-berkeleyBlue text-[16px] mt-4 md:mt-0 font-ibmPlexSans md:w-[85%]">
                S'engager dans des collaborations stratégiques et des
                initiatives d'innovation avec 10mg Pharma. Accédez à des
                opportunités uniques de co-créer, innover et contribuer aux
                avancées du secteur pharmaceutique l'industrie, en positionnant
                votre marque à l'avant-garde solutions de pointe.
              </p>
            </div>

            <div className="md:w-1/2">
              <motion.div variant={imageVariants} key="img3">
                <img
                  src={problemPic3}
                  alt="Problem description illustration"
                  className="md:hidden w-full md:w-[100%] rounded-md"
                />
              </motion.div>
              <motion.div variant={imageVariants} key="img3">
                <img
                  src={problemPic3}
                  alt=""
                  className="hidden md:block w-[100%] rounded-md"
                />
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Pagination - Desktop only */}
      <div className="hidden md:flex justify-start items-center gap-2 mt-10">
        {Array(3)
          .fill()
          .map((_, index) => (
            <div
              className={`h-[2px] w-[100px] rounded-full cursor-pointer ${
                index === activeScreen ? "bg-[#B3001B]" : "bg-[#E6E6E6]"
              } `}
            ></div>
          ))}
      </div>

      {/* Navigation Buttons - Mobile only */}
      <div className="flex justify-start items-center md:hidden gap-2 mt-10 ">
        <div
          className="w-8 h-8 rounded-full bg-[#E6E6E6] opacity-5 flex justify-center 
            items-center text-berkeleyBlue cursor-pointer"
          onClick={handlePreviousScreen}
        >
          <IoChevronBack />
        </div>
        <div
          className="w-8 h-8 rounded-full bg-[#E6E6E6] flex justify-center 
            items-center text-berkeleyBlue cursor-pointer"
          onClick={handleNextScreen}
        >
          <IoChevronForward />
        </div>
      </div>
    </div>
  );
};

export default SuppliersFr;
