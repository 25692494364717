import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Banner from "../banner";

import draper from "../../images/draper.svg";
import aston from "../../images/aston.svg";
import microsoft from "../../images/microsoft.svg";
import intel from "../../images/intel.svg";
import bseen from "../../images/bseen.svg";
import afritech from "../../images/afritech.svg";
import sheffield from "../../images/sheffield.svg";
import FormDialog from "../form-dialog";

const Supporters = () => {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const targetSection = urlParams.get("section");

    if (targetSection) {
      const element = document.getElementById(targetSection);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className="p-5 py-20 lg:p-20 text-center" id="supporters">
      <div className="flex flex-col justify-center items-center">
        <Banner title="supporters" />
        <h1 className="text-left md:text-center text-2xl lg:text-3xl mt-7 w-full lg:w-1/2 text-berkeleyBlue">
          Backed by robust partners and investors, we come together in our
          shared mission to improve accessibility to healthcare
        </h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5 mt-10">
        <img src={draper} alt="Draper University" />
        <img src={aston} alt="Draper University" />
        <img src={microsoft} alt="Draper University" />
        <img src={intel} alt="Draper University" />
        <img src={bseen} alt="Draper University" />
        <img src={afritech} alt="Draper University" />
        <img src={sheffield} alt="Draper University" />
        <div className="flex flex-col justify-center items-center">
          <p className="text-berkeleyBlue text-sm font-ibmPlexSans">
            Buy this slot *wink wink*
          </p>
          <h1 className="text-berkeleyBlue text-2xl">Become a supporter</h1>
          <FormDialog />
        </div>
      </div>
    </div>
  );
};

export default Supporters;
