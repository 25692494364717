import Banner from "../banner";

const CValues = () => {
  const values = [
    {
      position: "01",
      title: "Innovation",
      description:
        "Foster a culture of continuous innovation for groundbreaking healthcare solutions.",
    },
    {
      position: "02",
      title: "Integrity",
      description:
        "People rely on our tools doing exactly what we say they do.",
    },
    {
      position: "03",
      title: "Empathy and Compassion",
      description:
        "Prioritize empathy in all interactions, recognizing the human impact of our work.",
    },
    {
      position: "04",
      title: "Collaboration",
      description:
        "Emphasize the importance of teamwork and diverse collaboration.",
    },
    {
      position: "05",
      title: "Community Impact",
      description:
        "Highlight the commitment to making a positive impact on underserved communities.",
    },
    {
      position: "06",
      title: "Employee Well-being",
      description:
        "Prioritize the well-being of employees with benefits and a healthy work-life balance.",
    },
  ];

  return (
    <div id="our-culture" className="p-5 py-20 lg:p-20">
      <Banner title="Our Values" />
      <h1 className="text-3xl mt-5 text-berkeleyBlue w-full lg:w-1/2">
        We are resolved to empowering people to create the best work of their
        lives.
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mt-10">
        {values.map((value) => (
          <div>
            <Banner
              title={value.position}
              className="bg-[#C3E3FD] text-berkeleyBlue w-5 h-7 px-0"
            />
            <h1 className="text-2xl mt-2 text-berkeleyBlue">{value.title}</h1>
            <p className="text-sm font-ibmPlexSans mt-3">{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CValues;
