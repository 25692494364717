import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const libraries = ["places"];

const mapContainerStyle = {
  width: "600px",
  height: "400px",
};

const defaultCenter = {
  lat: 37.4224,
  lng: -122.084,
};

const Map = ({ selectedAddress }) => {
  const [mapRef, setMapRef] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isMarkerVisible, setIsMarkerVisible] = useState(true);

  //The marker blinking effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsMarkerVisible((prevVisible) => !prevVisible);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCgF7kJmOP24WKz0K1bSh1I1xJ87_lZjuw",
    libraries,
  });

  const onMapLoad = (map) => {
    setMapRef(map);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  useEffect(() => {
    if (!mapRef || !selectedAddress) return;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: selectedAddress }, (results, status) => {
      if (status === "OK") {
        mapRef.panTo(results[0].geometry.location);
        setSelectedMarker(results[0].geometry.location);
      }
    });
  }, [mapRef, selectedAddress]);

  if (loadError)
    return (
      <div className="bg-red-200 text-red-500 p-5 rounded-lg">
        Map error! Check your Network connection
      </div>
    );
  if (!isLoaded)
    return (
      <div className="border border-[#EEEEF0] p-5 text-[#EEEEF0] rounded-lg font-ibmPlexSans">
        Loading...
      </div>
    );

  return (
    <div className="flex justify-center items-center">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={defaultCenter}
        onLoad={onMapLoad}
      >
        {selectedMarker && (
          <Marker
            position={selectedMarker}
            animation={window.google.maps.Animation.BOUNCE}
            onClick={handleMarkerClick}
            icon={{
              url: "/images/marker.png",
              scaledSize: new window.google.maps.Size(20, 20),
            }}
            visible={isMarkerVisible}
          >
            <InfoWindow position={selectedMarker}>
              <div className="bg-transparent p-1 rounded-lg shadow-md flex items-center ">
                {selectedAddress}
              </div>
            </InfoWindow>
          </Marker>
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;
