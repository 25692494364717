import { BsChevronLeft } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import Banner from "./banner";
import CareerForm from "./career-form";

import { useState } from "react";

const OpeningDetails = () => {
  const location = useLocation();
  const opening = location.state;
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState("overview");

  const isActive = (sectionId) => activeSection === sectionId;

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setActiveSection(sectionId);
  };

  return (
    <div className="p-5 py-20 lg:p-20 flex justify-between items-start gap-5 relative">
      <div
        className="hidden lg:block h-[200px] text-berkeleyBlue font-ibmPlexSans 
        w-[20%]"
      >
        <button
          className="font-ibmPlexSans flex justify-start items-center 
            text-berkeleyBlue text-sm"
          onClick={() => navigate(-1)}
        >
          <BsChevronLeft size={10} />
          Back to all listings
        </button>
        <div className="mt-20">
          <h1 className="font-medium text-sm">JOB DESCRIPTION</h1>
          <ul className="mt-5 space-y-3">
            <li
              className={`cursor-pointer p-1 px-4 w-max rounded-full 
         focus:bg-[#F5F5F5] focus:outline-none ${
           isActive("overview") ? "bg-[#F5F5F5]" : ""
         }`}
              onClick={() => scrollToSection("overview")}
            >
              Overview
            </li>
            <li
              className={`cursor-pointer p-1 px-4 w-max rounded-full 
         focus:bg-[#F5F5F5] focus:outline-none ${
           isActive("responsibilities") ? "bg-[#F5F5F5]" : ""
         }`}
              onClick={() => scrollToSection("responsibilities")}
            >
              Responsibilities
            </li>
            <li
              className={`cursor-pointer p-1 px-4 w-max rounded-full 
         focus:bg-[#F5F5F5] focus:outline-none ${
           isActive("qualifications") ? "bg-[#F5F5F5]" : ""
         }`}
              onClick={() => scrollToSection("qualifications")}
            >
              Qualifications
            </li>
            <li
              className={`cursor-pointer p-1 px-4 w-max rounded-full 
         focus:bg-[#F5F5F5] focus:outline-none ${
           isActive("benefits") ? "bg-[#F5F5F5]" : ""
         }`}
              onClick={() => scrollToSection("benefits")}
            >
              Benefits
            </li>
          </ul>
        </div>
      </div>

      {opening ? (
        <div className="w-full lg:w-2/3">
          <Banner title="full time - remote" />
          <h1 className="text-3xl text-berkeleyBlue mt-5">{opening.title}</h1>
          <div className="font-ibmPlexSans mt-10">
            {/* Overview */}
            <div id="overview">
              <h1 className="text-md font-medium text-berkeleyBlue">
                OVERVIEW
              </h1>
              <p className="mt-2 text-[#4D4D4D]">
                Join our dynamic team at 10mg Pharma as a Pharmaceutical
                Research Scientist and contribute to groundbreaking research and
                development in the pharmaceutical field. As a key member of our
                research team, you will play a crucial role in advancing
                pharmaceutical solutions that address healthcare challenges,
                particularly in underserved communities in Africa. If you are
                passionate about driving innovation, exploring novel treatments,
                and making a meaningful impact on global health, this is the
                opportunity for you.
              </p>
            </div>

            {/* Responsibilities */}
            <div id="responsibilities" className="mt-10">
              <h1 className="text-md font-medium text-berkeleyBlue">
                RESPONSIBILITIES
              </h1>
              <ol className="text-[#4D4D4D] list-decimal space-y-7 mt-2">
                <li>
                  Research and Development
                  <ul className="space-y-3 mt-3">
                    <li>
                      - Design and conduct experiments to develop new
                      pharmaceutical products and improve existing formulations.
                    </li>
                    <li>
                      - Collaborate with cross-functional teams to explore
                      innovative solutions that align with the company's
                      mission.
                    </li>
                  </ul>
                </li>

                {/* Data Analysis */}
                <li>
                  Data Analysis
                  <ul className="space-y-3 mt-3">
                    <li>
                      - Analyze and interpret experimental data using scientific
                      methodologies and statistical tools.
                    </li>
                    <li>
                      - Generate insights and recommendations based on research
                      findings to inform decision-making.
                    </li>
                  </ul>
                </li>

                {/* Documentation and Reporting */}
                <li>
                  Documentation and Reporting
                  <ul className="space-y-3 mt-3">
                    <li>
                      - Maintain detailed records of experiments, procedures,
                      and outcomes.
                    </li>
                    <li>
                      - Prepare comprehensive reports, research papers, and
                      documentation for internal and external stakeholders.
                    </li>
                  </ul>
                </li>

                {/* Regulatory Compliance */}
                <li>
                  Regulatory Compliance
                  <ul className="space-y-3 mt-3">
                    <li>
                      - Stay abreast of regulatory requirements in the
                      pharmaceutical industry.
                    </li>
                    <li>
                      - Ensure that research activities comply with ethical and
                      regulatory standards.
                    </li>
                  </ul>
                </li>

                {/* Collaboration and Communication */}
                <li>
                  Collaboration and Communication
                  <ul className="space-y-3 mt-3">
                    <li>
                      - Work closely with cross-disciplinary teams, including AI
                      engineers, supply chain professionals, and quality
                      assurance specialists.
                    </li>
                    <li>
                      - Communicate research findings and contribute to team
                      discussions and project planning.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>

            {/* Qualifications */}
            <div id="qualifications" className="mt-10">
              <h1 className="text-md font-medium text-berkeleyBlue">
                QUALIFICATIONS
              </h1>
              <ul className="space-y-3 mt-2 text-[#4D4D4D] ">
                <li>
                  - Ph.D. or equivalent in Pharmaceutical Sciences,
                  Pharmacology, Biochemistry, or a related field.
                </li>
                <li>
                  - Proven experience in pharmaceutical research and
                  development.
                </li>
                <li>
                  - Strong analytical and problem-solving skills, with a keen
                  attention to detail.
                </li>
                <li>
                  - Excellent communication skills, both written and verbal
                </li>
                <li>- Ability to work collaboratively in a team environment</li>
              </ul>
            </div>

            {/* Benefits */}
            <div id="benefits" className="mt-10">
              <h1 className="text-md font-medium text-berkeleyBlue">
                BENEFITS
              </h1>
              <ul className="space-y-3 mt-2 text-[#4D4D4D] ">
                <li>- Competitive salary and benefits package.</li>
                <li>
                  - Opportunities for professional development and career
                  advancement.
                </li>
                <li>
                  - Engaging work environment focused on innovation and impact.
                </li>
                <li>
                  - Contribution to meaningful research with the potential to
                  improve healthcare access globally.
                </li>
              </ul>
            </div>
          </div>

          <CareerForm />
        </div>
      ) : (
        <p>Opening details not found.</p>
      )}
    </div>
  );
};

export default OpeningDetails;
