const ServiceHero = () => {
  return (
    <section className="relative p-5 bg-service-hero-bg bg-cover bg-center h-screen flex flex-col justify-end">
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="relative z-10 mb-4 sm:self-end sm:mb-14 mr-10">
        <span className="font-ibmPlexSans text-xs bg-[#DCE9D8] py-1 px-4 rounded-full text-[#1F1F1F]">
          SERVICES
        </span>
        <h1 className="text-[#FFFFFF] text-3xl tracking-wide leading-10 font-medium mt-6 mb-3 sm:text-[56px] sm:w-[450px] mr-4 sm:leading-[75px] sm:font-bold">
          Healthcare that works.
        </h1>
        <p className="text-[#FFFFFF] font-ibmPlexSans leading-9 sm:text-[18px]">
          Changing lives with accessible and affordable healthcare
        </p>
      </div>
    </section>
  );
};

export default ServiceHero;
