import Banner from "../banner";
import VideoModal from "../video-modal";

const Why10mg = () => {
  return (
    <div className="h-auto lg:h-[700px] relative">
      <div
        className="p-5 py-20 lg:p-20 lg:py-[130px] flex flex-col lg:flex-row justify-center lg:justify-end items-center 
          gap-8 bg-[#EFF5FA]"
      >
        <div className="w-full lg:w-1/2 lg:absolute top-[7rem] left-[5rem]">
          <VideoModal />
          {/* <img src={imageVid} alt="Vids" className="w-full lg:w-[500px]" /> */}
        </div>
        <div className="flex flex-col justify-center items-start gap-5 w-full lg:w-1/2">
          <Banner title="why 10mg pharma" />
          <h1 className="text-3xl text-berkeleyBlue">
            We Bridge the Gap to Affordable Chronic Pain Medication in Africa.
          </h1>
          <p className="text-[#4D4D4D] font-ibmPlexSans text-lg lg:text-sm">
            Our mission is to improve the quality of life for people suffering
            from chronic pains. We are achieving this through AI-driven
            financing, with innovative and effective pharmaceutical solutions.
            Our dedication and integrity are the driving force behind our
            continuous effort to better understand chronic pains, to provide
            comprehensive management options, and to deliver them in a manner
            that is accessible and affordable for healthcare care providers and
            patients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Why10mg;
