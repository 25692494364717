import Suppliers from "../../components/sections/serv_suppliers";
import Banner from "../banner";

const ServiceSuppliers = () => {
  return (
    <section className="p-5 mt-20 sm:px-10 sm:flex flex-col sm:py-20">
      <div className="sm:text-center sm:self-center">
        <div className="flex justify-center">
          <Banner title="for suppliers" />
        </div>
        <div className="sm:w-[800px] self-center">
          <h3 className="text-[20px] text-berkeleyBlue leading-10 font-medium mt-10 tracking-wide sm:text-[32px]">
            Increase your customer base and provide more value
          </h3>
          <p className="text-[#4D4D4D] font-ibmPlexSans leading-6 tracking-wide my-4 sm:leading-8 sm:text-[18px]  sm:text-center sm:px-3">
            Elevate your brand's reach and impact by partnering with 10mg
            Pharma. Our collaboration provides suppliers with the opportunity to
            expand networks, connect with diverse healthcare providers, and
            contribute to innovative solutions.
          </p>
        </div>
      </div>
      <Suppliers />
    </section>
  );
};

export default ServiceSuppliers;
