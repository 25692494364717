import Banner from "../banner";

const ServiceHeroFr = () => {
  return (
    <section className="relative p-5 bg-service-hero-bg bg-cover bg-center h-screen flex flex-col justify-end">
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="relative z-10 mb-4 sm:self-end sm:mb-14 mr-10">
        <Banner title="Prestations de service" />
        <h1 className="text-[#FFFFFF] text-3xl tracking-wide leading-10 font-medium mt-6 mb-3 sm:text-[56px] sm:w-[450px] mr-4 sm:leading-[75px] sm:font-bold">
          Des soins de santé qui fonctionnent.
        </h1>
        <p className="text-[#FFFFFF] font-ibmPlexSans leading-9 sm:text-[18px]">
          Changer des vies avec des soins de santé accessibles et abordables
        </p>
      </div>
    </section>
  );
};

export default ServiceHeroFr;
