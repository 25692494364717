import accessImg from "../../img/access-img.png";
import { FaChevronRight } from "react-icons/fa6";

const AccessSectionFr = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-10 p-5 py-20 lg:p-20">
      <img src={accessImg} alt={accessImg} className="w-full md:w-[350px]" />
      <div>
        <h3 className="text-2xl text-berkeleyBlue">
          Accès aux prêts transfrontaliers à un chiffre
        </h3>
        <div className="flex items-center gap-2 text-md text-[#3E5B34] mt-1">
          <FaChevronRight className="h-3 w-2" />
          <p>
            Croissez à 10x, dites adieu aux garanties et aux taux d’intérêt
            élevés
          </p>
        </div>
        <p className="text-[#4D4D4D] font-ibmPlexSans text-sm leading-[30px] mt-3">
          Dites adieu au fardeau des garanties et aux taux d’intérêt exorbitants
          alors que vous tracez la voie vers la croissance de votre entreprise
          et vous développez dans des communautés mal desservies. Nos solutions
          financières innovantes proposent des prêts à un chiffre sans besoin de
          garantie, permettant à votre entreprise de prospérer sans les
          contraintes traditionnelles. Ce soutien financier vous permet
          d'étendre vos services, d'atteindre davantage de communautés et
          d'investir dans des ressources essentielles.
        </p>
      </div>
    </div>
  );
};

export default AccessSectionFr;
