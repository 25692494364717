import { useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../images/logo.png";
// import truck from "../images/truck.png";

import "./nav.css";
import LanguageDropdown from "./language-dropdown";

const Navbar = ({ onLanguageChange }) => {
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  const [mobileNav, setMobileNav] = useState(false);
  const toggleMobileNav = () => {
    setMobileNav(!mobileNav);
    setHamburgerOpen(!mobileNav);
  };

  const closeMobileNav = (event) => {
    // Check if the clicked element is outside the navbar
    if (!event.target.closest(".navbar")) {
      setMobileNav(false);
      setHamburgerOpen(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("click", closeMobileNav);

    const pathname = location.pathname;
    if (pathname === "/" && window.scrollY >= 0) {
      window.addEventListener("scroll", changeColor);
    } else if (
      pathname === "/services" ||
      pathname === "/about" ||
      pathname === "/careers" ||
      pathname === "/blog" ||
      pathname === "/faqs"
    ) {
      setColor(true);
    }

    return () => {
      window.removeEventListener("scroll", changeColor);
      window.removeEventListener("click", closeMobileNav);
    };
  }, [location]);

  const handleNavLinkClick = () => {
    setMobileNav(false);
    setHamburgerOpen(false);
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const handleLanguageChange = (language) => {
    console.log("Nav language:", language);
    onLanguageChange(language);
  };

  return (
    <nav
      className={
        color
          ? "nav-scroll text-white font-[400] font-ibmPlexSans text-[14px]"
          : color === "orange"
          ? `bg-berkeleyBlue text-white font-[400] font-ibmPlexSans text-[14px]`
          : "text-white font-[400] font-ibmPlexSans text-[14px]"
      }
    >
      <div className="navbar">
        <NavLink to="/">
          <img src={logo} alt="10mg brand logo" className="logo" />
        </NavLink>
        <div className={mobileNav ? "nav-links open" : "nav-links"}>
          <NavLink
            to="/"
            onClick={handleNavLinkClick}
            style={({ isActive }) => ({
              color: isActive ? "#1A70B8" : "white",
              fontWeight: isActive ? "700" : "400",
            })}
            className="flex lg:hidden"
          >
            Home
          </NavLink>
          <NavLink
            to="/services"
            onClick={handleNavLinkClick}
            style={({ isActive }) => ({
              color: isActive ? "#1A70B8" : "white",
              fontWeight: isActive ? "700" : "400",
            })}
          >
            Services
          </NavLink>
          <NavLink
            to="/about"
            onClick={handleNavLinkClick}
            style={({ isActive }) => ({
              color: isActive ? "#1A70B8" : "white",
              fontWeight: isActive ? "700" : "400",
            })}
          >
            About Us
          </NavLink>
          <NavLink
            to="/careers"
            onClick={handleNavLinkClick}
            style={({ isActive }) => ({
              color: isActive ? "#1A70B8" : "white",
              fontWeight: isActive ? "700" : "400",
            })}
          >
            Careers
          </NavLink>
          <NavLink
            to="https://medium.com/@10mgpharm"
            target="_blank"
            onClick={handleNavLinkClick}
            style={({ isActive }) => ({
              color: isActive ? "#1A70B8" : "white",
              fontWeight: isActive ? "700" : "400",
            })}
          >
            Blog
          </NavLink>
          <NavLink
            to="/faqs"
            onClick={handleNavLinkClick}
            style={({ isActive }) => ({
              color: isActive ? "#1A70B8" : "white",
              fontWeight: isActive ? "700" : "400",
            })}
          >
            FAQs
          </NavLink>
          <NavLink to="https://app.10mg.co.uk/signup">
            <button
              className="lg:hidden bg-transparent 
          border border-[#C3E3FD] text-[#C3E3FD] 
          px-4 py-2 rounded-md w-full mt-6"
            >
              Sign Up
            </button>
          </NavLink>
        </div>
        <div className="action-btns flex justify-center items-center gap-1 md:gap-6">
          <LanguageDropdown onLanguageChange={handleLanguageChange} />
          <NavLink to="https://app.10mg.co.uk/signup">
            <button className="hidden lg:block bg-transparent border border-[#C3E3FD] text-[#C3E3FD] px-4 py-2 rounded-md">
              Sign Up
            </button>
          </NavLink>
          {/* <button className="hidden lg:flex justify-center items-center bg-white rounded-full w-10 h-10">
            <img src={truck} alt="truck btn" />
          </button> */}
          <div className="toggle-nav-button" onClick={toggleMobileNav}>
            <Hamburger size={25} toggled={hamburgerOpen} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
