import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import Banner from "../banner";

import { Select } from "@chakra-ui/react";

const Opening = () => {
  const [selectedDepartment, setSelectedDepartment] = useState("All");

  const openingData = [
    {
      title: "Pharmaceutical Research Scientist",
      department: "Product",
      location: "Remote",
    },
    {
      title: "Quality Assurance Specialist",
      department: "Product",
      location: "Remote",
    },
    {
      title: "Logistics and Distribution Manager",
      department: "Logistics",
      location: "Remote",
    },
    {
      title: "Supply Chain Coordinator",
      department: "Logistics",
      location: "Remote",
    },
    {
      title: "Customer Support Representative",
      department: "Customer Support",
      location: "Remote",
    },
    {
      title: "Marketing and Communications Specialist",
      department: "Customer Support",
      location: "Remote",
    },
  ];

  const navigate = useNavigate();

  return (
    <div id="open-roles" className="p-5 py-20 lg:p-20">
      <div className="flex flex-col lg:flex-row justify-between items-start gap-3">
        <div>
          <Banner title="current openings" />
          <h1 className="text-berkeleyBlue text-3xl mt-5">
            We can’t wait to meet you
          </h1>
        </div>
        <div
          className="bg-[#F7F7F8] rounded-lg p-4 flex justify-between items-center 
            w-full lg:w-1/4"
        >
          <Select
            variant="unstyled"
            placeholder="Departments"
            icon=""
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Product">Product</option>
            <option value="Logistics">Logistics</option>
            <option value="Customer Support">Customer Support</option>
          </Select>
          <div className="bg-[#E6E6E6] text-berkeleyBlue rounded-full p-2">
            <BsChevronDown size={13} />
          </div>
        </div>
      </div>

      {/* All */}
      <div
        className="mt-10 grid grids-cols-1 md:grid-cols-2 
            lg:grid-cols-2 gap-5"
      >
        {openingData.length === 0 ? (
          <p>No openings</p>
        ) : (
          openingData
            .filter(
              (opening) =>
                selectedDepartment === "All" ||
                opening.department === selectedDepartment
            )
            .map((opening) => (
              <div
                key={opening.title}
                className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-10 bg-[#F9F8F5] p-5 
                rounded-lg  font-ibmPlexSans"
              >
                <div className="w-full">
                  <p className="text-[#B3001B] text-md lg:text-xl">
                    {opening.title}
                  </p>
                  <div
                    className="flex justify-start items-center mt-2 gap-10 text-sm 
                    text-[#011627]"
                  >
                    <p>{opening.department}</p>
                    <p>{opening.location}</p>
                  </div>
                </div>
                <button
                  className="flex justify-center items-center gap-3
                text-berkeleyBlue border border-berkeleyBlue p-1 px-4 rounded-full"
                  onClick={() =>
                    navigate(`/opening/${opening.title}`, { state: opening })
                  }
                >
                  View <BsChevronRight size={10} />
                </button>
              </div>
            ))
        )}
      </div>

      {/* //Product */}
      {/* <div
        className="mt-10 grid grids-cols-1 md:grid-cols-2 
            lg:grid-cols-2 gap-5"
      >
        <div className="flex justify-between items-center gap-10 bg-[#F9F8F5] p-5 rounded-lg ">
          <div className="w-full">
            <p className="text-[#B3001B] text-xl">
              Pharmaceutical Research Scientist
            </p>
            <div className="flex justify-start items-center mt-2 gap-10 text-sm text-[#011627]">
              <p>Product</p>
              <p>Remote</p>
            </div>
          </div>
          <button
            className="flex justify-center items-center gap-3
           text-berkeleyBlue border border-berkeleyBlue p-1 px-4 rounded-full"
          >
            View <BsChevronRight size={10} />
          </button>
        </div>
        <div className="flex justify-between items-center gap-10 bg-[#F9F8F5] p-5 rounded-lg ">
          <div className="w-full">
            <p className="text-[#B3001B] text-xl">
              Pharmaceutical Research Scientist
            </p>
            <div className="flex justify-start items-center mt-2 gap-10 text-sm text-[#011627]">
              <p>Product</p>
              <p>Remote</p>
            </div>
          </div>
          <button
            className="flex justify-center items-center gap-3
           text-berkeleyBlue border border-berkeleyBlue p-1 px-4 rounded-full"
          >
            View <BsChevronRight size={10} />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Opening;
