import Banner from "../banner";
import spotlight1 from "../../img/mission.png";
import spotlight2 from "../../img/vision-img.png";

const Spotlight = () => {
  return (
    <>
      <div className="p-5 py-20 lg:p-20 text-center">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-10">
          <div className="w-full lg:w-1/2">
            <Banner title="our mission" />
            <h1 className="text-left mt-5 text-2xl lg:text-3xl text-berkeleyBlue">
              10mg Pharma is dedicated to extending our influence and impact in
              the realm of African healthcare. Starting with Nigeria and
              Senegal.
            </h1>
            <p className="text-left mt-10 text-sm text-[#4D4D4D] font-ibmPlexSans">
              We will intensify our effort to have 10mg Pharma deeply rooted
              across Africa. Ghana and Kenya. Our focus will be to reach a
              minimum of 2 million patients per month in the next five years.
            </p>
          </div>
          <img
            src={spotlight1}
            alt="Spotlight"
            className="w-full lg:w-[400px] rounded-lg"
          />
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center gap-10 mt-20 lg:mt-[250px]">
          <img
            src={spotlight2}
            alt="Spotlight"
            className="w-[400px] hidden lg:block rounded-lg"
          />
          <div className="w-full lg:w-1/2">
            <Banner title="our vision" />
            <h1 className="text-left mt-5 text-2xl lg:text-3xl text-berkeleyBlue">
              Our dedication to building a sustainable future for healthcare in
              Africa is connected to advocating a cost-effective and consistent
              use of chronic pain medications.
            </h1>
            <p className="text-left mt-10 text-sm text-[#4D4D4D] font-ibmPlexSans">
              We ensure patients can access these medications through our
              innovative financial support to healthcare providers.
            </p>
          </div>
          <img
            src={spotlight2}
            alt="Spotlight"
            className="w-full lg:hidden rounded-lg"
          />
        </div>
      </div>
    </>
  );
};

export default Spotlight;
