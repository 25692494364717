import Banner from "../banner";
import VideoModal from "../video-modal";

const Why10mgFr = () => {
  return (
    <div className="h-auto lg:h-[700px] relative">
      <div
        className="p-5 py-20 lg:p-20 lg:py-[130px] flex flex-col lg:flex-row justify-center lg:justify-end items-center 
          gap-8 bg-[#EFF5FA]"
      >
        <div className="w-full lg:w-1/2 lg:absolute top-[7rem] left-[5rem]">
          <VideoModal />
          {/* <img src={imageVid} alt="Vids" className="w-full lg:w-[500px]" /> */}
        </div>
        <div className="flex flex-col justify-center items-start gap-5 w-full lg:w-1/2">
          <Banner title="POURQUOI 10MG PHARMA" />
          <h1 className="text-3xl text-berkeleyBlue">
            Nous comblons le fossé vers des médicaments abordables contre la
            douleur chronique en Afrique.
          </h1>
          <p className="text-[#4D4D4D] font-ibmPlexSans text-lg lg:text-sm">
            Notre mission est d'améliorer la qualité de vie des personnes
            souffrant des douleurs chroniques. Nous y parvenons grâce à l'IA
            financement, avec des solutions pharmaceutiques innovantes et
            efficaces. Notre dévouement et notre intégrité sont la force motrice
            de notre un effort continu pour mieux comprendre les douleurs
            chroniques, pour fournir options de gestion complètes, et de les
            fournir de manière qui soit accessible et abordable pour les
            prestataires de soins de santé et les patients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Why10mgFr;
