import whatsApp from "../images/WhatsApp.png";
import email_icon from "../images/Email.png";
import { faqCategoriesDataFr } from "../data/data-fr";
import { Link, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import QCard from "../components/sections/question_card";

const FaqCategoryFr = () => {
  const { id } = useParams();

  const faqCategory = faqCategoriesDataFr.find(
    (category) => category.id === parseInt(id)
  );

  return (
    <div>
      <div className="w-[100%]">
        <section className="w-full p-5 pt-[120px] sm:px-10">
          <Link to="/faqs">
            <div className="flex items-center gap-2 w-max bg-[#DCE9D8] text-sm rounded-full text-center py-2 px-5 text-darkGreen">
              <FaArrowLeft />
              <span className="">FAQ</span>
            </div>
          </Link>

          <div className="grid sm:grid-cols-3 gap-8">
            <div className="hidden col-span-1 sm:block mt-10">
              <p className="text-sm text-berkeleyBlue font-ibmPlexSans mb-4 font-medium">
                CATÉGORIES
              </p>
              <ul className="text-berkeleyBlue grid gap-2 text-[14px] font-ibmPlexSans">
                <Link to="/faqCategory/1">
                  <li
                    className={`px-5 cursor-pointer ${
                      id === "1" ? "py-1 w-max bg-[#F5F5F5] rounded-full" : ""
                    }`}
                  >
                    Commandes et livraison
                  </li>
                </Link>
                <Link to="/faqCategory/2">
                  <li
                    className={`px-5 cursor-pointer ${
                      id === "2" ? "py-1 w-max bg-[#F5F5F5] rounded-full" : ""
                    }`}
                  >
                    Information produit
                  </li>
                </Link>
                <Link to="/faqCategory/3">
                  <li
                    className={`px-5 cursor-pointer ${
                      id === "3" ? "py-1 w-max bg-[#F5F5F5] rounded-full" : ""
                    }`}
                  >
                    Compte et sécurité
                  </li>
                </Link>
                <Link to="/faqCategory/4">
                  <li
                    className={`px-5 cursor-pointer ${
                      id === "4" ? "py-1 w-max bg-[#F5F5F5] rounded-full" : ""
                    }`}
                  >
                    Paiement et facturation
                  </li>
                </Link>
                <Link to="/faqCategory/5">
                  <li
                    className={`px-5 cursor-pointer ${
                      id === "5" ? "py-1 w-max bg-[#F5F5F5] rounded-full" : ""
                    }`}
                  >
                    Retours et remboursements
                  </li>
                </Link>
                <Link to="/faqCategory/6">
                  <li
                    className={`px-5 cursor-pointer ${
                      id === "6" ? "py-1 w-max bg-[#F5F5F5] rounded-full" : ""
                    }`}
                  >
                    Service client
                  </li>
                </Link>
              </ul>
            </div>
            <div className="mt-10 sm:col-span-2 sm:mt-0">
              <div className="mb-6">
                <h2 className="text-[28px] text-berkeleyBlue font-semibold leading-40 tracking-wider mb-1">
                  {faqCategory.title}
                </h2>
                <p className="font-ibmPlexSans text-base font-normal leading-26">
                  {faqCategory.desc}
                </p>
              </div>
              <div>
                {faqCategory.questions.map((question, index) => {
                  return (
                    <QCard question={question} index={index} key={index} />
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {/* <section className="w-full p-5 sm:p-10">
          <h2 className="text-[28px] sm:text-[32px] text-berkeleyBlue font-medium tracking-wide">
            common questions
          </h2>
          <p className="font-ibmPlexSans text-base font-normal leading-8 tracking-normal text-[#011627] mb-3">
            What we’ve been hearing the most
          </p>
          <div className="mb-4 grid grid-cols-1 gap-8 sm:grid sm:grid-cols-3">
            <article className="bg-[#F7F7F8] p-3 rounded-md">
              <div className="mb-2 flex flex-row-reverse justify-between">
                <div className="w-[32px] h-[32px] rounded-full bg-[#E6E6E6] flex justify-center items-center" onClick={toggleCardOneExpand} >
                  <img src={upArrow} alt="up arrow" className={`h-1 w-3 text-berkeleyBlue ${isCardOneExpand ? "transform rotate-180" : ""}`} />
                </div>
                <p className="font-ibmPlexSans text-lg text-berkeleyBlue font-normal leading-26 tracking-normal">
                  Why do we have expedited delivery?
                </p>
              </div>
              {isCardOneExpand && 
              (<p className="font-ibmPlexSans text-sm font-normal leading-[1.75]">
                We understand that certain health conditions may require a more
                urgent delivery, and our expedited service is designed to meet
                those specific needs. Whether it's a critical prescription or a
                time-sensitive healthcare requirement, expedited delivery
                provides a faster shipping option, ensuring that our customers
                receive the necessary medications promptly, promoting both
                convenience and peace of mind.
              </p>)}
            </article>
            <article className="bg-[#F7F7F8] p-3 rounded-md">
              <div className="mb-2 flex flex-row-reverse justify-between">
                <div className="w-[32px] h-[32px] rounded-full bg-[#E6E6E6] flex justify-center items-center" onClick={toggleCardTwoExpand}>
                  <img src={upArrow} alt="up arrow" className={`h-1 w-3 text-berkeleyBlue ${isCardTwoExpand ? "transform rotate-180" : ""}`} />
                </div>
                <p className="font-ibmPlexSans text-lg text-berkeleyBlue font-normal leading-26 tracking-normal">
                  How do I reset my password??
                </p>
              </div>
              {isCardTwoExpand &&
              (<p className="font-ibmPlexSans text-sm font-normal leading-[1.75]">
                We understand that certain health conditions may require a more
                urgent delivery, and our expedited service is designed to meet
                those specific needs. Whether it's a critical prescription or a
                time-sensitive healthcare requirement, expedited delivery
                provides a faster shipping option, ensuring that our customers
                receive the necessary medications promptly, promoting both
                convenience and peace of mind.
              </p>)}
            </article>
            <article className="bg-[#F7F7F8] p-3 rounded-md">
              <div className="mb-2 flex flex-row-reverse justify-between">
                <div className="w-[32px] h-[32px] rounded-full bg-[#E6E6E6] flex justify-center items-center"  onClick={toggleCardThreeExpand}>
                  <img src={upArrow} alt="up arrow" className={`h-1 w-3 text-berkeleyBlue ${isCardThreeExpand ? "transform rotate-180" : ""}`} />
                </div>
                <p className="font-ibmPlexSans text-lg text-berkeleyBlue font-normal leading-26 tracking-normal">
                  What types of medication do you provide?
                </p>
              </div>
              {
                isCardThreeExpand &&
              
              (<p className="font-ibmPlexSans text-sm font-normal leading-[1.75]">
                We understand that certain health conditions may require a more
                urgent delivery, and our expedited service is designed to meet
                those specific needs. Whether it's a critical prescription or a
                time-sensitive healthcare requirement, expedited delivery
                provides a faster shipping option, ensuring that our customers
                receive the necessary medications promptly, promoting both
                convenience and peace of mind.
              </p>)}
            </article>
          </div>
              </section>*/}

        <section className="w-full sm:p-5 sm:mt-20">
          <div className="p-5 sm:w-[60%]">
            <h2 className="text-3xl text-[#02325A] font-medium leading-10">
              Vous ne trouvez pas ce que vous cherchez ?
            </h2>
            <p className="font-ibmPlexSans font-normal text-base mt-4 leading-8">
              Si vous avez d'autres questions ou avez besoin d'aide
              supplémentaire, contactez-nous appelez, envoyez un e-mail ou
              remplissez simplement le formulaire ci-dessous, et notre une
              équipe dédiée vous répondra dans les plus brefs délais. Vos
              demandes important, et nous sommes impatients de vous fournir les
              informations dont vous avez besoin.
            </p>
          </div>
          <div className=" sm:flex mb-20">
            <div className="flex gap-4 p-5 overflow-x-auto w-full sm:gap-8">
              <article className="w-[100%] bg-[#FBFBF8] py-2 px-[50px] flex flex-col justify-center items-center rounded-lg">
                <div className="w-10 h-10 rounded-full mb-8 bg-[#F2F5F8] flex justify-center items-center sm:w-[112px] sm:h-[112px]">
                  <img
                    src={email_icon}
                    className="w-4 h-4 sm:h-12 sm:w-12 "
                    alt="email"
                  />
                </div>
                <p className="text-lg font-ibmPlexSans font-normal mb-2">
                  Envoyer un e-mail
                </p>
                <p className="text-base font-ibmPlexSans text-[#02325A]">
                  info@10mg.co.uk
                </p>
              </article>
              <article className="w-[100%] bg-[#FBFBF8] py-5 px-[50px] flex flex-col justify-center items-center rounded-lg">
                <div className="w-10 h-10 rounded-full mb-8 bg-[#F5F8F2] flex justify-center items-center sm:h-[112px] sm:w-[112px]">
                  <img
                    src={whatsApp}
                    className="w-4 h-4 sm:h-10 sm:w-10"
                    alt=""
                  />
                </div>
                <p className="text-lg font-ibmPlexSans font-normal mb-2 text-[#B3001B]">
                  WhatsApp
                </p>
                <p className="text-base font-ibmPlexSans text-[#02325A]">
                  wa.me/10mg
                </p>
              </article>
            </div>
            <div className="p-5 sm:pl-2">
              <form className=" p-5 bg-[#EEF5FC] rounded-2xl border-b-4 border-[#E6E6E6] space-y-5 sm:space-y-2">
                <div className="flex flex-col">
                  <label className="mb-2 font-ibmPlexSans text-berkeleyBlue text-base">
                    Nom et prénom
                  </label>
                  <input
                    type="text"
                    placeholder="Votre nom"
                    className="p-4 rounded font-ibmPlexSans border-1 border-[#E6E6E6] drop-shadow sm:p-2"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-ibmPlexSans text-berkeleyBlue text-base">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="You@company.com"
                    className="p-4 rounded font-ibmPlexSans border-1 border-[#E6E6E6] drop-shadow sm:p-2"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="mb-2 font-ibmPlexSans text-berkeleyBlue text-base">
                    Comment pouvons nous aider?
                  </label>
                  <textarea
                    placeholder="Votre message"
                    rows={3}
                    cols={40}
                    className="p-4 rounded font-ibmPlexSans border-1 border-[#E6E6E6] drop-shadow"
                  />
                </div>
                <div className="pt-4 sm:pt-2">
                  <button className="border border-berkeleyBlue w-full py-2 rounded-full drop-shadow text-base font-ibmPlexSans">
                    Envoyer le message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FaqCategoryFr;
