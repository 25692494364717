import Banner from "../banner";
import SuppliersFr from "./serv_suppliers-fr";

const ServiceSuppliersFr = () => {
  return (
    <section className="p-5 mt-20 sm:px-10 sm:flex flex-col sm:py-20">
      <div className="sm:text-center sm:self-center">
        <div className="flex justify-center">
          <Banner title="POUR LES FOURNISSEURS" />
        </div>
        <div className="sm:w-[800px] self-center">
          <h3 className="text-[20px] text-berkeleyBlue leading-10 font-medium mt-10 tracking-wide sm:text-[32px]">
            Augmentez votre clientèle et offrez plus de valeur
          </h3>
          <p className="text-[#4D4D4D] font-ibmPlexSans leading-6 tracking-wide my-4 sm:leading-8 sm:text-[18px]  sm:text-center sm:px-3">
            Augmentez la portée et l'impact de votre marque en vous associant à
            10mg Pharma. Notre collaboration offre aux fournisseurs la
            possibilité d'étendre leurs réseaux, de se connecter avec divers
            prestataires de soins de santé et de contribuer à des solutions
            innovantes.
          </p>
        </div>
      </div>
      <SuppliersFr />
    </section>
  );
};

export default ServiceSuppliersFr;
