/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";

import toast from "react-hot-toast";

const SupporterForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.target);

    try {
      const response = await fetch("https://api.10mg.co.uk/10mg/send-enquiry", {
        method: "POST",
        body: formData,
      });

      console.log("RESPONSE", response.json());

      if (response.ok) {
        formRef.current.reset();
        toast.success("Form submitted successfully!");
        closeModal();
      } else {
        toast.error("An error occurred while submitting the form.");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        ref={formRef}
        className="flex flex-col gap-4 mt-4 font-ibmPlexSans"
      >
        <div className="flex flex-col gap-4">
          <label htmlFor="name" className="text-sm  text-berkeleyBlue">
            Full Name
          </label>
          <input
            type="text"
            name="name"
            id="fullName"
            placeholder="Your name"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="email"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="you@company.com"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="message"
            className="text-sm font-ibmPlexSans text-berkeleyBlue"
          >
            How would you like to support?
          </label>
          <textarea
            name="message"
            id="message"
            placeholder="Your message"
            className="bg-[#FAFAFC] p-3 rounded-lg border border-[#EEEEF0]"
          ></textarea>
        </div>
        <button
          type="submit"
          className="inline-flex justify-center rounded-full border border-berkeleyBlue 
                        bg-transparent px-4 py-2 text-sm font-medium text-berkeleyBlue  hover:bg-blue-200 
                        focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 
                        focus-visible:ring-offset-2"
        >
          {isLoading ? "Submitting..." : "Send message"}
        </button>
      </form>
    </>
  );
};

export default SupporterForm;
