import Achievements from "../components/sections/achievements";
import Contact from "../components/sections/contact";
import HomeHero from "../components/sections/home_hero";
import Impact from "../components/sections/impact";
import Partners from "../components/sections/partners";
import Problem from "../components/sections/problems";
import Process from "../components/sections/process";
import Quote from "../components/sections/quote";
import Solution from "../components/sections/solution";
import Supporters from "../components/sections/supporters";
import Testimonials from "../components/sections/testimonials";

const Home = () => {
  return (
    <>
      <HomeHero />
      <Partners />
      <Problem />
      <Solution />
      <Quote />
      <Process />
      <Impact />
      <Achievements />
      <Testimonials />
      <Supporters />
      <Contact />
    </>
  );
};

export default Home;
