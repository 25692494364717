import AboutHeroFr from "../components/sections/ab-hero-fr";
import AboutImpactFr from "../components/sections/ab-impact-fr";
import AboutIntroFr from "../components/sections/ab-intro-fr";
import ContactFr from "../components/sections/contact-fr";
import PainProblemFr from "../components/sections/pain-problem-fr";
import PartnersFr from "../components/sections/partners-fr";
import SpotlightFr from "../components/sections/spotlight-fr";
// import Team from "../components/sections/team";
// import Values from '../components/sections/values'
import Why10mgFr from "../components/sections/why10mgFr";

const AboutFr = () => {
  return (
    <>
      <AboutHeroFr />
      <PartnersFr />
      <AboutIntroFr />
      <Why10mgFr />
      <PainProblemFr />
      <AboutImpactFr />
      <SpotlightFr />
      <ContactFr />
      {/* <Values /> */}
      {/* <Team /> */}
      {/* <HealthRefined /> */}
    </>
  );
};

export default AboutFr;
