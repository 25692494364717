import Banner from "../banner";

const CValuesFr = () => {
  const values = [
    {
      position: "01",
      title: "Innovation",
      description:
        "Favoriser une culture d’innovation continue pour des solutions de santé révolutionnaires.",
    },
    {
      position: "02",
      title: "Intégrité",
      description:
        "Les gens comptent sur nos outils pour faire exactement ce que nous disons qu'ils font",
    },
    {
      position: "03",
      title: "Empathie et compassion",
      description:
        "Donner la priorité à l’empathie dans toutes les interactions, en reconnaissant l’impact humain de notre travail.",
    },
    {
      position: "04",
      title: "Collaboration",
      description:
        "Insistez sur l’importance du travail d’équipe et de la collaboration diversifiée.",
    },
    {
      position: "05",
      title: "Impact sur la communauté",
      description:
        "Soulignez l’engagement à avoir un impact positif sur les communautés mal desservies.",
    },
    {
      position: "06",
      title: "Bien-être des employés",
      description:
        "Donnez la priorité au bien-être des employés avec des avantages sociaux et un équilibre sain entre travail et vie privée.",
    },
  ];

  return (
    <div id="our-culture" className="p-5 py-20 lg:p-20">
      <Banner title="nos valeurs" />
      <h1 className="text-3xl mt-5 text-berkeleyBlue w-full lg:w-1/2">
        Nous sommes déterminés à donner aux gens les moyens de créer le meilleur
        travail de leur vies.
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mt-10">
        {values.map((value) => (
          <div>
            <Banner
              title={value.position}
              className="bg-[#C3E3FD] text-berkeleyBlue w-5 h-7 px-0"
            />
            <h1 className="text-2xl mt-2 text-berkeleyBlue">{value.title}</h1>
            <p className="text-sm font-ibmPlexSans mt-3">{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CValuesFr;
