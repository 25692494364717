import s1 from "../../img/im-1.png";
import s2 from "../../img/im-2.png";
import s3 from "../../img/im-3.png";

import Banner from "../banner";
import { FaChevronRight } from "react-icons/fa6";

const HealthcareProvidersFr = () => {
  return (
    <div className="p-5 py-20 lg:p-20">
      <div className="flex justify-center items-center">
        <Banner title="POUR LES PRESTATAIRES DE SOINS DE SANTÉ" />
      </div>

      <div className="flex flex-col md:flex-row justify-center items-start gap-10 mt-28 w-full">
        <article className="mt-8 text-left w-full lg:w-1/2">
          <h3 className="text-2xl text-berkeleyBlue">
            Gestion des stocks améliorée par l'IA
          </h3>
          <div className="flex items-center gap-2 text-md text-[#3E5B34] mt-1">
            <FaChevronRight className="h-3 w-2" />
            <p>Automatisez votre processus d’approvisionnement pour l’année</p>
          </div>
          <p className="text-[#4D4D4D] font-ibmPlexSans text-sm leading-[30px] mt-3">
            Simplifiez votre achat mensuel de médicaments en adoptant un système
            automatisé qui élimine le stress du processus. Grâce à notre achat
            automatisé de médicaments, vous pouvez mettre en place une routine
            transparente et sans stress pour obtenir les médicaments dont vous
            avez besoin chaque année. Cela implique un processus de commande et
            de livraison sans tracas, garantissant que vous disposez toujours
            des médicaments nécessaires sans avoir besoin d’une intervention
            manuelle constante.
          </p>
        </article>

        <div className="flex justify-center items-center gap-3 w-full lg:w-1/2 h-[300px]">
          <div className="flex">
            <img className="w-[327px] rounded-lg" src={s1} alt="s1" />
          </div>
          <div className="flex flex-col gap-3">
            <img className="w-[150px] rounded-lg" src={s2} alt="s2" />
            <img className="w-[150px] rounded-lg" src={s3} alt="s3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthcareProvidersFr;
