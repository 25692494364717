import Banner from "../banner";

import img1 from "../../images/ab-hero1.png";
import img2 from "../../images/ab-hero2.png";

const AboutHero = () => {
  return (
    <div className="h-screen flex flex-col p-2 md:p-5 lg:p-10 py-10 text-center relative bg-[#F7F7F9]">
      <img
        src={img1}
        alt="About Hero"
        className="w-[200px] absolute top-0 left-0 hidden lg:block"
      />
      <img
        src={img2}
        alt="About Hero"
        className="w-[300px] absolute top-[200px] right-0 hidden lg:block"
      />
      <img
        src={img1}
        alt="About Hero"
        className="w-[80px] md:w-[150px] lg:hidden"
      />
      <img
        src={img2}
        alt="About Hero"
        className="w-[80px] md:w-[150px] lg:hidden mt-10 absolute top-[5rem] md:top-[10rem] right-0"
      />
      <div
        className="h-screen flex flex-col justify-center items-start lg:items-center gap-6 
        w-full lg:w-1/2 mx-auto"
      >
        <Banner title="Introduction" />
        <h1 className="text-2xl lg:text-4xl font-medium text-berkeleyBlue text-left lg:text-center">
          We Bridge the Gap to Affordable Chronic Pain Medication in Africa.
        </h1>
        <p className="text-[#4D4D4D] font-ibmPlexSans text-sm text-left lg:text-center">
          Our mission is to improve the quality of life for people suffering
          from chronic pains. We are achieving this through AI-driven financing,
          with innovative and effective pharmaceutical solutions. Our dedication
          and integrity are the driving force behind our continuous effort to
          better understand chronic pains, to provide comprehensive management
          options, and to deliver them in a manner that is accessible and
          affordable for healthcare care providers and patients.
        </p>
      </div>
    </div>
  );
};

export default AboutHero;
