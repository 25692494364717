import React, { useState } from "react";
import pharm from "../../images/afsp4.png";
import pharm1 from "../../images/afsp17.png"; // Import your image
import pharm2 from "../../images/afsp22.png";
import pharm3 from "../../images/afsp23.png";
import icon from "../../images/icon.png"; // Import your icon
import icon2 from "../../images/icon2.png";
import { Link } from "react-router-dom";

const HoverExpandComponentFr = () => {
  const [expand, setExpand] = useState(false);
  const [cardTwoExpand, setCardTwoExpand] = useState(false);

  const toggleCardOne = () => {
    setExpand(!expand);
  };
  const toggleCardTwo = () => {
    setCardTwoExpand(!cardTwoExpand);
  };
  return (
    <>
      <section className="p-5 sm:hidden">
        <div className="flex justify-center">
          <div className="my-10 text-center">
            <span className="bg-[#DCE9D8] py-1 px-4 rounded-full font-ibmPlexSans text-[12px] text-[#1F1F1F] leading-4 font-medium">
              Dynamisez votre entreprise
            </span>
            <p className="my-10 font-medium text-[20px] leading-8 tracking-wide text-berkeleyBlue mx-auto">
              Que vous soyez une pharmacie ou un fournisseur, nous vous
              proposons des solutions sur mesure et un accompagnement fiable
              pour votre réussite."
            </p>
          </div>
        </div>
        <div>
          <div className="bg-[#E9F3FC] p-4 rounded-lg">
            <h3 className="text-[24px] text-berkeleyBlue font-medium leading-6 tracking-wide mb-4">
              Pour les fournisseurs
            </h3>
            <p className="text-[#1F1F1F] font-ibmPlexSans leading-6 tracking-wide my-4">
              Nous vous aidons à améliorer la qualité du service que vous rendez
              à vos clients.
            </p>
            <img src={pharm2} alt="pharm" className="w-full mb-4" />
            <article>
              <div className="flex items-center gap-2 mb-2">
                <img src={icon} alt="icon" className="w-5 h-5" />
                <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                  Intégration rationalisée de la chaîne d'approvisionnement
                </p>
              </div>
              <div className="flex items-center gap-2 mb-2">
                <img src={icon} alt="icon" className="w-5 h-5" />
                <p className="text-[16px] font-ibmPlexSans text-[#4D4D4D] leading-6">
                  Stabilité financière avec des paiements rapides
                </p>
              </div>
              <div className="flex items-center gap-2 mb-2">
                <img src={icon} alt="icon" className="w-5 h-5" />
                <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                  Opportunités de recherche et de développement collaboratifs{" "}
                </p>
              </div>
              <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6 mb-8">
                Augmentez votre clientèle en comblant le fossé entre la
                fabrication et les utilisateurs finaux
              </p>
            </article>
            <Link to="/signup-pharmacy">
              <button className="bg-[#1A70B8] w-full py-3 px-4 text-white font-ibmPlexSans leading-6 rounded-md">
                Devenez fournisseur
              </button>
            </Link>
          </div>
          <div className="bg-[#FDF0E7] p-4 my-4 rounded-lg">
            <h3 className="text-[24px] text-berkeleyBlue font-medium leading-6 tracking-wide mb-4">
              Pour les prestataires de soins de santé
            </h3>
            <p className="text-[#1F1F1F] font-ibmPlexSans leading-6 tracking-wide my-4">
              Réorganisez la qualité du service que vous rendez à vos clients.
            </p>
            <img src={pharm3} alt="pharm" className="w-full mb-4" />
            <article>
              <div className="flex items-center gap-2 mb-2">
                <img src={icon} alt="icon" className="w-5 h-5" />
                <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                  Gestion des stocks améliorée par l'IA
                </p>
              </div>
              <div className="flex items-center gap-2 mb-2">
                <img src={icon} alt="icon" className="w-5 h-5" />
                <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                  Accès aux prêts transfrontaliers à un chiffre
                </p>
              </div>
              <div className="flex items-center gap-2 mb-2">
                <img src={icon} alt="icon" className="w-5 h-5" />
                <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                  Initiatives marketing de renforcement de la confiance
                </p>
              </div>
              <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6 mb-8">
                Increase your customer base by bridging the gap between
                manufacturing and end users
              </p>
            </article>
            <Link to="/signup-pharmacy">
              <button className="bg-[#85390A] w-full py-3 px-4 text-white font-ibmPlexSans leading-6 rounded-md">
                Enregistrez votre pharmacie
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* bigger screen */}
      <section className="p-5 hidden sm:block">
        <div className="flex justify-center">
          <div className="my-10 text-center">
            <span className="bg-[#DCE9D8] py-1 px-4 rounded-full font-ibmPlexSans text-[12px] text-[#1F1F1F] leading-4 font-medium">
              Dynamisez votre entreprise
            </span>
            <p className="my-10 font-medium text-[20px] leading-8 tracking-wide text-berkeleyBlue w-[450px]">
              Que vous soyez une pharmacie ou un fournisseur, nous vous
              proposons des solutions sur mesure et un accompagnement fiable
              pour votre réussite."
            </p>
          </div>
        </div>
        <div className={` flex gap-4`}>
          <div
            className={`flex ${
              cardTwoExpand ? "justify-start" : "justify-end"
            } ${expand ? "w-[100%]" : "w-[50%]"} ease-in-out duration-500`}
          >
            <div
              className={`bg-[#FDF0E7] p-4 rounded-lg flex justify-between gap-4 h-[450px] ${
                expand ? "w-full" : `${cardTwoExpand ? "w-full" : "w-[60%] "}`
              }`}
              onMouseEnter={toggleCardOne}
              onMouseLeave={toggleCardOne}
            >
              <div className={`flex flex-col`}>
                <h2 className="text-3xl text-[#ED6512] tracking-wide font-medium mb-4">
                  Pour les fournisseurs
                </h2>
                <article className="mt-auto">
                  <p className={`font-ibmPlexSans text-base `}>
                    Nous vous aidons à améliorer la qualité du service que vous
                    rendez à vos clients.
                  </p>
                  <div
                    className={`${
                      expand ? "transform translate-y-0 opacity-100" : "hidden"
                    } ease-in-out duration-500`}
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <img src={icon2} alt="icon" className="w-5 h-5 " />
                      <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                        Intégration rationalisée de la chaîne
                        d'approvisionnement
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mb-2">
                      <img src={icon2} alt="icon" className="w-5 h-5" />
                      <p className="text-[16px] font-ibmPlexSans text-[#4D4D4D] leading-6">
                        Stabilité financière avec des paiements rapides
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mb-2">
                      <img src={icon2} alt="icon" className="w-5 h-5" />
                      <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                        Opportunités de recherche et de développement
                        collaboratifs
                      </p>
                    </div>
                  </div>
                </article>
                <Link to="/signup-pharmacy">
                  <button
                    className={`w-max py-3 px-5 bg-[#85390A] mt-4 rounded-sm text-white font-ibmPlexSans`}
                  >
                    Devenez fournisseur
                  </button>
                </Link>
              </div>
              <div className={`${expand ? "block" : "hidden"} `}>
                <img
                  src={pharm}
                  alt="pharmacist"
                  className="h-full object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
          <div
            className={`flex ${expand ? "justify-end" : "justify-start"} ${
              cardTwoExpand ? "w-[100%]" : "w-[50%]"
            } ease-in-out duration-500`}
          >
            <div
              className={`bg-[#E9F3FC] p-4 rounded-lg flex justify-between gap-4 h-[450px] ${
                cardTwoExpand ? "w-full" : `${expand ? "w-full" : "w-[60%]"}`
              }`}
              onMouseEnter={toggleCardTwo}
              onMouseLeave={toggleCardTwo}
            >
              <div className="flex flex-col justify-between">
                <h2 className="text-3xl text-[#02325A] tracking-wide font-medium mb-4">
                  Pour les prestataires de soins de santé
                </h2>
                <article className="mt-auto">
                  <p className="font-ibmPlexSans text-base">
                    Réorganisez la qualité du service que vous rendez à vos
                    clients.
                  </p>
                  <div
                    className={`${
                      cardTwoExpand ? "" : "hidden"
                    } transition-opacity`}
                  >
                    <div className="flex items-center gap-2 mb-2">
                      <img src={icon} alt="icon" className="w-5 h-5" />
                      <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                        Gestion des stocks améliorée par l'IA
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mb-2">
                      <img src={icon} alt="icon" className="w-5 h-5" />
                      <p className="text-[16px] font-ibmPlexSans text-[#4D4D4D] leading-6">
                        Accès aux prêts transfrontaliers à un chiffre
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mb-2">
                      <img src={icon} alt="icon" className="w-5 h-5" />
                      <p className="text-base font-ibmPlexSans text-[#4D4D4D] leading-6">
                        Initiatives marketing de renforcement de la confiance
                      </p>
                    </div>
                  </div>
                </article>
                <Link to="/signup-pharmacy">
                  <button className="w-max py-3 px-5 bg-[#1A70B8] mt-4 text-white font-ibmPlexSans rounded-sm">
                    Enregistrez votre pharmacie
                  </button>
                </Link>
              </div>
              <div className={`${cardTwoExpand ? "block" : "hidden"}`}>
                <img
                  src={pharm1}
                  alt="pharmacist"
                  className="h-full rounded-lg object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HoverExpandComponentFr;
