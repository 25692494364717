import { useRef, useState } from "react";
import "../App.css";

const TermsFr = () => {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const [selectedItem, setSelectedItem] = useState("section1");

  const scrollToSection = (elementRef, data) => {
    console.log(data);
    setSelectedItem(data);
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="w-[100%] p-3 mx-auto  sm:p-2">
      <div className="my-20 sm:flex w-[95%] m-auto">
        <div className="hidden sm:block w-[560px] mt-[132px]">
          <p className="text-sm text-berkeleyBlue font-ibmPlexSans mb-8 font-medium">
            SUR CETTE PAGE
          </p>
          <ul className="text-berkeleyBlue grid gap-2 text-[14px] font-ibmPlexSans ">
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section1"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section1, "section1")}
            >
              Aperçu
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section2"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section2, "section2")}
            >
              Accéder à nos services
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section3"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section3, "section3")}
            >
              Nature des contenus apparaissant sur nos services
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section4"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section4, "section4")}
            >
              Vos interactions et communications avec nous et nos services
            </li>
            <li
              className={`px-5 cursor-pointer ${
                selectedItem === "section5"
                  ? "py-1 w-max bg-[#F5F5F5] rounded-full"
                  : ""
              }`}
              onClick={() => scrollToSection(section5, "section5")}
            >
              Divers
            </li>
          </ul>
        </div>
        <div className="sm:w-[65%]">
          <div className=" flex flex-col gap-8">
            <div className="w-[342px] flex flex-col gap-4 sm:flex-row justify-between">
              <span className="w-max bg-[#DCE9D8] text-xs rounded-full text-center py-1 px-4 text-darkGreen">
                TERMES
              </span>
              <p class="text-sm font-medium text-darkGreen">
                DERNIÈRE MISE À JOUR - 16 et 22 octobre
              </p>
            </div>
            <h1 className="text-xl font-medium font-pp-pangaia text-berkeleyBlue mb-10">
              Conditions d'utilisation
            </h1>
          </div>
          <section ref={section1}>
            <h2 className="text-lg mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              Aperçu
            </h2>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Ces conditions d'utilisation (ces « Conditions ») s'appliquent aux
              visiteurs de 10mg Le site Web de Pharma (« nous » ou « notre »)
              10mg.co.uk, ainsi que utilisateurs enregistrés de nos informations
              de marque 10mg Pharma® et services technologiques et applications
              mobiles (nos « Services »). Veuillez lire attentivement ces
              conditions. Veuillez également consulter notre Politique de
              confidentialité, qui explique comment nos services traitent votre
              données personnelles et protégez votre vie privée lorsque vous
              utilisez nos Services. En accédant à nos services, vous acceptez
              ces conditions et nos Politique de confidentialité.
            </p>
          </section>
          <section ref={section2}>
            <h2 className="text-xl my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1. Accéder à nos services
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.1 SERVICES PUBLICS
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Si vous exploitez un moteur de recherche public et gratuit qui a
              rendu public engagement à adhérer au protocole robots.txt, ainsi
              qu'à nos directives Sitemaps et Crawl-delay (collectivement, les
              "Protocole"), vous pouvez explorer, indexer et publier des
              hyperliens (y compris les soi-disant « liens profonds ») vers les
              services publics, à condition car (i) vous le faites conformément
              aux instructions du Protocole publiés aux endroits applicables sur
              nos Services, (ii) vous ne faites pas percevoir directement ou
              indirectement une rémunération en relation avec fourniture ou
              affichage desdits hyperliens et pages mises en cache, et (iii) si
              votre agent utilisateur est refusé par nous dans notre protocole
              instructions ou si nous vous en informons par écrit, vous devez
              cesser toute exploration de nos services. Nous appelons un tel
              service public et gratuit moteur de recherche satisfaisant aux
              exigences des clauses (i) à (iii), un « moteur de recherche public
              » et le moteur de recherche public exécution combinée des clauses
              (i) à (iii), collectivement, «Services publics de recherche». Nous
              pouvons révoquer ce qui précède autorisations à tout moment comme
              indiqué dans notre Protocole instructions. Nous nous réservons
              tous les droits qui ne vous sont pas expressément accordés. Cela
              signifie que si vous souhaitez utiliser les Services Publics d'une
              manière qui n'est pas autorisé ci-dessus, vous devez recevoir
              notre autorisation avant une telle utilisation.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.2 SERVICES PROTÉGÉS
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Certains de nos Services sont protégés par des mesures techniques
              destinées garantir la confidentialité, l’intégrité et
              l’accessibilité des informations sensibles que nos utilisateurs
              stockent et partagent en utilisant notre Prestations de service.
              Nous appelons cela des « services protégés ». Une telle garantie
              exige que chaque utilisateur soit correctement authentifié par
              divers des moyens (que nous appelons « Credentials »), tels que
              des identifiants uniques, Clés API, mots de passe, etc. Afin
              d'obtenir les informations d'identification, vous devez fournir
              certaines informations vous concernant. Si vous êtes s'inscrire à
              un service protégé, ou accéder ou utiliser, ou tenter d'accéder ou
              d'utiliser un service protégé au nom de, ou pour le bénéfice de
              quelqu'un d'autre que vous-même - comme votre employeur, client ou
              client (chacun, un « mandant ») - vous devez également identifier
              et fournir des informations sur chaque mandant. Si vous signez
              pour bénéficier de l'un de nos services protégés, il vous sera
              peut-être demandé d'accepter un contrat d'utilisation (votre
              « Contrat d'utilisation »). Dans de tels cas, vous être invité à
              consentir expressément à votre contrat d'utilisation, par exemple,
              en cochant une case ou en cliquant sur un bouton comportant des
              termes tels que "Je d'accord" ou "Créer mon DSE" ou autre. En cas
              de conflit entre les termes de votre contrat d'utilisation et de
              ces conditions, le les termes de votre contrat d’utilisation
              prévaudront. Vos informations d'identification sont unique à vous.
              Vous devez nous informer immédiatement ici si votre Les
              informations d'identification ont été volées ou compromises. Tu es
              responsable pour toutes les activités qui se produisent sous vos
              informations d'identification jusqu'à ce que vous nous avoir
              correctement informés que vos identifiants ont été volés ou
              compromis. De plus, vous représentez, garantissez et engagez que:
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  les informations que vous soumettez pour obtenir vos
                  informations d'identification sont complet et précis et vous
                  identifie ainsi que le nom de chacun de vos directeurs ;
                </li>
                <li>
                  vous ne partagerez vos informations d'identification avec
                  personne d'autre ;
                </li>
                <li>
                  vous ne contournerez pas, ni ne tenterez de contourner, aucun
                  mesures techniques que nous avons mises en place pour
                  sauvegarder Services protégés ;
                </li>
                <li>
                  vous n'aurez pas, sans notre accord écrit préalable, accès ou
                  utiliser, ou tenter d'accéder ou d'utiliser, toute partie du
                  contenu protégé Services autres qu'avec (i) un navigateur
                  commercial (tel que Chrome, Internet Explorer ou Mozilla
                  Firefox), (ii) un application conçue pour les appareils
                  mobiles ou portables qui sont développé et distribué par nous,
                  ou (iii) notre application interface de programmation (« API
                  ») utilisant les informations d'identification qui vous ont
                  été délivrées directement par nous, et seulement nous ; et
                </li>
                <li>
                  Vous n’accéderez pas, n’utiliserez pas, ou ne tenterez pas
                  d’accéder ou d’utiliser, un Service protégé sans informations
                  d'identification actives valablement émises.
                </li>
              </ul>
              Nous nous réservons le droit de suspendre ou de résilier votre
              accès à tout Service protégé à tout moment, avec ou sans motif ou
              préavis. Nous ne sera en aucun cas responsable envers vous si nous
              suspendons ou résilions votre accès à un Service Protégé ou à nos
              Services.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.3 Garanties supplémentaires
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Pour protéger davantage la confidentialité, l’intégrité et la
              disponibilité des informations hébergées et partagées sur nos
              Services, ainsi que la stabilité de nos Services, vous acceptez ce
              qui suit des garanties supplémentaires. En conséquence, vous
              acceptez de ne pas le faire, vous n’essaierez pas non plus de :
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  accéder, utiliser ou diffuser nos Services, ni aucune
                  information ou des fichiers accessibles via nos Services,
                  d'une manière qui viole toute loi ou réglementation applicable
                  ou les droits de tout individu ou entité ;
                </li>
                <li>
                  vendre ou transférer toute information incluse dans nos
                  services ou utiliser ces informations pour commercialiser tout
                  produit ou service - y compris en envoyant, ou en facilitant
                  l'envoi de, courriels non sollicités ou SPAM ;
                </li>
                <li>
                  sonder, scanner ou tester la vulnérabilité de nos Services, ou
                  de le système ou le réseau prenant en charge nos services, ou
                  contourner toute mesure de sécurité ou d'authentification ;
                </li>
                <li>
                  disable, bypass, defeat, avoid, remove, deactivate or
                  désactiver, contourner, vaincre, éviter, supprimer, désactiver
                  ou sinon, contourner les mesures techniques que nous avons mis
                  en œuvre pour sauvegarder la stabilité de nos Services, ou la
                  la confidentialité, l'intégrité ou la disponibilité de toute
                  information, le contenu ou les données hébergés ou hébergés
                  sur nos Services ;
                </li>
                <li>
                  introduire dans nos Services tout logiciel, code ou autre
                  appareil qui, de quelque manière que ce soit (i) permet un
                  accès non autorisé à nos systèmes ou tout logiciel, matériel,
                  fichiers ou données qui s'y trouvent, (ii) désactive,
                  endommage ou interfère de toute autre manière avec ou altère
                  affecte le fonctionnement de nos systèmes ou de tout logiciel,
                  matériel, fichiers ou données qui s'y trouvent, ou (iii)
                  surcharge ou interfère avec le bon fonctionnement de nos
                  Services ;
                </li>
                <li>
                  désassembler, décompiler ou effectuer une ingénierie inverse
                  de nos Services ;
                </li>
                <li>
                  récolter, récupérer, indexer ou publier toute partie de notre
                  Services, sauf si vous êtes un moteur de recherche public
                  engagé dans Services de recherche publique ;
                </li>
                <li>
                  désactiver ou contourner nos garanties d'utilisation de l'API,
                  y compris garanties conçues pour réglementer la nature ou la
                  quantité de données que vous êtes autorisé à extraire de nos
                  Services, ou du fréquence à laquelle vous pouvez accéder à ces
                  données ; ou passer des appels à notre API autre que celles
                  autorisées dans notre documentation API ;
                </li>
                <li>
                  supprimer tout droit d'auteur, marque déposée ou autre droit
                  de propriété les avis contenus dans ou sur nos Services ; ou
                </li>
                <li>
                  se livrer à toute activité autre que celles expressément
                  autorisées dans ces Conditions et votre (vos) Contrat(s)
                  d'utilisation.
                </li>
              </ul>
            </p>

            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.4 Utilisation des Services par et pour le compte de Mineurs
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Vous n'êtes pas autorisé à utiliser nos services (y compris
              l'obtention d'un Identifiant ou conclusion d'un contrat
              d'utilisation), sauf si vous êtes à être âgé d'au moins 18 ans et
              avoir la capacité juridique d'entrer dans un contrat contraignant
              dans votre juridiction. Si vous êtes un mineur non émancipé de
              plus de 13 ans, vous ne pouvez utiliser que notre Services si «
              (i) une telle utilisation a été approuvée par votre parent ou
              tuteur légal, (ii) vous utilisez nos Services sous son
              supervision, et (iii) il ou elle a accepté les présentes
              conditions (et, le cas échéant, votre contrat d'utilisation) en
              votre nom.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Si vous êtes le parent ou tuteur d'un mineur non émancipé, vous
              peut utiliser les services et conclure un contrat d'utilisation au
              nom de si mineur. Ce faisant, vous déclarez et garantissez que
              vous avez la capacité juridique d'agir au nom de ce mineur ; et
              toi reconnaissez et acceptez, à ce titre, que toutes les
              dispositions de ces conditions (et le contrat d'utilisation, le
              cas échéant) qui s'appliquent à vous sont également applicables à
              ce mineur.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              En aucun cas nos Services ne peuvent être utilisés par un enfant
              de moins de 13 ans.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              1.5 Responsabilité
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              L’accès à nos Services est destiné aux utilisateurs en AFRIQUE.
              Vous pouvez ne pas utiliser nos services dans une juridiction où
              l'offre, l'accès ou l'utilisation de nos Services serait illégale
              ou illégale.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              En utilisant les services de 10mg Pharma, vous acceptez que 10mg
              Pharma ne n'assume aucune responsabilité dans l'utilisation des
              services de 10mg Pharma et ne peut pas être aidé responsable de
              tout défaut de service
            </p>
          </section>
          <section ref={section3}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue">
              2. NATURE DU CONTENU APPARAISSANT SUR NOS SERVICES
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.1 Aperçu
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nos Services peuvent inclure du texte, des données, des
              graphiques, des images, des vidéos ou autre contenu
              (collectivement, « Contenu ») créé par nous ou par des tiers
              parties, y compris d'autres utilisateurs, professionnels,
              partenaires, annonceurs, sponsors, consommateurs et agences
              gouvernementales. Le Le contenu est fourni à des fins
              d'information générale, mais doit ne peut être invoqué à des fins
              personnelles, professionnelles, médicales ou décisions
              réglementaires. Et nous ne garantissons pas l'exhaustivité,
              l'actualité ou l'exactitude du contenu.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.2 Articles de blog ; Notes ; Enquêtes; Commentaires; Contenu
              soumis par l'utilisateur
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nos services vous permettent d'accéder à des blogs, des forums de
              discussion, des discussions services, enquêtes et autres forums où
              divers utilisateurs peuvent partager informations, opinions,
              discussions et autres contenus. Nous faisons généralement ne pas
              présélectionner ou surveiller le contenu soumis par l'utilisateur,
              et ce contenu peut simplement représenter l'opinion d'un
              utilisateur ou le contenu qu'un utilisateur trouve intéressant.
              Nos services peuvent également inclure des résultats d'enquêtes,
              des évaluations ou des témoignages (« Évaluations ») de patients,
              de clients ou d'autres clients (« Patients ») de professionnels de
              santé (« Professionnels ») qui peuvent approuver, recommander,
              critiquer, analyser, évaluer ou autrement décrire les
              Professionnels et la nature ou qualité des services reçus par ce
              patient, client ou client. De telles évaluations sont des
              témoignages anecdotiques de première main patients individuels et
              ne constituent ni un jugement professionnel ni la produit de la
              science médicale. De telles évaluations ne signifient en aucun cas
              constituent ou impliquent notre approbation ou notre
              recommandation de tout Professionnel. De plus, les évaluations
              sont sujettes à des erreurs et les préjugés qui sont courants dans
              les récits anecdotiques de première main, et ne doit pas être
              présumé fiable ou exempt d’erreurs.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.3 Répertoires : pharmaciens, médecins et autres professionnels
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nos services comprennent des listes et des répertoires
              (« Répertoires ») pour vous aider à trouver des fournisseurs de
              médicaments, des professionnels de santé, des bailleurs de fonds
              spéculatifs (collectivement, les « Professionnels »). Le Des
              répertoires sont fournis pour votre commodité. Les répertoires
              sont pas exhaustif, mais représente plutôt généralement des
              professionnels qui utilisent nos Services et qui ont choisi de
              participer au Annuaires. De plus, parce que votre intérêt est
              notre priorité, nous vérifier la licence et les certifications de
              spécialité de nos répertoires. Les Professionnels sont seuls
              responsables de la la pertinence et la qualité des services qu'ils
              fournissent.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nos services pourraient utiliser les avis sur les produits comme
              atout marketing.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.4 Publicité
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nos services peuvent inclure des publicités ou des messages
              promotionnels sponsorisés par des tiers (collectivement,
              « Annonces »). Le fabricants, prestataires de services,
              distributeurs et revendeurs de les produits et services identifiés
              dans les annonces sont uniquement responsable de l'exactitude de
              leurs Annonces et de leur adéquation, l'efficacité, la sécurité et
              la qualité de ces produits ou services. Une pub ne constitue ni
              n’implique en aucun cas notre recommandation ou l'approbation d'un
              tel produit ou service.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.5 Liens vers d'autres sites
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nos services peuvent contenir des hyperliens (y compris des
              publicités hyperliées) vers des sites Web exploités par des tiers,
              ou à des documents ou des informations mis à disposition par des
              tiers. De tels liens ne constituent ni implique notre approbation
              de ces tiers, ou du contenu de leurs sites, ou la pertinence,
              l’efficacité, la sécurité ou la qualité des leurs produits ou
              services, ou la confidentialité ou la sécurité des informations
              pratiques de leurs sites Web.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.6 Nos services
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Notre contenu comprend des informations relatives aux médicaments
              qui sont fourni à des fins transactionnelles générales ; y
              compris, mais pas limité à notre blog. Nous rendons le commerce
              électronique, via nos Services ou sinon.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              2.7 Aucun conseil juridique ou réglementaire
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Certains contenus peuvent inclure des informations réglementaires
              relatives à vous ou à votre entreprise. Un tel contenu est fourni
              pour à titre informatif uniquement. Nous ne fournissons pas de
              garanties légales ou des conseils réglementaires et aucune
              relation avocat/client n’est créée par votre utilisation de nos
              Services ou du Contenu. En conséquence, toujours demandez l’avis
              de votre avocat ou de vos conseillers pour toute question que vous
              pourriez avoir concernant une loi, un règlement ou un litige.
            </p>
          </section>
          <section ref={section4}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3. VOS INTERACTIONS ET COMMUNICATIONS AVEC NOUS ET NOS SERVICES
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.1 Visiter nos sites Web ; Inscription à nos services ; Envoi par
              courrier électronique Nous
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Lorsque vous vous inscrivez ou vous connectez à l'un de nos
              services protégés, ou lorsque vous soumettez des informations sur
              un formulaire Web, par courrier électronique ou instantanément
              envoyez-nous un message, vous communiquez avec nous par voie
              électronique. Lorsque vous visitez nos sites Web, nous et certains
              fournisseurs de services tiers recueillir des informations lors de
              votre visite. Dans chaque cas, la nature de les informations que
              nous collectons, la durée et la manière dont nous le conserver, la
              manière dont nous l'utilisons et à qui nous le divulguons est régi
              par notre Politique de confidentialité.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Dans le cadre de la fourniture de nos services, nous pouvons être
              amenés à fournir vous avec certaines communications, telles que
              les annonces de service et messages administratifs. Cette
              communication peut se faire par courrier électronique, message
              instantané, une publication sur nos Services ou tout autre
              mécanisme. Vous consentez à recevoir de telles communications de
              notre part électroniquement. Si vous êtes inscrit à un ou
              plusieurs des Services protégés, nos annonces de services et nos
              informations administratives les messages que nous vous
              fournissons sont considérés comme faisant partie des informations
              protégées. Services eux-mêmes, dont vous ne pourrez peut-être pas
              vous désinscrire recevoir jusqu'à ce que vous cessiez d'utiliser
              ou désactiviez vos informations d'identification à ces services
              protégés.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Vous acceptez que tous les accords, avis, autorisations, les
              divulgations et autres communications que nous vous fournissons
              par voie électronique, ainsi que toutes acceptations, accords,
              consentements ou les autorisations que vous nous fournissez par
              voie électronique, satisfont toute exigence légale exigeant que
              ces communications soient conformes en écrivant.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.2 Votre participation à nos forums publics
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous pouvons proposer un ou plusieurs forums d’échange
              d’informations parmi nos utilisateurs. Vous reconnaissez que tout
              texte, donnée, graphique, images, vidéos ou autres contenus
              (« Contenu ») que vous soumettez dans n'importe quel de nos forums
              (y compris les groupes de discussion, les blogs, les enquêtes,
              évaluations, formulaires de commentaires ou forums de discussion,
              collectivement, "Public Forums") est accessible au public.
              Nonobstant les ce qui précède, nous ne sommes pas tenus d'afficher
              vos Contenu que vous soumettez, et nous nous réservons le droit de
              supprimer ou modifier votre contenu à tout moment, pour quelque
              raison que ce soit ou sans raison.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Il est important que vous agissez de manière responsable lorsque
              vous soumettez du contenu à un forum public. Vous reconnaissez que
              tout contenu que vous soumettre dans un forum public est
              accessible au public. Tu es seul responsable de tout contenu que
              vous publiez sur le site public Forums ou transmettre à d’autres
              utilisateurs de nos Services. Toi reconnaissez que toute
              information que vous publiez sur un forum public peut être
              accessible au public et peut donner lieu à votre réception
              communications provenant de tiers en dehors de nos services.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Votre participation à nos forums publics est soumise à nos
              conditions d'utilisation et dépend de votre reconnaissance et de
              votre accord. avec ce qui suit :
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  Vous ne divulguerez des informations vous concernant que sur
                  un site public Forum que vous considérez approprié pour la
                  divulgation publique. Faire ne pas divulguer d’informations
                  permettant de vous identifier personnellement, sauf si vous
                  avez l’intention que ces informations soient divulguées
                  publiquement. Nous vous recommandons fortement de vous
                  abstenir de divulguer tout des informations sensibles vous
                  concernant sur un forum public, y compris des informations sur
                  toute condition médicale.
                </li>
                <li>
                  Vous ne violerez pas les droits à la vie privée d'autrui, y
                  compris divulguer des informations sur les antécédents
                  médicaux ou médicaux de quelqu'un d'autre situation financière
                  ou tout autre sujet sensible.
                </li>
                <li>
                  Vous vous assurerez que tout contenu que vous soumettez au
                  public orums est précis. Si vous évaluez ou évaluez un
                  Professionnel, vous vous engagez à fournir vos appréciations
                  honnêtes de ce Professionnel, sans utiliser de langage
                  inapproprié ou faire des critiques personnelles gratuites.
                </li>
                <li>
                  Vous ne publierez aucun contenu auquel vous n'avez pas le
                  droit poster; vous ne violerez pas les droits de toute
                  personne ou entité la propriété intellectuelle ou les droits
                  de propriété, y compris droits d'auteur, marques déposées ou
                  droits relatifs aux secrets commerciaux.
                </li>
                <li>
                  Nous ne serons pas responsables envers vous du contenu que
                  vous soumettez à tout Forum public.
                </li>
              </ul>
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.3 Suppression du contenu
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Vous comprenez et acceptez que nous pouvons, sans y être obligés,
              surveiller, modifier ou supprimer tout contenu pour quelque raison
              que ce soit ou sans aucune raison temps. Nous ne sommes cependant
              pas responsables de tout retard ou échec dans supprimer tout
              contenu.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.4 Signalement des violations
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous pouvons vous fournir des outils pour signaler les violations
              de les normes communautaires ou d’autres dispositions des
              présentes conditions. Nonobstant la disponibilité de tels outils,
              vous reconnaissez et acceptez que nous ne sommes pas tenus de
              prendre des mesures avec concernant un tel rapport.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              3.5 Politique de droits d'auteur
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous répondrons aux avis de violation présumée du droit d'auteur
              qui sont conformes à la loi applicable et nous sont correctement
              fournis. Si tu pensez que votre contenu a été copié d'une manière
              qui constitue une violation du droit d'auteur, veuillez nous
              fournir le information suivante:
              <ul className=" list-disc ml-6 mt-6">
                <li>
                  Une signature physique ou électronique du titulaire du droit
                  d'auteur ou d'un personne autorisée à agir au nom du
                  propriétaire, si quelqu'un autre que vous, d'un droit exclusif
                  qui serait violé;
                </li>
                <li>
                  Identification du matériel prétendument en infraction ou faire
                  l'objet d'une activité de contrefaçon et qui doit être
                  supprimé ou dont l'accès doit être désactivé, et des
                  informations raisonnablement suffisantes pour nous permettre
                  de localiser le matériel;
                </li>
                <li>
                  Une déclaration de votre part selon laquelle vous croyez de
                  bonne foi que vous utilisez du matériel de la manière dénoncée
                  n'est pas autorisé par le titulaire du droit d'auteur, son
                  agent ou la loi ;
                </li>
                <li>
                  Une déclaration selon laquelle les informations contenues dans
                  la notification sont exacte et, sous peine de parjure, que
                  vous êtes autorisé à agir au nom du titulaire du droit
                  d'auteur ; et
                </li>
                <li>
                  Vos coordonnées, y compris votre adresse, votre téléphone
                  numéro et adresse e-mail
                </li>
                <li>
                  Nous nous réservons le droit de supprimer le contenu présumé
                  être contrefaçon sans préavis, à notre seule discrétion, et
                  sans responsabilité envers vous. Dans des circonstances
                  appropriées, 10 mg Pharma mettra également fin au compte d'un
                  utilisateur si celui-ci est déterminé comme étant un
                  contrevenant récidiviste
                </li>
                <li>
                  Notre agent des droits d'auteur désigné pour les avis de
                  droits d'auteur présumés l'infraction est : 10mg Pharma.
                </li>
              </ul>
            </p>
          </section>
          <section ref={section5}>
            <h2 className="text-lg my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4. DIVERS
            </h2>
            <h3 className="text-base mb-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.1 La possession
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Vous ne conservez pas la propriété des droits de propriété
              intellectuelle dans Contenu que vous soumettez sur nos services.
              Lorsque vous soumettez du contenu sur notre Services que vous nous
              accordez ainsi qu'à ceux avec qui nous travaillons dans le monde
              entier, droit libre de droits de stocker, héberger, reproduire,
              créer un dérivé œuvres de (telles que traductions, adaptations,
              versions reformatées et versions agrégées, anonymisées ou
              anonymisées), publier, exécuter publiquement, afficher, utiliser
              et distribuer ces Contenu tel que décrit plus en détail dans notre
              Politique de confidentialité et, le cas échéant applicable, dans
              votre contrat d'utilisation. Pour certains de nos Services, votre
              contrat d'utilisation ou vos paramètres peuvent restreindre la
              portée de notre utilisation de Contenu que vous soumettez. Vous
              pouvez trouver plus d'informations sur la façon dont nous
              utilisons et stockez le contenu dans notre politique de
              confidentialité ou, le cas échéant, votre Accord de l'utilisateur.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Si vous nous soumettez des idées, suggestions ou propositions
              (collectivement, « Suggestions ») relatifs à nos Services ou à
              d'autres produits ou services par quelque moyen que ce soit, par
              exemple via « Contactez-nous », par email ou autres canaux de
              communication, une de nos communautés ou des forums
              d'utilisateurs, ou à notre service client ou à tout autre
              personnel - ces suggestions (1) ne sont pas régies par notre
              politique de confidentialité, (2) nous ne sommes soumis à aucune
              obligation de confidentialité, expresse ou implicite, en ce qui
              concerne ces suggestions ; (3) nous sommes en droit d'utiliser ou
              divulguer (ou choisir de ne pas utiliser ou divulguer) ces
              suggestions dans de quelque manière et à quelque fin que ce soit ;
              (4) ces suggestions automatiquement devenir notre propriété sans
              aucune obligation ; et (5) vous n'êtes pas droit à toute
              comptabilité, compensation ou remboursement de tout gentil de
              notre part en toutes circonstances.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Aucune exception pour votre contenu, entre vous et nous, d'accord,
              titre et intérêt dans et sur nos services, le contenu et les leur
              structure, leur organisation et leur agencement, sont et restent
              la propriété exclusive de nous et de nos concédants de licence.
              Sauf pour le droits spécifiques limités que nous vous accordons
              ci-dessus, vous ne pouvez pas reproduire, modifier ou préparer des
              œuvres dérivées basées sur, distribuer, vendre, transférer,
              afficher publiquement, exécuter publiquement, exploiter ou
              autrement utiliser nos services ou tout contenu.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.2 Violations
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous nous réservons le droit de surveiller toute utilisation de
              nos Services, et enquêter sur toute activité que nous soupçonnons
              de violer les présentes Conditions, un Contrat d'utilisation, nos
              droits ou intérêts, ou les droits ou intérêts de toute personne ou
              entité.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous nous réservons le droit, dans toute la mesure permise par la
              loi, coopérer avec toute autorité gouvernementale ou tiers
              enquêter sur une conduite qui pourrait être illégale ou nuire à un
              individu ou entité ou viole leurs droits respectifs. Si, par
              exemple, un l'utilisateur menace de blesser physiquement un autre
              utilisateur ou tout autre individuel, nous nous réservons le droit
              de coopérer pleinement avec la loi les autorités chargées de
              l'application de la loi et la personne menacée. Vous par la
              présente consentez à notre coopération dans une telle enquête.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.3 Indemnité
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Vous indemniserez, défendrez et dégagerez 10mg Pharma et tout de
              ses sociétés affiliées, dirigeants, administrateurs, employés,
              agents, représentants, partenaires et concédants de licence de et
              contre tout et toutes réclamations, actions, procédures,
              poursuites, responsabilités, dommages, règlements, pénalités,
              amendes, pertes ou dépenses, y compris les honoraires, frais et
              débours d'avocat, découlant de ou dans tout manière liée à votre
              utilisation de nos Services.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.4 Des disputes; Loi applicable ; Juridiction
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              L'interprétation des présentes Conditions et la résolution de tout
              les litiges découlant des présentes Conditions seront régis par
              les lois de l'État de Lagos, sans égard à ses dispositions
              relatives aux conflits de lois. Les présentes Conditions ne seront
              pas régies par la Convention des Nations Unies sur le contrat de
              vente internationale de marchandises, l'application ce qui est
              expressément exclu. Si une action ou autre la procédure est
              intentée ou en relation avec les présentes Conditions, vous
              accepter de se soumettre à la juridiction personnelle de l’État et
              tribunaux fédéraux situés à Ikeja, dans l'État de Lagos, et
              acceptons de ne pas intenter toute action relative à l’utilisation
              de nos Services ou à ces Conditions devant tout tribunal dans
              toute juridiction autre que les tribunaux étatiques ou fédéraux
              situés à Ikeja, dans l’État de Lagos. Nous aura le droit d'engager
              et de poursuivre toute procédure judiciaire ou action ou procédure
              équitable devant tout NIGERIEN ou non NIGERIEN tribunal compétent
              pour faire appliquer les présentes Conditions ou pour protéger nos
              droits ou ceux de tout tiers sur nos Services ou tout autre
              données, informations ou autres contenus mis à disposition via
              notre Prestations de service. Vous renoncez par la présente à tout
              droit à un procès devant jury. Toi aussi acceptez que nous
              puissions intenter une action en justice pour interdire toute
              violation de ces Conditions sans dépôt de caution ou de garantie,
              en plus à tous les recours que nous pourrions avoir en droit. Dans
              tout litige entre vous et nous là où nous prévalons, nous aurons
              le droit de récupérer notre honoraires d'avocat raisonnables,
              frais de justice, débours et autres frais juridiques.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.5 Résiliation
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Vous acceptez et reconnaissez que nous pouvons suspendre ou
              résilier votre autorisation d’accéder à l’un de nos Services, avec
              ou sans avis ou cause, pour quelque raison que ce soit, et sans
              aucune responsabilité pour vous. Les sections 4.1 à 4.14
              survivront à toute résiliation ou l'expiration des présentes
              Conditions.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.6 Avis de non-responsabilité et limitations de responsabilité
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              ACCÈS À NOS SERVICES ET AUX INFORMATIONS ET CONTENUS CONTENUS IL
              EST FOURNI « TEL QUEL » ET « SELON LA DISPONIBILITÉ » ET PAR LA
              PRÉSENTE, NOUS DÉCLINE EXPRESSÉMENT TOUTE GARANTIE DE QUELQUE
              SORTE, QU'ELLE SOIT EXPRESSÉE, IMPLICITE, LÉGALE OU AUTRE, Y
              COMPRIS MAIS SANS LIMITATION, LE GARANTIES IMPLICITES DE QUALITÉ
              MARCHANDE, D'ADAPTATION À UN PRODUIT PARTICULIER OBJECTIF,
              NON-VIOLATION ET TITRE. NOUS NE GARANTISSONS PAS QUE LE LES
              SERVICES SERONT ININTERROMPUS, SANS ERREURS OU SANS VIOLATIONS O
              SÉCURITÉ ET VOUS ACCEPTEZ QUE VOUS ÊTES SEUL RESPONSABLE DE TOUT
              ET TOUS LES ACTES OU OMISSIONS PRIS OU FAITS EN FONCTION DE NOTRE
              SERVICES OU LES INFORMATIONS CONTENUES DANS NOS SERVICES, Y
              COMPRIS DES INEXACTES OU INFORMATIONS INCOMPLETES. NOUS DÉCLINONS
              EXPRESSÉMENT TOUTE RESPONSABILITÉ CONCERNANT TOUTE BLESSURE CAUSÉE
              PAR UN UTILISATEUR OU TOUT DOMMAGE SOUFFRÉS PAR TOUT UTILISATEUR,
              À LA SUITE DES ACTIONS OU INACTIONS DE TOUT AUTRE UTILISATEUR.
              VOUS ÊTES INSATISFAIT DE NOS SERVICES OU TOUT CONTENU, VOTRE SEUL
              ET EXCLUSIF RECOURS EST D'ARRÊTER UTILISER ET ACCÉDER À NOS
              SERVICES. CERTAINES JURIDICTIONS NE PERMETTENT PAS L'EXCLUSION OU
              LA LIMITATION DES GARANTIES IMPLICITES, DONC DANS CES JURIDICTIONS
              LES LIMITATIONS OU EXCLUSIONS CI-DESSUS PEUVENT NE PAS S'APPLIQUER
              À L'UTILISATEUR.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.7 Risques que vous assumez
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              SANS LIMITER AUCUN DES AUTRES RISQUES QUE NOUS VOUS AVONS DIVULGÉS
              DANS CES CONDITIONS, VOUS ÊTES SEUL RESPONSABLE DE VOTRE
              UTILISATION DE NOTRE SERVICES, Y COMPRIS TOUT CONTENU QUE VOUS
              SOUMETTEZ À UTILISER ET VOUS RECONNAISSEZ ET ACCEPTEZ EXPRESSÉMENT
              QUE VOTRE UTILISATION ET VOTRE ACCÈS À NOTRE LES SERVICES ET LES
              INFORMATIONS ET LE CONTENU QUI Y SONT CONTENUS, ET TOUS LES SITES
              LIÉS VIA NOS SERVICES ET TOUTES DONNÉES TRANSMISES PAR NOS
              SERVICES, C'EST À VOS PROPRES RISQUES. PAR CONSÉQUENT, NOUS NE
              ASSUMEZ TOUTE RESPONSABILITÉ ENVERS VOUS POUR OU RELATIVE À L'UN
              DE VOS ACTIONS, Y COMPRIS LA PUBLICATION DE TOUT CONTENU QUE VOUS
              SOUMETTEZ OU NOTRE EXERCICE DES DROITS QUE VOUS NOUS ACCORDEZ À CE
              SUJET.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.8 Limitation de responsabilité
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              EN AUCUN CAS NOUS OU AUCUNE DE NOS FILIALES OU AFFILIÉS NE
              DEVRONS, OU NOS DIRIGEANTS, DIRECTEURS, EMPLOYÉS, AGENTS
              RESPECTIFS, REPRÉSENTANTS, PARTENAIRES ET CONCÉDANTS DE LICENCE
              (COLLECTIVEMENT, LES "10mg ENTITÉS Pharma") ÊTRE RESPONSABLES DE
              TOUT SPÉCIAL, INDIRECT, DOMMAGES INDIRECTS OU EXEMPLAIRES, Y
              COMPRIS, MAIS SANS LIMITATION TO, PERTE DE PROFITS OU DE REVENUS,
              PERTE D'USAGE, PERTE DE GOODWILL OU PERTE D'INFORMATIONS, QUELLE
              QU'EN SOIT LA CAUSE ET QU'ELLE SOIT BASÉE SUR CONTRAT, GARANTIE,
              NÉGLIGENCE, RESPONSABILITÉ STRICTE OU TOUT AUTRE THÉORIE DE LA
              RESPONSABILITÉ, MÊME SI NOUS AVONS ÉTÉ CONNAISSÉS POSSIBILITÉ OU
              PROBABILITÉ DE TELS DOMMAGES. NONOBSTANT TOUT DANS CES CONDITIONS
              CONTRAIRE, NOTRE RESPONSABILITÉ GLOBALE EN CE QUI CONCERNE TOUTE
              RÉCLAMATION OU ACTION QUE VOUS POUVEZ INTRODUIRE CONTRE NOUS OU
              TOUTE DES ENTITÉS Pharma 10mg, QUELLE QUE SOIT LA FORME D'ACTION
              OU LA THÉORIE DE LA RESPONSABILITÉ, SERA LIMITÉE AU PLUS GRAND DES
              ENTRE (1) TRENTE MILLE NAIRA NIGÉRIENS (NAIRA \N30,000) ET (2) LE
              FRAIS GLOBAUX RÉELLEMENT PAYÉS PAR VOUS À NOUS POUR LES SIX (6)
              MOIS PÉRIODE PRÉCÉDANT LE PREMIER ÉVÉNEMENT DONNANT LIEU À UNE
              TELLE RÉCLAMATION OU ACTION. VOUS RECONNAISSEZ QUE VOUS POUVEZ
              RENONCER À DROITS AVEC RESPECT DES RÉCLAMATIONS INCONNUES OU
              INSOUPÇONNÉES. PAR CONSÉQUENT, VOUS ACCEPTEZ DE RENONCER AU
              BÉNÉFICE DE TOUTE LOI, Y COMPRIS AU MESURE APPLICABLE.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.9 Divisibilité
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Si une disposition des présentes Conditions est jugée invalide ou
              inapplicable, alors (a) cette disposition doit être interprétée
              dans le sens dans la mesure nécessaire pour le rendre valide et
              exécutoire de cette manière ce qui se rapproche le plus de la
              préservation des intentions d'une telle disposition, et (b) les
              dispositions restantes resteront pleinement en vigueur et effet.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.10 Aucune renonciation
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Notre échec, à tout moment, à exiger de votre part l'exécution de
              tout la disposition des présentes Conditions n'affectera en aucun
              cas notre droit de appliquer cette disposition, et la renonciation
              à toute violation de votre part ne de toute disposition des
              présentes constitue une renonciation à toute violation ou la
              disposition elle-même.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.11 Interprétation
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Aux fins des présentes Conditions, « vous » désigne l'utilisateur
              individuel de nos Services. Si votre accès ou votre utilisation de
              nos Services est, directement ou indirectement, pour le compte
              d'un ou plusieurs tiers (comme, sans s'y limiter, votre employeur
              ou client, ou votre client de l'employeur, si votre employeur a
              été engagé pour accéder à notre Services (un tel employeur, client
              ou autre tiers, un « Principal »), puis « vous » fait également
              référence à ce Principal. Si tu vous utilisez nos Services au nom
              d'un Mandant, (a) vous représentez et garantissez que vous avez le
              pouvoir de lier et d'avoir lié, ce Mandataire aux présentes
              Conditions ; et (b) vous acceptez d'être conjointement et
              solidairement responsable de toute violation des présentes
              Conditions par le Principal.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              « 10mg Pharma », « nous », « notre » et « notre » signifient,
              collectivement, 10ILESGREEN Pharma, Ltd. et nos filiales actuelles
              et futures et affiliés.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              De plus, les mots « inclure », « comprend » et « y compris »
              doivent être réputé être suivi de l'expression « sans limitation
              ». Le Le mot « volonté » doit être interprété comme ayant le même
              sens et le même effet comme le mot "doit". Le mot « ou » doit être
              interprété comme ayant le même signification et même effet que «
              et/ou ». Les mots « dans les présentes », « dans les présentes »
              et « ci-dessous », ainsi que les mots d'importance similaire,
              doivent être interprétés de se référer aux présentes Conditions.
              Les titres utilisés dans les présentes Conditions sont utilisés
              pour commodité uniquement et ne doivent pas être pris en compte
              dans l’interprétation ou interpréter ces Conditions.
            </p>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Les présentes conditions, ainsi que votre (vos) contrat(s)
              d'utilisation, constituent le l'intégralité de l'accord entre vous
              et 10mg Pharma concernant tout services accessibles via nos
              Services, et remplace tous les précédents communications,
              représentations ou ententes, orales ou écrit, relatif à l'objet
              des présentes ; à condition toutefois (a) en cas de conflit exprès
              entre un disposition incluse dans les présentes Conditions et une
              disposition expresse dans un Contrat d'utilisation, la disposition
              énoncée dans le Contrat d'utilisation doit prévalent, et (b) les
              présentes Conditions couvrent tous les droits, obligations, termes
              et conditions non expressément abordés dans cet utilisateur
              Accord.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.12 Contrat électronique
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Votre utilisation de nos Services inclut la possibilité de
              conclure accords et/ou pour effectuer des transactions par voie
              électronique. TOI RECONNAISSEZ QUE VOS SOUMISSIONS ÉLECTRONIQUES
              CONSTITUENT VOTRE ACCORD ET INTENTION D'ÊTRE LIÉ PAR DE TELS
              ACCORDS ET TRANSACTIONS. VOTRE ACCORD ET VOTRE INTENTION D'ÊTRE
              LIÉ PAR ÉLECTRONIQUE LES SOUMISSIONS S'APPLIQUENT À TOUS LES
              DOSSIERS RELATIFS À TOUTES LES TRANSACTIONS VOUS PARTICIPEZ AUX
              SERVICES, Y COMPRIS LES AVIS DE ANNULATION, POLITIQUES, CONTRATS
              ET DEMANDES.
            </p>
            <h3 className="text-sm my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.13 Affectation
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous pouvons librement céder ces Conditions dans le cadre d'une
              fusion, acquisition ou vente d'actifs, ou par effet de la loi ou
              sinon.
            </p>
            <h3 className="text-base my-6 font-ibmPlexSans text-berkeleyBlue font-medium">
              4.14 Amendements
            </h3>
            <p className="text-sm font-ibmPlexSans text-naturalGrey my-6 leading-7">
              Nous pouvons mettre à jour ou modifier nos services ou les
              dispositions énoncées dans les présentes Conditions de temps à
              autre et vous recommandons de consulter ces Conditions de manière
              régulière. Vous comprenez et acceptez que votre utilisation
              continue de nos services après la mise à jour de ces conditions ou
              modifié constitue votre acceptation des Conditions révisées. Sans
              limiter ce qui précède, si nous apportons une modification à ces
              Conditions qui affectent matériellement votre utilisation des
              Services, nous pouvons publier un remarquer ou vous informer par
              e-mail ou sur notre (nos) site(s) Web d'un tel changement. La
              version la plus récente des Conditions régit et remplace toutes
              les versions précédentes.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsFr;
