import vector from "../images/Vector.png";
import deliveryTruck from "../images/delivery-truck.png";
import accountIcon from "../images/manage-accounts.png";
import billingIcon from "../images/credit-card.png";
import receiptIcon from "../images/receipt.png";
import supportIcon from "../images/support.png";

export const commonTopics = [
  {
    id: 1,
    title: "Orders and Delivery",
    icon: deliveryTruck,
    description: "order tracking, delivery estimates and more",
    color: "#FBFBF8",
  },
  {
    id: 2,
    title: "Product Information",
    icon: vector,
    description: "Medication types, authenticity etc",
    color: "#F8FBFA",
  },
  {
    id: 3,
    title: "Account and Security",
    icon: accountIcon,
    description: "Reset password, your Information ",
    color: "#F8F9FB",
  },
  {
    id: 4,
    title: "Payment and Billing",
    icon: billingIcon,
    description: "Payment methods, invoices etc",
    color: "#F8FAFB",
  },

  {
    id: 5,
    title: "Returns and Refunds",
    icon: receiptIcon,
    description: "Return policy",
    color: "#FAF8FB",
  },

  {
    id: 6,
    title: "Customer Support",
    icon: supportIcon,
    description: "Helpline, contact information ",
    color: "#FBFBF8",
  },
];

export const faqCategoriesData = [
  {
    id: 1,
    title: " Orders and Delivery",
    desc: "Order tracking, delivery estimates and more",
    questions: [
      {
        question: "Why do we have expedited delivery?",
        answer:
          "We understand that certain health conditions may require a more urgent delivery, and our expedited service is designed to meet those specific needs. Whether it's a critical prescription or a time-sensitive healthcare requirement, expedited delivery provides a faster shipping option, ensuring that our customers receive the necessary medications promptly, promoting both convenience and peace of mind.",
      },
      {
        question: "What types of delivery do you provide?",
        answer:
          "Our primary mission is the transformation of the healthcare system, with unwavering focus. Every decision we make aligns with achieving this mission, prioritizing our goals over politics or politeness. We operate with a sense of ownership, consistently identifying opportunities for improvement beyond our current responsibilities.",
      },
      {
        question: "How can I track my order?",
        answer:
          "Our primary mission is the transformation of the healthcare system, with unwavering focus. Every decision we make aligns with achieving this mission, prioritizing our goals over politics or politeness. We operate with a sense of ownership, consistently identifying opportunities for improvement beyond our current responsibilities.",
      },
      {
        question: "What to do if my order is mistaken or damaged?",
        answer:
          "Our primary mission is the transformation of the healthcare system, with unwavering focus. Every decision we make aligns with achieving this mission, prioritizing our goals over politics or politeness. We operate with a sense of ownership, consistently identifying opportunities for improvement beyond our current responsibilities.",
      },
    ],
  },
  {
    id: 2,
    title: " Product Information",
    desc: "Order tracking, delivery estimates and more",
    questions: [
      {
        question: "What does your product/service offer?",
        answer:
          "At 10mg Pharma we offer the following services to meet your specific needs: We provide access to affordable chronic pain medications through direct procurement from manufacturers. No more middlement bottleneck We also facilitate financing for pharmacies/hospitals",
      },
      {
        question: "What are the key features of your product/service?",
        answer:
          "Low cost chronic pain medications, financial support in the form of collateral-free loans and an end to end inventory management system to optimize your medications stock.",
      },
      {
        question: "How can I benefit from your product/services?",
        answer:
          "To benefit from any of our services, sign up as a supplier or a healthcare provider to unlock unlimited benefits. Learn more(link to whatsapp)",
      },
    ],
  },
  {
    id: 3,
    title: " Account and Security",
    desc: "Order tracking, delivery estimates and more",
    questions: [
      {
        question: "How do I create an account on your platform?",
        answer:
          "Follow these 2 simple steps to: 1.	Register as a supplier, or healthcare provider 2.	Provide the required documents",
      },
      {
        question:
          "Can I update my account information, such as email address or password?",
        answer: "Yes. This can be done from settings(Link)",
      },
      {
        question: "What should I do if I forget my account password?",
        answer:
          "On the sign in page, click on reset password on the and follow the prompt",
      },
      {
        question: "Is there a way to deactivate or close my account?",
        answer:
          "Yes. From the settings(Link to) or email support(link to info@10mg.co.uk)",
      },
      {
        question:
          "Are there any benefits to creating an account, such as loyalty rewards or discounts?",
        answer:
          "Yes, we value our customers and currently designing a reward program",
      },
    ],
  },
  {
    id: 4,
    title: "Payment and Billing",
    desc: "Order tracking, delivery estimates and more",
    questions: [
      {
        question: "What payment methods do you accept?",
        answer:
          "We accept cash at the point of delivery, bank transfers and payment at checkout. USSD coming soon",
      },
      {
        question: "Is my payment information secure?",
        answer:
          "Rest assured, we employ the latest encryption technology and adhere to strict security protocols to safeguard your data. Your payment details are encrypted during transmission and stored securely in compliance with industry standards.",
      },
      {
        question: "When will my credit/debit card be charged?",
        answer:
          "Your credit/debit card will be charged upon completion of your order. You will receive a confirmation email once the payment has been successfully processed.",
      },
      {
        question: "Can I receive a copy of my invoice or receipt?",
        answer:
          "We promptly send a copy of your invoice or receipt via email. Kindly provide us an active email to receive invoice and receipts notification after payment.",
      },
      {
        question: "Are there any additional fees or charges?",
        answer:
          "We do not charge additional fees. Before payment is made we'll send you a quote to confirm your payment.",
      },
    ],
  },
  {
    id: 5,
    title: "Returns and Refunds",
    desc: "Order tracking, delivery estimates and more",
    questions: [
      {
        question: "Return and Refund Policy Summary:",
        answer:
          "Contact customer support to initiate the return process. Refunds will be issued minus restocking fees and return shipping costs. Damaged or defective items will be replaced or refunded promptly. For more information, contact us at info@10mg.co.uk",
      },
    ],
  },
  {
    id: 6,
    title: " Customer Support",
    desc: "Order tracking, delivery estimates and more",
    questions: [
      {
        question: "Want to contact us?",
        answer: "Contact us via info@10mg.co.uk ",
      },
    ],
  },
];
