import React, { useState } from "react";
import { IoChevronDown, IoGlobeSharp } from "react-icons/io5";
import englishFlag from "../images/gb.png";
import frenchFlag from "../images/fr.png";

const LanguageDropdown = ({ onLanguageChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "English",
    flag: englishFlag,
  });

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (language) => {
    const flag = language === "English" ? englishFlag : frenchFlag;
    setSelectedLanguage({ name: language, flag });
    setDropdownOpen(false);
    onLanguageChange(language);
  };
  return (
    <div className="relative">
      <button
        className="flex justify-center items-center gap-2 font-cabin font-[500px] text-[16px]"
        onClick={toggleDropdown}
      >
        <IoGlobeSharp size={20} className="hidden lg:block" />
        <p className="hidden lg:block">{selectedLanguage.name}</p>
        <img
          src={selectedLanguage.flag}
          alt={`${selectedLanguage.name} Flag`}
        />
        <IoChevronDown />
      </button>

      {dropdownOpen && (
        <div className="dropdown-options absolute bg-white text-black w-full rounded-md mt-3 text-center">
          <div
            onClick={() => handleLanguageChange("English")}
            className="border-b-2 cursor-pointer hover:bg-gray-300
            p-2 rounded-t-md flex justify-center items-center gap-2"
          >
            <img src={englishFlag} alt="English Flag" />
            <p className="hidden lg:block">English</p>
          </div>
          <div
            onClick={() => handleLanguageChange("French")}
            className=" cursor-pointer hover:bg-gray-300
            p-2 rounded-b-md flex justify-center items-center gap-2"
          >
            <img src={frenchFlag} alt="English Flag" />
            <p className="hidden lg:block">French</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
