import React, { useState } from "react";
import Banner from "../banner";
import { FaXTwitter, FaLinkedin, FaInstagram } from "react-icons/fa6";

import emma from "../../images/emma.png";
import gabby from "../../images/gabby.png";
import joy from "../../images/joy.png";
import otega from "../../images/otega.png";
import samb from "../../images/samb.png";
import mary from "../../images/maryin.png";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, Scrollbar } from "swiper/modules";

const StaffSpotlight = () => {
  const images = [emma, gabby, joy, otega, samb, mary];
  const textContent = [
    {
      name: "Ojadi Emmanuel",
      occupation: "Content Creator",
      story: `With over 3 years experience of helping Startups grow their 
      online presence and increase their brand visibility through 
      effective social media management.`,
    },
    {
      name: "Gabriella Chukwujindu",
      occupation: "Pharmacist",
      story: `Gabriella Chukwujindu is a Pharmacist and sales associate at 10mg Pharma. 
      - With over 3 years experience in the Pharmaceutical industry.`,
    },
    {
      name: "Odianosen Joy Peter",
      occupation:
        "Co-founder/ COO 10mg Pharma, Social Entrepreneur and Lawyer.",
      story: `With over 5 years experience in social impact and business operations.`,
    },
    {
      name: "Obaro Christopher Otega",
      occupation: "Graphic Designer.",
      story: `Obaro is a graphic designer with over three years of experience, from he has acquired valuable expertise in the field.`,
    },
    {
      name: "Samb Mody Dieyela",
      occupation: "Director of Operations, 10mg Pharma, Senegal.",
      story: ``,
    },
    {
      name: "Maryin-Jesu Deborah Ehinlaiye",
      occupation: "Customer Support Representative.",
      story: `Maryin-jesu Deborah Ehinlaiye is a Customer Support Representative with 2 years of experience in customer service and relations.  She possesses strong negotiation skills,  a proven track record in resolving complex issues, building customer relationships, and consistently exceeding satisfaction ratings.`,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const [expandStory, setExpandStory] = useState(false);

  const toggleStory = () => {
    setExpandStory(!expandStory);
  };

  return (
    <div className="p-2 md:p-5 lg:p-10 py-10 text-center">
      <div
        className="flex flex-col justify-center items-center w-full lg:w-1/2 
        mx-auto space-y-3 text-berkeleyBlue py-10"
      >
        <Banner title="staff spotlight" />
        <h1 className="text-[28px]">
          What it’s like working at 10mg from the best people
        </h1>
      </div>

      {/* Images */}
      <div className="w-full relative p-5 lg:p-10">
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          grabCursor={true}
          centeredSlides={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          pagination={false}
          modules={[Autoplay, Scrollbar]}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
          }}
        >
          <div className="flex items-center justify-center gap-1">
            {images.map((image, index) => (
              <SwiperSlide>
                <img
                  key={index}
                  src={image}
                  alt={textContent[index].title}
                  className="rounded-lg w-[600px]"
                  style={{
                    opacity: activeIndex === index ? 1 : 0.1,
                  }}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>

        {/* Texts that change */}
        <div
          className="bg-[#F6F4EF] p-5 py-4 lg:py-8 rounded-lg mt-10 w-full sm:w-[40%] 
        md:w-[30%] lg:w-1/4 text-left lg:absolute left-0 lg:left-[100px] bottom-8 
        lg:bottom-20 z-10"
        >
          <h1 className="text-sm lg:text-3xl text-[#B3001B]">
            {textContent[activeIndex].name}
          </h1>
          <p className="text-[10px] lg:text-[13px] font-ibmPlexSans text-berkeleyBlue">
            {textContent[activeIndex].occupation}
          </p>
          {expandStory && (
            <div className="h-[50px] lg:h-[130px] overflow-auto">
              <p className="text-[10px] lg:text-[13px] font-ibmPlexSans text-[#011627] mt-4">
                {textContent[activeIndex].story}
              </p>
            </div>
          )}
          <div className="mt-5 flex justify-start items-center gap-5 text-berkeleyBlue">
            <FaXTwitter />
            <FaLinkedin />
            <FaInstagram />
          </div>
          <button
            className="text-xs text-berkeleyBlue font-ibmPlexSans 
            border border-berkeleyBlue p-1 px-5 rounded-full mt-3 cursor-pointer"
            onClick={toggleStory}
          >
            {expandStory ? "CLOSE HER STORY +" : "READ HER STORY"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StaffSpotlight;
