import { useState } from "react";
import { FaAngleDown } from "react-icons/fa";

const QCard = ({ question, index }) => {
  const isFirst = index === 0;
  const [isExpanded, setIsExpanded] = useState(isFirst);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <article className="bg-[#F7F7F8] mb-8 p-3 rounded-md">
      <div
        className="mb-3 flex justify-between hover:bg-[#dbdada] px-2 py-2 cursor-pointer rounded-md"
        onClick={handleToggle}
      >
        <h2 className="font-ibmPlexSans text-lg text-berkeleyBlue font-normal leading-26 tracking-normal">
          {question.question}
        </h2>
        <div className="h-6 w-6  rounded-full bg-[#E6E6E6] flex justify-center items-center">
          <FaAngleDown
            className={`h-[16px] w-[16px] text-berkeleyBlue ${
              isExpanded ? "transform rotate-180" : ""
            }`}
          />
        </div>
      </div>
      {isExpanded && (
        <p className="font-ibmPlexSans text-sm font-normal leading-24 tracking-normal">
          {question.answer}
        </p>
      )}
    </article>
  );
};

export default QCard;
