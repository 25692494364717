import Banner from "../banner";

import pain from "../../img/pain.png";

const PainProblemFr = () => {
  return (
    <div
      className="bg-[#F7F7F9] p-5 py-20 lg:p-20 text-center"
      id="the-problem"
    >
      <div className="flex flex-col justify-start lg:justify-center items-start lg:items-center gap-4 w-full lg:w-1/2 mx-auto">
        <Banner title="le problème" />
        <h1 className="text-left lg:text-center text-3xl font-medium text-berkeleyBlue">
          L’épidémie de douleur chronique est là
        </h1>
        <p className="text-left lg:text-center text-lg text-[#011627] font-ibmPlexSans">
          La douleur chronique peut avoir un impact dévastateur partout où elle
          est ressentie, mais en Afrique, elle est particulièrement
          problématique en raison d’un manque de sensibilisation, de ressources
          et d’infrastructures médicales.
        </p>
      </div>
      <div className="font-ibmPlexSans flex flex-col lg:flex-row justify-between items-center gap-5 mt-10 lg:mt-20">
        <div className="flex flex-col justify-start items-start gap-8 text-left text-[#011627]">
          <img
            src={pain}
            alt="the problem"
            className="lg:hidden rounded-lg w-full"
          />
          <h1 className="text-3xl font-pangaia text-berkeleyBlue w-[70%]">
            Combattre la douleur à travers la communauté
          </h1>
          <p>
            L'Organisation mondiale de la santé estime que plus de 70 % des
            personnes souffrant de douleur chronique dans le monde vivent dans
            des pays à revenu faible ou intermédiaire, dont beaucoup se trouvent
            en Afrique. Le fardeau de la douleur chronique en Afrique affecte à
            la fois les individus et les sociétés.
          </p>

          <p>
            Pour atténuer le taux de mortalité des patients qui meurent du
            cancer, la faucille maladies cellulaires et autres maladies
            douloureuses chroniques, nous mettons en place programmes et
            initiatives grâce à notre impact social et programmes
            communautaires.
          </p>
        </div>
        <img
          src={pain}
          alt="the problem"
          className="hidden lg:block rounded-lg w-1/2"
        />
      </div>
    </div>
  );
};

export default PainProblemFr;
