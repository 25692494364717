import { Link } from "react-router-dom";
import logoOff from "../images/logo_off.png";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedinIn, FaInstagram, FaFacebookF } from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import LanguageDropdown from "./language-dropdown";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleTheProblem = () => {
    navigate("/about?section=the-problem");
    const query = new URLSearchParams(window.location.search);
    if (query.get("section") === "the-problem") {
      const element = document.getElementById("the-problem");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleFoundersDesk = () => {
    navigate("/?section=founder-desk");
    const query = new URLSearchParams(window.location.search);
    if (query.get("section") === "founder-desk") {
      const element = document.getElementById("founder-desk");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleSupportersSection = () => {
    navigate("/?section=supporters");
    const query = new URLSearchParams(window.location.search);
    if (query.get("section") === "supporters") {
      const element = document.getElementById("supporters");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <footer className="bg-[#F7F7F9] h-auto z-50">
      <div
        className="border md:border-r-[#E6E6E6] 
            border-l-[#E6E6E6] md:border-l-0 border-t-0 border-b-[#E6E6E6] 
            md:border-b-0"
      >
        <div
          className="flex flex-col md:flex-row justify-between items-center gap-0 md:gap-7
          p-4 md:p-0 border md:border-r-[#E6E6E6] 
            border-l-[#E6E6E6] md:border-l-0 border-t-0 border-b-[#E6E6E6] 
            md:border-b-0 "
        >
          <div
            className="border md:border-r-[#E6E6E6] 
            border-l-[#E6E6E6] md:border-l-0 border-t-0 border-b-[#E6E6E6] 
            md:border-b-0 w-full md:w-1/2 px-5 py-10 md:p-10 md:py-0"
          >
            <div
              className="flex md:flex-col justify-between items-start 
            content-between md:h-[300px] h-[100px] "
            >
              <div
                className="flex flex-col justify-between 
              items-start font-ibmPlexSans gap-2 text-[16px] text-berkeleyBlue"
              >
                <p>info@10mg.co.uk</p>
                <p>Birmingham, UK</p>
              </div>
              <img src={logoOff} alt="black and white logo" width="70" />
            </div>
          </div>

          {/* Newsletter */}
          <div
            className="flex flex-col justify-start items-start gap-10 
            w-full md:w-1/2 px-4 md:p-10 border md:border-r-0 
            border-l-[#E6E6E6] md:border-l-0 border-t-0 border-b-[#E6E6E6] 
            md:border-b-0"
          >
            <div className="py-10">
              <h2 className="text-[28px] md:text-[38px] leading-[62.4px] text-berkeleyBlue">
                Join our newsletter
              </h2>
              <p
                className="font-ibmPlexSans text-[16px] md:text-[18px] 
                text-berkeleyBlue"
              >
                Updates and announcements from the people at 10mg
              </p>
            </div>
            <form className="w-full flex justify-center items-center gap-3">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-4 md:p-2 px-5 bg-[#FAFAFC] w-[60%] md:w-[80%] border border-b-[#EEEEF0] 
              font-ibmPlexSans text-[#808080] text-[16px] font-normal rounded-md"
              />
              <button
                className="w-[40%] md:w-[20%] font-ibmPlexSans text-white 
                text-[16px] font-normal bg-berkeleyBlue p-4 md:p-2 rounded-md"
              >
                Subscribe
              </button>
            </form>
            <p className="font-ibmPlexSans font-normal text-[#808080] text-[16px] my-5 mb-10 md:mt-10">
              You can unsubscribe at any time. Learn more about our{" "}
              <Link to="/privacy">
                <span className="text-berkeleyBlue">Privacy Policy</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-[#E6E6E6]"></div>
      <div className="p-4 md:p-10 mt-7 md:mt-0">
        <div className="flex flex-col lg:flex-row flex-wrap justify-between items-start">
          <div className="flex flex-col justify-start items-start gap-10">
            <div className="flex justify-between md:justify-start items-center gap-8 text-berkeleyBlue w-full">
              <a
                href="https://x.com/10mgPharma?t=1r_Hgg5lo05WxVP1G-GjVA&s=08"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitterX size={25} />
              </a>
              <a
                href="https://www.linkedin.com/company/10mgpharma/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn size={25} />
              </a>
              <a
                href="https://www.instagram.com/10mgpharma?igsh=ZGUzMzM3NWJiOQ=="
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={25} />
              </a>
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <FaFacebookF size={25} />
              </a>
              <a href="mailto:info@10mg.co.uk" target="_blank" rel="noreferrer">
                <MdOutlineAlternateEmail size={25} />
              </a>
            </div>
            <div
              className="font-ibmPlexSans text-berkeleyBlue flex md:flex-col 
                gap-4 w-full"
            >
              <p>+44 7955466155</p>
              <a
                href="https://wa.me/2349168634430"
                target="_blank"
                rel="noreferrer"
              >
                <p>+234 9168634430</p>
              </a>
            </div>
          </div>
          {/* About Us */}
          <div className="mt-10 md:mt-0">
            <h2 className="text-[27px] md:text-[30px] text-[#011627]">
              About Us
            </h2>
            <ul
              className="text-[16px] font-ibmPlexSans text-berkeleyBlue 
                font-normal mt-5 flex flex-col gap-4"
            >
              <li onClick={handleFoundersDesk} className="cursor-pointer">
                Founder's Desk
              </li>
              <Link to="/about">
                <li>Our Story</li>
              </Link>
              <li onClick={handleTheProblem} className="cursor-pointer">
                Challenges
              </li>
            </ul>
          </div>
          {/* Work with us */}
          <div className="mt-10 md:mt-0">
            <h2 className="text-[27px] md:text-[30px] text-[#011627]">
              Work With Us
            </h2>
            <ul
              className="text-[16px] font-ibmPlexSans text-berkeleyBlue 
                font-normal mt-5 flex flex-col gap-4"
            >
              <Link to="/signup-pharmacy">
                <li>Suppliers</li>
              </Link>
              <Link to="/signup-pharmacy">
                <li>Pharmacy</li>
              </Link>
              <li onClick={handleSupportersSection} className="cursor-pointer">
                Partners
              </li>
            </ul>
          </div>
          <div className="mt-10 md:mt-0">
            <h2 className="text-[27px] md:text-[30px] text-[#011627]">
              Resources
            </h2>
            <ul
              className="text-[16px] font-ibmPlexSans text-berkeleyBlue 
                font-normal mt-5 flex flex-col gap-4"
            >
              <Link to="/blog">
                <li>Blog</li>
              </Link>
              <Link to="/faqs">
                <li>FAQs</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="h-[1px] w-full bg-[#E6E6E6] my-10"></div>
        <div
          className="flex flex-col md:flex-row justify-start md:justify-between 
          items-start md:items-center relative font-ibmPlexSans mb-10 gap-8 w-full"
        >
          <p>Copyright &copy; 2024. 10mg Pharma</p>
          <div className="flex justify-between items-center gap-4 w-full lg:w-auto">
            <LanguageDropdown />
            <div className="flex justify-end items-center gap-8">
              <Link to="/terms">Terms</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
