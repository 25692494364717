import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Banner from '../banner'
import impactImg1 from '../../img/free-m.png'
import impactImg2 from '../../img/free-m1.png'
import impactImg3 from '../../img/free-m2.png'

const AboutImpact = () => {
  const contents = [
    {
      postion: '1',
      text: 'Free Chronic Pain Management Education and Service.',
      img: impactImg1
    },
    {
      postion: '2',
      text: 'Free Medical Camp and Outreach to assist healthcare providers in underserved communities.',
      img: impactImg2
    },
    {
      postion: '3',
      text: 'Free or discounted medications to patient assistance to help vulnerable patients facing financial barriers to accessing chronic pain treatment.',
      img: impactImg3
    }
  ]

  const location = useLocation()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const targetSection = urlParams.get('section')

    if (targetSection) {
      const element = document.getElementById(targetSection)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  return (
    <div className='p-5 py-20 lg:p-20 text-center' id='our-journey'>
      <div>
        <div className=''>
          <Banner title='our impact journey' />
        </div>
        <div className='w-full flex justify-center items-start gap-4 mt-8'>
          <h1 className='text-left text-3xl text-berkeleyBlue w-[70%]'>
            We support under-served Communities
          </h1>
          <p className='text-sm font-ibmPlexSans text-[#4D4D4D] w-full lg:w-1/2 text-left'>
            From healthcare initiatives that ensure access to vital medical
            services to educational programs that empower individuals with
            knowledge and skills, we strive to make a positive impact. Our
            commitment extends beyond immediate assistance, focusing on creating
            lasting and meaningful changes that uplift the socio-economic fabric
            of underserved communities.
          </p>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 lg:mt-20'>
        {contents.map(content => (
          <div
            className=' h-[500px] flex flex-col justify-between items-start p-5 border border-[#E6E6E6] 
            rounded-lg gap-4'
          >
            <div>
              <Banner
                title={content.postion}
                className='rounded-full w-7 h-7 p-0 px-0 py-0'
              />
              <p className='text-left font-ibmPlexSans text-berkeleyBlue mt-5'>
                {content.text}
              </p>
            </div>
            <img src={content.img} alt='Impact' className='rounded-lg' />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AboutImpact
