import { Link } from "react-router-dom";
import HomeVidModal from "../home-vid-modal";

const HomeHeroFr = () => {
  return (
    <div
      className="p-5 lg:p-10 bg-home-hero-bg h-screen 
    flex flex-col justify-end md:justify-center items-start lg:items-center"
    >
      <div
        className="w-full lg:w-[90%] 
      flex flex-col justify-center items-start lg:items-center gap-2 md:gap-5"
      >
        <h1
          className="text-[26px] md:text-[48px] lg:text-[54px] 
          leading-[35.6px] md:leading-[54.8px] 
          lg:leading-[75.6px] text-white text-left md:text-center font-normal"
        >
          Débloquez une Afrique sans douleur. <br /> Construire une base de
          fournisseurs de confiance
        </h1>
        <p
          className="text-[14px] md:text-[18px] ld:text-[24px] 
          font-[400] leading-[24.8px] lg:leading-[38.4px] 
        text-white text-left md:text-center font-ibmPlexSans"
        >
          En tirant parti de l’IA, 10mg Pharma instaure la confiance, en
          permettant aux prestataires de soins de santé des communautés mal
          desservies d’Afrique d’accéder à des prêts transfrontaliers à un
          chiffre sans garantie.
        </p>
        <div
          className="flex justify-start md:justify-center items-center 
          gap-2 md:gap-5 mt-2 w-full font-ibmPlexSans"
        >
          <HomeVidModal />
          <Link to="/about">
            <button
              className="text-sm bg-transparent border border-[#C3E3FD] text-[#C3E3FD] 
          p-3 rounded-md w-[100%] md:w-[100%] lg:w-full font-normal"
            >
              Learn more
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeHeroFr;
