import accessImg from "../../img/access-img.png";
import { FaChevronRight } from "react-icons/fa6";

const AccessSection = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-10 p-5 py-20 lg:p-20">
      <img src={accessImg} alt={accessImg} className="w-full md:w-[350px]" />
      <div>
        <h3 className="text-2xl text-berkeleyBlue">
          Access to Cross-Border Single-Digit Loans
        </h3>
        <div className="flex items-center gap-2 text-md text-[#3E5B34] mt-1">
          <FaChevronRight className="h-3 w-2" />
          <p>Grow at 10x, say goodbye to collaterals and high interest rates</p>
        </div>
        <p className="text-[#4D4D4D] font-ibmPlexSans text-sm leading-[30px] mt-3">
          Bid farewell to the burdens of collaterals and exorbitant interest
          rates as you chart a path to business growth and expand to underserved
          communities. Our innovative financial solutions offer single-digit
          loans without the need for collateral, allowing your business to
          thrive without the traditional constraints. This financial support
          empowers you to expand your services, reach more communities, and
          invest in essential resources.
        </p>
      </div>
    </div>
  );
};

export default AccessSection;
