import Banner from "../banner";

import remote from "../../images/remote.svg";
import development from "../../images/development.svg";
import insurance from "../../images/insurance.svg";
import leave from "../../images/leave.svg";
import employee from "../../images/employee.svg";

const BenefitsFr = () => {
  const benefitsImages = [remote, development, insurance, leave, employee];

  const benefits = [
    {
      icon: benefitsImages[0],
      title: "RÀ distance d'abord",
      description: `10mg Pharma s'engage à favoriser l'efficacité et la flexibilité grâce au travail à distance. 
      Nous prônons la collaboration numérique entre nos employés diversifiés et nous offrons la flexibilité d'une 
      expérience de travail hybride, en particulier pour ceux qui se trouvent à proximité de nos bureaux.`,
    },
    {
      icon: benefitsImages[1],
      title: "PDéveloppement professionnel",
      description: `Nous accordons la priorité au développement de nos collaborateurs, en les accompagnant dans 
      l’atteinte de leurs objectifs professionnels à court, moyen et long terme.`,
    },
    {
      icon: benefitsImages[2],
      title: "Assurance santé",
      description: `Nous proposons une assurance maladie complète pour couvrir les besoins médicaux des
      vous et vos personnes à votre charge.`,
    },
    {
      icon: benefitsImages[3],
      title: "Congé annuel payé",
      description: `Chez 10mg Pharma, nous valorisons l'équilibre entre vie professionnelle et vie privée et, à cet effet, toute l'équipe
      les membres bénéficient de 20 jours de congé annuel payé, permettant des pauses essentielles et garantissant
      une main d'œuvre rafraîchie et motivée.`,
    },
    {
      icon: benefitsImages[4],
      title: "Pension des employés",
      description: `Chez 10mg Pharma, nous accordons la priorité au bien-être financier des membres de notre équipe.
      Grâce à notre régime de retraite complet, nous permettons à nos employés de planifier une sécurité et
      un avenir épanouissant.`,
    },
  ];

  return (
    <div className="p-5 py-20 lg:p-20">
      <Banner title="avantages et bénéfices" />
      <h1 className="text-berkeleyBlue text-3xl mt-5">
        Vous adorerez travailler ici
      </h1>
      <div
        className="mt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 
        "
      >
        {benefits.map((benefit, index) => (
          <div
            className="flex flex-col justify-center items-start font-ibmPlexSans 
            bg-[#FBFBF8] p-5 rounded-lg"
          >
            <div
              className="bg-[#DFF0F6] w-10 h-10 rounded-full flex 
            justify-center items-center mb-5"
            >
              <img src={benefit.icon} alt="Remote First" className="w-4" />
            </div>
            <p className="text-[#B3001B] text-lg">{benefit.title}</p>
            <p className="text-[#011627] text-[14px] mt-2">
              {benefit.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsFr;
